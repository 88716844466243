import React, { useEffect, useMemo, useState } from 'react';
import { MaxWidth, MaxWidth2, BannerImg, BannerTop, TitTab, MainWrap, TopBtn, SubTit } from 'Global';
import { useTranslation } from 'react-i18next';
import { Main01, Main02, Main03, Main04, Main05, Main06 } from './style';
import Form from './component/Form';
import { useLocation } from 'react-router-dom';
import useMoveScrool from '../../hooks/useMoveScroll';
import { throttle } from 'lodash';

const Support = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const [tab, setTab] = useState<number>(1);

  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    2: useMoveScrool(),
    3: useMoveScrool(),
    4: useMoveScrool(),
    5: useMoveScrool(),
    length: 6,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;
        const mainTop02 = tabRef[1].element?.current?.getBoundingClientRect().top;
        const mainTop03 = tabRef[2].element?.current?.getBoundingClientRect().top;
        const mainTop04 = tabRef[3].element?.current?.getBoundingClientRect().top;
        const mainTop05 = tabRef[4].element?.current?.getBoundingClientRect().top;
        const mainTop06 = tabRef[5].element?.current?.getBoundingClientRect().top;

        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }
        if (mainTop02 && mainTop02 <= 900) {
          setScrollEvent(2);
        }
        if (mainTop03 && mainTop03 <= 900) {
          setScrollEvent(3);
        }
        if (mainTop04 && mainTop04 <= 900) {
          setScrollEvent(4);
        }
        if (mainTop05 && mainTop05 <= 900) {
          setScrollEvent(5);
        }
        if (mainTop06 && mainTop06 <= 900) {
          setScrollEvent(6);
        }
        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [tabRef, scrollEvent],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  useEffect(() => {
    if (pathname === '/support') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/support/technical') {
      setScrollEvent(2);
      tabRef[0].onMoveToElement();
    }
    if (pathname === '/support/network') {
      tabRef[4].onMoveToElement();
    }
    if (pathname === '/support/contact') {
      tabRef[5].onMoveToElement();
    }
  }, [pathname]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop>
          <h1>
            <span>{t('TR_SUPPORT_TIT_01')}</span>
            <span>{t('TR_SUPPORT_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="cnt3">
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_SUPPORT_TAB_01')}
          </button>
          <button type="button" className={scrollEvent === 5 ? ' active' : ''} onClick={tabRef[4].onMoveToElement}>
            {t('TR_SUPPORT_TAB_02')}
          </button>
          <button type="button" className={scrollEvent === 6 ? ' active' : ''} onClick={tabRef[5].onMoveToElement}>
            {t('TR_SUPPORT_TAB_03')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg className="support"></BannerImg>
      <MaxWidth>
        <Main01 ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_SUPPORT_MAIN_01_TIT')}</h2>
            <div>
              <p>
                <span>{t('TR_SUPPORT_MAIN_01_TXT_01')}</span>
                <span>{t('TR_SUPPORT_MAIN_01_TXT_02')}</span>
              </p>
            </div>
          </SubTit>
        </Main01>
      </MaxWidth>
      <Main02 ref={tabRef[1].element} className={scrollEvent >= 2 ? ' active' : ''}>
        <MaxWidth>
          <h2>{t('TR_SUPPORT_MAIN_02_TIT')}</h2>
          <div className="main02Img">
            <img src="/images/img-support-main02.png" alt="UNIC Production Process" />
            <img src="/images/img-support-main02_t.png" alt="UNIC Production Process" />
            <img src="/images/img-support-main02_m.png" alt="UNIC Production Process" />
          </div>
        </MaxWidth>
      </Main02>
      <MaxWidth>
        <Main03 ref={tabRef[2].element} className={scrollEvent >= 3 ? ' active' : ''}>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_SUPPORT_MAIN_03_TIT')}</h2>
            <div>
              <p>
                <span>{t('TR_SUPPORT_MAIN_03_TXT')}</span>
              </p>
            </div>
          </SubTit>
        </Main03>
      </MaxWidth>
      <Main04 ref={tabRef[3].element} className={scrollEvent >= 4 ? ' active' : ''}>
        <MaxWidth>
          <div className="inner">
            <h3>{t('TR_SUPPORT_MAIN_04_TIT')}</h3>
            <div className="main04Img">
              <img src="/images/img-support-main04.png" alt="UNIC Field Support Control" />
              <img src="/images/img-support-main04_t.png" alt="UNIC Field Support Control" />
              <img src="/images/img-support-main04_m.png" alt="UNIC Field Support Control" />
            </div>
            <div className="txtBox">
              <ul>
                <li>
                  <h4>{t('TR_SUPPORT_MAIN_04_LIST_01_TIT')}</h4>
                  <p>
                    <span>{t('TR_SUPPORT_MAIN_04_LIST_01_TXT_01')}</span>
                    <span>{t('TR_SUPPORT_MAIN_04_LIST_01_TXT_02')}</span>
                  </p>
                </li>
                <li>
                  <h4>{t('TR_SUPPORT_MAIN_04_LIST_02_TIT')}</h4>
                  <p>
                    <span>{t('TR_SUPPORT_MAIN_04_LIST_02_TXT_01')}</span>
                  </p>
                </li>
                <li>
                  <h4>{t('TR_SUPPORT_MAIN_04_LIST_03_TIT')}</h4>
                  <p>
                    <span>{t('TR_SUPPORT_MAIN_04_LIST_03_TXT_01')}</span>
                    <span>{t('TR_SUPPORT_MAIN_04_LIST_03_TXT_02')}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </MaxWidth>
      </Main04>
      <Main05 ref={tabRef[4].element} className={scrollEvent >= 5 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <div>
              <h2>{t('TR_SUPPORT_MAIN_05_TIT')}</h2>
              <div>
                <p>
                  <span>{t('TR_SUPPORT_MAIN_05_TXT_01')}</span>
                  <span>{t('TR_SUPPORT_MAIN_05_TXT_02')}</span>
                </p>
              </div>
            </div>
            <div className="btnGroup">
              <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                {t('TR_SUPPORT_MAIN_05_TAB_01')}
              </button>
              <button type="button" className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                {t('TR_SUPPORT_MAIN_05_TAB_02')}
              </button>
            </div>
          </SubTit>
        </MaxWidth>
        <div className="map">
          {tab === 1 ? (
            <img src="/images/img-support-main05-client.png" alt="Global Network client map" />
          ) : (
            <img src="/images/img-support-main05-partner.png" alt="Global Network partner map" />
          )}
        </div>
      </Main05>
      <MaxWidth2>
        <Main06 ref={tabRef[5].element} className={scrollEvent >= 6 ? ' active' : ''}>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_SUPPORT_MAIN_06_TIT')}</h2>
          </SubTit>
          <Form />
        </Main06>
      </MaxWidth2>
      {scrollEvent !== 0 && (
        <TopBtn className={scrollEvent === 5 ? 'active' : ''} onClick={onTop}>
          TOP
        </TopBtn>
      )}
    </MainWrap>
  );
};

export default Support;
