import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SliderWrap } from '../style';
import Slider from 'react-slick';
import { sliderData } from './sliderData';
import { useTranslation } from 'react-i18next';
import { SubTit } from 'Global';

const Slick = () => {
  const { t, i18n } = useTranslation();
  let interval: any = undefined;

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [progressWidth, setProgressWidth] = useState<number>(0);

  const progressRef = useRef<any>(null);
  const sliderRef = useRef<any>(null);
  const nowSliderRef = useRef<any>(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesToShow: 4,
    swipe: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

    beforeChange(currentSlide: number, nextSlide: number) {
      setCurrentSlide(nextSlide + 1);
    },
  };
  const onNext = useCallback(() => {
    sliderRef.current.slickNext();
    setProgressWidth(0);
  }, []);

  const onPrev = useCallback(() => {
    sliderRef.current.slickPrev();
    setProgressWidth(0);
  }, []);
  useEffect(() => {
    sliderRef.current.slickPause();

    interval = setInterval(() => {
      setProgressWidth((prev) => prev + 1);
      if (progressWidth === 100) {
        setProgressWidth(0);
        sliderRef.current.slickNext();
      }
    }, 50);
    return () => clearInterval(interval);

    progressRef.current.style.width = progressWidth + '%';
  }, [progressWidth]);
  return (
    <>
      <SubTit className="tit">
        <h2>{t('TR_ABOUT_MAIN_04_TIT')}</h2>
        <div>
          <p>
            <span>{t('TR_ABOUT_MAIN_04_TXT_01')}</span>
            <span>{t('TR_ABOUT_MAIN_04_TXT_02')}</span>
          </p>
          <div>
            <button type="button" onClick={onPrev}>
              <img src="/images/icon/ico-about-arrowLeft.png" alt="이전 슬라이드" />
            </button>
            <button type="button" onClick={onNext}>
              <img src="/images/icon/ico-about-arrowRight.png" alt="다음 슬라이드" />
            </button>
          </div>
        </div>
      </SubTit>
      <SliderWrap>
        <div className="fakeBox"></div>
        <Slider {...settings} ref={sliderRef}>
          {sliderData.map((item) => (
            <div key={item.id} className="slideItem" ref={nowSliderRef}>
              <h3>{t(item.title)}</h3>
              <ul>
                <li>{t(item.sub01)}</li>
                <li>{t(item.sub02)}</li>
                <li>{t(item.sub03)}</li>
                <li>{t(item.sub04)}</li>
                <li>{t(item.sub05)}</li>
                <li>{t(item.sub06)}</li>
                <li>{t(item.sub07)}</li>
              </ul>
            </div>
          ))}
        </Slider>
      </SliderWrap>
    </>
  );
};

export default Slick;
