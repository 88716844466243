export const listData = [
  {
    id: 1,
    title: 'DRYER',
    images: [
      { id: 0, src: '/images/img-dryer01.png' },
      { id: 1, src: '/images/img-dryer02.png' },
      { id: 2, src: '/images/img-dryer03.png' },
      { id: 3, src: '/images/img-dryer04.png' },
    ],
    arr: [
      { id: 1, text: 'High Quality, High Through-put, High Efficiency Dry Performance' },
      { id: 2, text: 'Rinse & Dry   (Optional: - HF + Rinse & Dry)' },
      { id: 3, text: 'Watermark Free' },
      { id: 4, text: 'Excellent Particle & Dry Quality' },
      { id: 5, text: 'Normal/Thin/Warpage/Sensitive Wafer Perfect Control' },
      { id: 6, text: 'Wet Integration Or Stand Alone' },
      { id: 7, text: 'Room Temp. Or Hot Temp.' },
      { id: 8, text: 'N2+ Hot N2  /  Hot IPA+ N2 or Hot N2' },
      { id: 9, text: 'IPA Consumption: 0~40cc/Batch' },
      { id: 10, text: 'HDD (Hard Disk Dryer) Cleaning & Drying' },
      { id: 11, text: '100ea (50ea)  Hard Disk / Batch' },
      { id: 12, text: 'Dual (Single) Robot  HDD  Usage' },
    ],
  },
  {
    id: 2,
    title: 'POD CLEANER',
    images: [
      { id: 0, src: '/images/img-pod01.png' },
      { id: 1, src: '/images/img-pod02.png' },
      { id: 2, src: '/images/img-pod03.png' },
    ],
    arr: [
      { id: 1, text: 'High Throughput (Cleaning, Drying Process Optimizing)' },
      { id: 2, text: 'Compact Dimension, Small Footprint' },
      { id: 3, text: 'Clean Uniformity, Perfect Dry Condition' },
      { id: 4, text: 'Multi POD Cleaning Application (FOUP, FOSB, CASSETTE, BOX, ETC.)' },
      { id: 5, text: 'FULL AUTO, SEMI AUTO, MANUAL with Multi Axis Transfer Robot' },
      { id: 6, text: 'Fab Automation Application (OHT, AGV, MGV, OPERATOR)' },
      { id: 7, text: 'POD Stock Area Application (RFID, BARCODE)' },
      { id: 8, text: 'Wafer Detect in POD Application' },
      { id: 9, text: 'CCD CAMERA' },
    ],
  },
  {
    id: 3,
    title: 'MASK CLEANER',
    images: [
      { id: 0, src: '/images/img-mask01.png' },
      { id: 1, src: '/images/img-mask02.png' },
      { id: 2, src: '/images/img-mask03.png' },
    ],
    arr: [
      { id: 1, text: 'Compact Dimension, Small Footprint' },
      { id: 2, text: 'Clean Uniformity, Optimized Mask Clean Condition ' },
      { id: 3, text: 'Multi Mask Cleaning Application (100x100mm  to 1260x1670mm)' },
      { id: 4, text: 'Full Auto, Semi Auto, Manual Operation with Multi Size Loader Module' },
      { id: 5, text: 'Alkali  Chemical Cleaning / Acid Chemical Cleaning ' },
      { id: 6, text: 'Others :  Megasonic Cleaning / Brush  Cleaning / Hot DIW Cleaning / Ultrasonic Bath Cleaning' },
    ],
  },
  {
    id: 4,
    title: 'SINGLE PROCESSOR',
    images: [
      { id: 0, src: '/images/img-single01.png' },
      { id: 1, src: '/images/img-single02.png' },
      { id: 2, src: '/images/img-single03.png' },
      { id: 3, src: '/images/img-single04.png' },
    ],
    arr: [
      { id: 1, text: 'Compact Dimension, Small Footprint' },
      { id: 2, text: 'Clean Uniformity, Wafer Clean Condition Enforcement' },
      {
        id: 3,
        text: 'Functions : Chemical Recycle, Liquid Non Drip, Standby Clean,Selective Drain, Various Chuck Application(Grip, Bernoulli, Venturi, Vacuum)',
      },
      { id: 4, text: 'Lab Single Spin Process or Multi Single Spin Process' },
      { id: 5, text: 'Wafer Cleaning Process, Wafer Processing Process' },
    ],
  },
];
