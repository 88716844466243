import styled from 'styled-components';

export const FooterWrap = styled.footer`
  background-color: #f0f3f6;
  width: 100%;
  padding: 60px 100px;
  @media screen and (max-width: 1500px) {
    padding: 60px 40px;
  }
  @media screen and (max-width: 980px) {
    padding: 50px 40px;
  }
  @media screen and (max-width: 500px) {
    padding: 45px 30px 70px;
  }
  &.white {
    background-color: #fff;
  }
  img {
    width: 115px;
  }
  .top {
    padding-bottom: 30px;
    border-bottom: 1px solid #bec0c4;
    @media screen and (max-width: 980px) {
      padding-bottom: 25px;
    }
    @media screen and (max-width: 500px) {
      padding-bottom: 20px;
    }
  }
  .mid {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 30px 0 25px;
    position: relative;
    @media screen and (max-width: 980px) {
      margin: 25px 0 20px;
    }
    @media screen and (max-width: 500px) {
      position: unset;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    span {
      color: #5c626e;
      font-family: Pretendard !important;
      font-size: 15px;
      font-weight: 400;
      display: block;
      @media screen and (max-width: 670px) {
        margin-bottom: 10px;
      }
    }
    button {
      color: #32353a;
      font-weight: 600;
      font-size: 14px;
      font-family: Pretendard !important;
      @media screen and (max-width: 500px) {
        padding: 0;
      }
      &:first-of-type {
        margin-right: 25px;
      }
    }
    div:nth-of-type(2) {
      @media screen and (max-width: 980px) {
        position: absolute;
        right: 0;
        top: -80px;
      }
      @media screen and (max-width: 500px) {
        position: unset;
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }
  .bot {
    & > span {
      color: #5c626e;
      font-family: Pretendard !important;
      font-size: 15px;
      font-weight: 400;
      display: block;
      span {
        @media screen and (max-width: 675px) {
          &.number {
            display: block;
          }
        }
        @media screen and (max-width: 520px) {
          &.email {
            display: block;
          }
        }
        @media screen and (max-width: 330px) {
          &.fax {
            display: block;
            margin-bottom: 20px;
          }
        }
      }
      .m15 {
        display: inline-block;
        margin: 0 15px;
        @media screen and (max-width: 675px) {
          margin: 0 0 20px;
        }
        @media screen and (max-width: 330px) {
          margin: 0;
        }
      }
    }
  }
`;
