import React from 'react';
import { Link } from 'react-router-dom';
import { Main03Wrap } from './style';
import { useTranslation } from 'react-i18next';

type Main03 = {
  titRef03: React.ForwardedRef<HTMLDivElement | null>;
  scrollEvent: number;
};

const Main03 = ({ titRef03, scrollEvent }: Main03) => {
  const { t, i18n } = useTranslation();
  return (
    <Main03Wrap ref={titRef03} className={scrollEvent >= 3 ? 'active' : ''}>
      <div>
        <div className="inner">
          <h2>{t('TR_MAIN_03_TIT')}</h2>
          <p>{t('TR_MAIN_03_TIT_SUB')}</p>
          <ul className="itemList">
            <li>
              <Link to="/support/contact">
                <h3>{t('TR_MAIN_03_LIST_ITEM_01_TIT')}</h3>
                <p>
                  <span>{t('TR_MAIN_03_LIST_ITEM_01_TXT_01')}</span>
                  <span>{t('TR_MAIN_03_LIST_ITEM_01_TXT_02')}</span>
                </p>
                <span className="arrow">{t('TR_MAIN_03_LIST_ITEM_01_LINK')}</span>
              </Link>
            </li>
            <li>
              <Link to="/welfare/welfare">
                <h3>{t('TR_MAIN_03_LIST_ITEM_02_TIT')}</h3>
                <p>
                  <span>{t('TR_MAIN_03_LIST_ITEM_02_TXT_01')}</span>
                  <span>{t('TR_MAIN_03_LIST_ITEM_02_TXT_02')}</span>
                </p>
                <span className="arrow">{t('TR_MAIN_03_LIST_ITEM_02_LINK')}</span>
              </Link>
            </li>
            <li>
              <Link to="/employment">
                <h3>{t('TR_MAIN_03_LIST_ITEM_03_TIT')}</h3>
                <p>
                  <span>{t('TR_MAIN_03_LIST_ITEM_03_TXT_01')}</span>
                  <span>{t('TR_MAIN_03_LIST_ITEM_03_TXT_02')}</span>
                </p>
                <span className="arrow">{t('TR_MAIN_03_LIST_ITEM_03_LINK')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Main03Wrap>
  );
};

export default Main03;
