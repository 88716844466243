import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Main01Wrap } from './style';
import { useTranslation } from 'react-i18next';

type Main01 = {
  titRef01: React.ForwardedRef<HTMLDivElement | null>;
  scrollEvent: number;
};

const Main01 = ({ titRef01, scrollEvent }: Main01) => {
  const { t, i18n } = useTranslation();
  return (
    <Main01Wrap>
      <div ref={titRef01} className={'top' + (scrollEvent >= 1 ? ' active' : '')}>
        <h2>{t('TR_MAIN_01_TIT')}</h2>
        <p>{t('TR_MAIN_01_SUB')}</p>
      </div>
      <div className={'item left' + (scrollEvent >= 1 ? ' active' : '')}>
        <div className="img">
          <img src="/images/img-main01_item01.png" alt="메인이미지" />
        </div>
        <div className="txt">
          <h3>{t('TR_MAIN_01_ITEM_01_TIT')}</h3>
          <p>
            <span>{t('TR_MAIN_01_ITEM_01_SUB')}</span>
            <span>{t('TR_MAIN_01_ITEM_01_SUB_BR')}</span>
            <span>{t('TR_MAIN_01_ITEM_01_SUB_BR_02')}</span>
          </p>
        </div>
      </div>
      <div className={'item right' + (scrollEvent >= 1 ? ' active' : '')}>
        <div className="txt">
          <h3>{t('TR_MAIN_01_ITEM_02_TIT')}</h3>
          <p>
            <span>{t('TR_MAIN_01_ITEM_02_SUB')}</span>
            <span>{t('TR_MAIN_01_ITEM_02_SUB_BR')}</span>
            <span>{t('TR_MAIN_01_ITEM_02_SUB_BR_02')}</span>
          </p>
        </div>
        <div className="img">
          <img src="/images/img-main01_item02.png" alt="메인이미지" />
        </div>
      </div>
    </Main01Wrap>
  );
};

export default Main01;
