import React, { useEffect, useMemo, useRef, useState } from 'react';
import Slick from 'components/slider';
import { throttle } from 'lodash';
import { Section } from './style';
import { TopBtn } from 'Global';
import Main01 from 'components/main/Main01';
import Main02 from 'components/main/Main02';
import Main03 from 'components/main/Main03';
import Main04 from 'components/main/Main04';

const Main = () => {
  const titRef01 = useRef<HTMLDivElement | null>(null);
  const titRef02 = useRef<HTMLDivElement | null>(null);
  const titRef03 = useRef<HTMLDivElement | null>(null);
  const titRef04 = useRef<HTMLDivElement | null>(null);

  const [scrollEvent, setScrollEvent] = useState<number>(0);

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const titTop01 = titRef01?.current?.getBoundingClientRect().top;
        const titTop02 = titRef02?.current?.getBoundingClientRect().top;
        const titTop03 = titRef03?.current?.getBoundingClientRect().top;
        const titTop04 = titRef04?.current?.getBoundingClientRect().top;

        if (titTop01 && titTop01 <= 900) {
          setScrollEvent(1);
        }
        if (titTop02 && titTop02 <= 1000) {
          setScrollEvent(2);
        }
        if (titTop03 && titTop03 <= 1000) {
          setScrollEvent(3);
        }
        if (titTop04 && titTop04 <= 900) {
          setScrollEvent(4);
        }
        if (titTop01 && titTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Section>
      <Slick />
      <Main01 titRef01={titRef01} scrollEvent={scrollEvent} />
      <Main02 titRef02={titRef02} scrollEvent={scrollEvent} />
      <Main03 titRef03={titRef03} scrollEvent={scrollEvent} />
      <Main04 titRef04={titRef04} scrollEvent={scrollEvent} />
      {scrollEvent !== 0 && (
        <TopBtn className={scrollEvent === 2 ? 'active' : ''} onClick={onTop}>
          TOP
        </TopBtn>
      )}
    </Section>
  );
};

export default Main;
