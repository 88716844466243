import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Main01, Main02, Main03, Main04, Main05, Main06, Main07, SliderWrap } from './style';
import { MaxWidth, BannerImg, BannerTop, TitTab, MainWrap, TopBtn, SubTit } from 'Global';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import useMoveScrool from 'hooks/useMoveScroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Slick from './component/Slick';
import Map from './component/Map';

const About = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [scrollEvent, setScrollEvent] = useState<number>(0);

  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    2: useMoveScrool(),
    3: useMoveScrool(),
    4: useMoveScrool(),
    5: useMoveScrool(),
    6: useMoveScrool(),
    length: 6,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;
        const mainTop02 = tabRef[1].element?.current?.getBoundingClientRect().top;
        const mainTop03 = tabRef[2].element?.current?.getBoundingClientRect().top;
        const mainTop04 = tabRef[3].element?.current?.getBoundingClientRect().top;
        const mainTop05 = tabRef[4].element?.current?.getBoundingClientRect().top;
        const mainTop06 = tabRef[5].element?.current?.getBoundingClientRect().top;
        const mainTop07 = tabRef[6].element?.current?.getBoundingClientRect().top;

        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }
        if (mainTop02 && mainTop02 <= 900) {
          setScrollEvent(2);
        }
        if (mainTop03 && mainTop03 <= 900) {
          setScrollEvent(3);
        }
        if (mainTop04 && mainTop04 <= 900) {
          setScrollEvent(4);
        }
        if (mainTop05 && mainTop05 <= 900) {
          setScrollEvent(5);
        }
        if (mainTop06 && mainTop06 <= 900) {
          setScrollEvent(6);
        }
        if (mainTop07 && mainTop07 <= 900) {
          setScrollEvent(7);
        }
        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [tabRef, scrollEvent],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  useEffect(() => {
    if (pathname === '/about') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/about/overview') {
      setScrollEvent(2);
      tabRef[0].onMoveToElement();
    }
    if (pathname === '/about/philosophy') {
      tabRef[1].onMoveToElement();
    }
    if (pathname === '/about/coreValue') {
      tabRef[2].onMoveToElement();
    }
    if (pathname === '/about/history') {
      tabRef[3].onMoveToElement();
    }
    if (pathname === '/about/certification') {
      tabRef[4].onMoveToElement();
    }
    if (pathname === '/about/organizationChart') {
      tabRef[5].onMoveToElement();
    }
    if (pathname === '/about/map') {
      tabRef[6].onMoveToElement();
    }
  }, [pathname]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop className="about">
          <h1>
            <span>{t('TR_ABOUT_H1_01')}</span>
            <span>{t('TR_ABOUT_H1_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="cnt7">
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_ABOUT_TAB_01')}
          </button>
          <button type="button" className={scrollEvent === 2 ? ' active' : ''} onClick={tabRef[1].onMoveToElement}>
            {t('TR_ABOUT_TAB_02')}
          </button>
          <button type="button" className={scrollEvent === 3 ? ' active' : ''} onClick={tabRef[2].onMoveToElement}>
            {t('TR_ABOUT_TAB_03')}
          </button>
          <button type="button" className={scrollEvent === 4 ? ' active' : ''} onClick={tabRef[3].onMoveToElement}>
            {t('TR_ABOUT_TAB_04')}
          </button>
          <button type="button" className={scrollEvent === 5 ? ' active' : ''} onClick={tabRef[4].onMoveToElement}>
            {t('TR_ABOUT_TAB_05')}
          </button>
          <button type="button" className={scrollEvent === 6 ? ' active' : ''} onClick={tabRef[5].onMoveToElement}>
            {t('TR_ABOUT_TAB_06')}
          </button>
          <button type="button" className={scrollEvent === 7 ? ' active' : ''} onClick={tabRef[6].onMoveToElement}>
            {t('TR_ABOUT_TAB_07')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg></BannerImg>
      <MaxWidth>
        <Main01 ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_ABOUT_MAIN_01_TIT')}</h2>
            <p className="fc-b">
              <span>{t('TR_ABOUT_MAIN_01_TXT_SUB_01')}</span>
              <span>{t('TR_ABOUT_MAIN_01_TXT_SUB_02')}</span>
              <span>{t('TR_ABOUT_MAIN_01_TXT_SUB_03')}</span>
              <span>{t('TR_ABOUT_MAIN_01_TXT_SUB_04')}</span>
            </p>
          </SubTit>
        </Main01>
      </MaxWidth>
      <Main02 ref={tabRef[1].element} className={scrollEvent >= 2 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_ABOUT_MAIN_02_TIT')}</h2>
            <p>
              <span>{t('TR_ABOUT_MAIN_02_TXT_SUB_01')}</span>
              <span>{t('TR_ABOUT_MAIN_02_TXT_SUB_02')}</span>
            </p>
          </SubTit>
          <ul>
            <li>
              <div>
                <span className="num">01</span>
                <h3>{t('TR_ABOUT_MAIN_02_ITEM_01_TIT')}</h3>
                <p>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_01_TXT_01')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_01_TXT_02')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_01_TXT_03')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <span className="num">02</span>
                <h3>{t('TR_ABOUT_MAIN_02_ITEM_02_TIT')}</h3>
                <p>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_02_TXT_01')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_02_TXT_02')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_02_TXT_03')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <span className="num">03</span>
                <h3>{t('TR_ABOUT_MAIN_02_ITEM_03_TIT')}</h3>
                <p>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_03_TXT_01')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_03_TXT_02')}</span>
                  <span>{t('TR_ABOUT_MAIN_02_ITEM_03_TXT_03')}</span>
                </p>
              </div>
            </li>
          </ul>
        </MaxWidth>
      </Main02>
      <Main03 ref={tabRef[2].element} className={scrollEvent >= 3 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_ABOUT_MAIN_03_TIT')}</h2>
            <p>
              <span>{t('TR_ABOUT_MAIN_03_TXT_SUB_01')}</span>
              <span>{t('TR_ABOUT_MAIN_03_TXT_SUB_02')}</span>
            </p>
          </SubTit>
          <ul>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_01')}</h3>
            </li>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_02')}</h3>
            </li>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_03')}</h3>
            </li>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_04')}</h3>
            </li>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_05')}</h3>
            </li>
            <li>
              <h3>{t('TR_ABOUT_MAIN_03_ITEM_06')}</h3>
            </li>
          </ul>
        </MaxWidth>
      </Main03>
      <MaxWidth>
        <Main04 ref={tabRef[3].element} className={scrollEvent >= 4 ? ' active' : ''}>
          <Slick />
        </Main04>
      </MaxWidth>
      <Main05 ref={tabRef[4].element} className={scrollEvent >= 5 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_ABOUT_MAIN_05_TIT')}</h2>
          </SubTit>
          <ul>
            <li>
              <img src="/images/img-about05-item01.png" alt={t('TR_ABOUT_MAIN_05_TXT_01')} />
              <h3>
                <span>{t('TR_ABOUT_MAIN_05_TXT_01')}</span>
              </h3>
            </li>
            <li>
              <img src="/images/img-about05-item02.png" alt={t('TR_ABOUT_MAIN_05_TXT_02')} />
              <h3>
                <span>{t('TR_ABOUT_MAIN_05_TXT_02')}</span>
                <span>{t('TR_ABOUT_MAIN_05_TXT_02_01')}</span>
                <span>{t('TR_ABOUT_MAIN_05_TXT_02_02')}</span>
              </h3>
            </li>
            <li>
              <img src="/images/img-about05-item03.png" alt={t('TR_ABOUT_MAIN_05_TXT_03')} />
              <h3>
                <span>{t('TR_ABOUT_MAIN_05_TXT_03')}</span>
                <span>{t('TR_ABOUT_MAIN_05_TXT_03_01')}</span>
              </h3>
            </li>
            <li>
              <img src="/images/img-about05-item04.png" alt={t('TR_ABOUT_MAIN_05_TXT_04')} />
              <h3>
                <span>{t('TR_ABOUT_MAIN_05_TXT_04')}</span>
                <span>{t('TR_ABOUT_MAIN_05_TXT_04_01')}</span>
              </h3>
            </li>
            <li>
              <img src="/images/img-about05-item05.png" alt={t('TR_ABOUT_MAIN_05_TXT_05')} />
              <h3>
                <span>{t('TR_ABOUT_MAIN_05_TXT_05')}</span>
                <span>{t('TR_ABOUT_MAIN_05_TXT_05_01')}</span>
              </h3>
            </li>
          </ul>
        </MaxWidth>
      </Main05>
      <Main06 ref={tabRef[5].element} className={scrollEvent >= 6 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_ABOUT_MAIN_06_TIT')}</h2>
          </SubTit>
          <div className="scroll">
            <img src="/images/img-about06.png" alt={t('TR_ABOUT_MAIN_06_TIT')} />
          </div>
        </MaxWidth>
      </Main06>
      <Main07 ref={tabRef[6].element} className={scrollEvent >= 7 ? ' active' : ''}>
        <SubTit className="tit">
          <h2>{t('TR_ABOUT_MAIN_07_01_TIT')}</h2>
          <span>LOCATION</span>
        </SubTit>
        <div className="map">
          <Map />
        </div>
        <div className="txt">
          <div className="left">
            <h3>{t('TR_ABOUT_MAIN_07_TIT')}</h3>
            <ul>
              <li>
                <h4>{t('TR_ABOUT_MAIN_07_ITEM_01_TIT')}</h4>
                <p>
                  <span className="mb15">{t('TR_ABOUT_MAIN_07_ITEM_01_TXT_01')}</span>
                  <span>{t('TR_ABOUT_MAIN_07_ITEM_01_TXT_02')}</span>
                  <span>{t('TR_ABOUT_MAIN_07_ITEM_01_TXT_03')}</span>
                </p>
              </li>
              <li>
                <h4>{t('TR_ABOUT_MAIN_07_ITEM_02_TIT')}</h4>
                <p>+82-31-366-4678</p>
              </li>
              <li>
                <h4>{t('TR_ABOUT_MAIN_07_ITEM_03_TIT')}</h4>
                <p>+82-31-366-2379</p>
              </li>
              <li>
                <h4>{t('TR_ABOUT_MAIN_07_ITEM_04_TIT')}</h4>
                <div className="txtBox">
                  <div>
                    <p>
                      <span>{t('TR_ABOUT_MAIN_07_ITEM_05_TIT')}</span>
                      <span>askterry12@unic-global.co.kr</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span>{t('TR_ABOUT_MAIN_07_ITEM_06_TIT')}</span>
                      <span>support@unic-global.com</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="right">
            <img src="/images/img-unic.png" alt="UNIC" />
          </div>
        </div>
      </Main07>
      {scrollEvent !== 0 && (
        <TopBtn className={scrollEvent === 2 || scrollEvent === 5 ? 'active' : ''} onClick={onTop}>
          TOP
        </TopBtn>
      )}
    </MainWrap>
  );
};

export default About;
