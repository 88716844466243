import React from 'react';
import { Link } from 'react-router-dom';
import { MainWrap, NewsListWrap } from '../style';
import removeTags from 'hooks/removeTag';
import { useTranslation } from 'react-i18next';

type ListType = {
  tab: any;
  list: any;
};

const NewsList = ({ tab, list }: ListType) => {
  const { t } = useTranslation();
  return (
    <MainWrap>
      {list ? (
        <NewsListWrap>
          {list?.list?.map((item: any) => (
            <li key={item.boardIdx}>
              <Link to={`/${tab === 1 ? 'notice' : 'news'}/view/${item.boardIdx}`}>
                <div>
                  <h3 className={tab === 1 ? 'noticeNew' : 'newsNew'}>{item.boardTitle}</h3>
                  <p>{removeTags(item.boardContent)}</p>
                </div>
              </Link>
            </li>
          ))}
        </NewsListWrap>
      ) : (
        <div className="loading">loading...</div>
      )}
      {list?.list?.length === 0 && <div className="loading">{t('TR_NEWS_MAIN_NO_LIST')}</div>}
    </MainWrap>
  );
};

export default NewsList;
