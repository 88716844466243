import { MaxWidth } from 'Global';
import React, { useCallback, useState } from 'react';
import { listData } from './productList';
import { SlideWrap } from '../style';

const ProductSlide = ({ slideNo }: any) => {
  const [list] = useState(listData[slideNo]);
  const [slideImg, setSlideImg] = useState(0);

  const onPrev = useCallback(() => {
    if (slideImg !== 0) {
      setSlideImg((prev) => prev - 1);
    } else {
      setSlideImg(list.images.length - 1);
    }
  }, [slideImg, list]);

  const onNext = useCallback(() => {
    if (slideImg !== list.images.length - 1) {
      setSlideImg((prev) => prev + 1);
    } else {
      setSlideImg(0);
    }
  }, [slideImg, list]);

  return (
    <SlideWrap>
      <div className="left">
        <div className="main">
          <button type="button" onClick={onPrev}>
            <img src="/images/icon/ico-product-prev.png" alt="prev" />
          </button>
          <img src={list?.images[slideImg]?.src} alt={list.title} />
          <button type="button" onClick={onNext}>
            <img src="/images/icon/ico-product-next.png" alt="next" />
          </button>
        </div>
        <div className="btnGroup">
          {list.images.map((item) => (
            <button type="button" key={item.id} onClick={() => setSlideImg(item.id)}>
              <img src={item.src} alt={list.title} />
            </button>
          ))}
        </div>
      </div>
      <div className="right">
        <div>
          <h3 className={slideNo === 1 ? 'videoTit' : ''}>{list.title}</h3>
          {slideNo === 1 ? (
            <button
              type="button"
              className="videoBtn"
              onClick={() => {
                window.open('/popup/video', 'window', 'width=1000, height=560, location=no, status=no, scrollbars=yes');
              }}
            >
              SMIF POD CLEANER video
            </button>
          ) : null}
        </div>

        <ul>
          {list.arr.map((item) => (
            <li key={item.id}>{item.text}</li>
          ))}
        </ul>
      </div>
    </SlideWrap>
  );
};

export default ProductSlide;
