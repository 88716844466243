import React, { useEffect, useState } from 'react';
import { NewsTit, NewsWrap } from './style';
import { MainWrap, MaxWidth2 } from '../../Global';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import localDateTimeConverter from 'hooks/localDateTimeConverter';
import ViewInner from './component/ViewInner';
import { useTranslation } from 'react-i18next';

const View = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const { t } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <MainWrap>
      <MaxWidth2>
        <NewsTit>
          {path === 'news' ? t('TR_NEWS_VIEW_TIT_01') : t('TR_NEWS_VIEW_TIT_02')}
          <p> {path === 'news' ? t('TR_NEWS_VIEW_TXT_01') : t('TR_NEWS_VIEW_TXT_02')}</p>
        </NewsTit>
        <NewsWrap>
          <ViewInner />
        </NewsWrap>
      </MaxWidth2>
    </MainWrap>
  );
};

export default View;
