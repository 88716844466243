export const sliderData = [
  {
    id: 1,
    title: '2022',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_01_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_01_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_01_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_01_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_01_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_01_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_01_07',
  },
  {
    id: 2,
    title: '2021',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_02_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_02_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_02_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_02_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_02_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_02_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_02_07',
  },
  {
    id: 3,
    title: '2020',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_03_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_03_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_03_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_03_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_03_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_03_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_03_07',
  },
  {
    id: 4,
    title: '2019',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_04_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_04_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_04_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_04_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_04_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_04_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_04_07',
  },
  {
    id: 5,
    title: '2018',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_05_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_05_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_05_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_05_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_05_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_05_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_05_07',
  },
  {
    id: 6,
    title: '2017',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_06_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_06_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_06_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_06_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_06_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_06_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_06_07',
  },
  {
    id: 7,
    title: '2016',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_07_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_07_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_07_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_07_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_07_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_07_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_07_07',
  },
  {
    id: 8,
    title: '2015',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_08_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_08_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_08_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_08_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_08_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_08_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_08_07',
  },
  {
    id: 9,
    title: '2014',
    sub01: 'TR_ABOUT_MAIN_04_SLIDER_09_01',
    sub02: 'TR_ABOUT_MAIN_04_SLIDER_09_02',
    sub03: 'TR_ABOUT_MAIN_04_SLIDER_09_03',
    sub04: 'TR_ABOUT_MAIN_04_SLIDER_09_04',
    sub05: 'TR_ABOUT_MAIN_04_SLIDER_09_05',
    sub06: 'TR_ABOUT_MAIN_04_SLIDER_09_06',
    sub07: 'TR_ABOUT_MAIN_04_SLIDER_09_07',
  },
];
