import styled, { keyframes } from 'styled-components';
const fade = keyframes`
  0%{opacity: 0;}
  100%{
    opacity: 1;}
`;
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

const aniLeft = keyframes`
  0%{opacity: 0;  transform: translateX(-180px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

const aniRight = keyframes`
  0%{opacity: 0;  transform: translateX(180px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;
export const Main01Wrap = styled.article`
  padding: 120px 0 110px;
  background-image: url('/images/bg/bg-main01.png');
  background-repeat: no-repeat;
  max-width: 1920px;
  margin: 0 auto;
  @media screen and (max-width: 880px) {
    padding: 90px 0 100px;
    background-image: none;
  }
  @media screen and (max-width: 505px) {
    padding: 55px 0 100px;
  }
  .top {
    text-align: center;
    margin-bottom: 100px;
    @media screen and (max-width: 880px) {
      margin-bottom: 50px;
      padding: 0 30px;
    }
    @media screen and (max-width: 505px) {
      margin-bottom: 40px;
    }
    &.active {
      h2 {
        animation: ${aniTop} 0.5s linear;
        animation-fill-mode: forwards;
      }
      p {
        animation: ${aniTop} 0.5s linear;
        animation-fill-mode: forwards;
      }
    }
    h2 {
      font-size: 56px;
      font-weight: 600;
      color: #222;
      font-family: SEBANGGothic !important;
      margin-bottom: 10px;
      opacity: 0;
      transform: translateY(100px);
      @media screen and (max-width: 880px) {
        font-size: 40px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 505px) {
        font-size: 30px;
        line-height: 1.4;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: #333;
      opacity: 0;
      transform: translateY(100px);
      word-break: break-word;
      @media screen and (max-width: 880px) {
        font-size: 18px;
      }
      @media screen and (max-width: 505px) {
        font-size: 16px;
      }
    }
  }
  .item {
    display: flex;
    align-items: end;
    @media screen and (max-width: 880px) {
      flex-wrap: wrap;
    }
    & > div {
      width: 50%;
      flex-grow: 1;
      @media screen and (max-width: 880px) {
        width: 100%;
        img {
          width: 110%;
        }
      }
    }
    .txt {
      padding: 0 0 50px 100px;
      @media screen and (max-width: 1500px) {
        padding: 30px 60px 0px 60px;
      }
      @media screen and (max-width: 880px) {
        text-align: center;
      }
      @media screen and (max-width: 685px) {
        padding: 30px 40px 0px 40px;
      }
      @media screen and (max-width: 505px) {
        padding: 30px 30px 0px 30px;
      }
      h3 {
        font-family: Pretendard !important;
        font-size: 40px;
        font-weight: 600;
        color: #333;
        margin-bottom: 20px;
        @media screen and (max-width: 1460px) {
          font-size: 36px;
        }
        @media screen and (max-width: 880px) {
          font-size: 34px;
        }
        @media screen and (max-width: 505px) {
          font-size: 30px;
          line-height: 1.3;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        color: #444444;
        line-height: 2;
        margin-bottom: 50px;
        @media screen and (max-width: 1460px) {
          font-size: 18px;
          margin-bottom: 100px;
          @media screen and (max-width: 880px) {
            margin-bottom: 0px;
          }
          @media screen and (max-width: 505px) {
            font-size: 16px;
          }
        }
        span {
          display: block;
          word-break: break-word;
          @media screen and (max-width: 1325px) {
            display: inline;
          }
          @media screen and (max-width: 880px) {
            display: block;
          }
          @media screen and (max-width: 640px) {
            display: inline;
          }
        }
      }
      a {
        font-family: Pretendard !important;
        color: #444;
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        padding: 20px 0;
        padding-right: 100px;
        background-image: url('/images/icon/ico-more.png');
        background-repeat: no-repeat;
        background-position: right center;
        transition: 0.3s;
        &:hover {
          background-position: left center;
          padding-right: 0;
          padding-left: 100px;
        }
      }
    }
  }
  .left {
    margin-bottom: 130px;
    @media screen and (max-width: 1500px) {
      margin-bottom: 130px;
    }
    @media screen and (max-width: 880px) {
      margin-bottom: 90px;
    }
    @media screen and (max-width: 505px) {
      margin-bottom: 80px;
    }
    .img {
      opacity: 0;
      transform: translateX(-180px);
      overflow: hidden;
    }
    .txt {
      opacity: 0;
      transform: translateY(100px);
      padding-bottom: 120px;
      @media screen and (max-width: 1325px) {
        padding-bottom: 0;
      }
    }
    &.active {
      .img {
        animation: ${aniLeft} 0.5s linear;
        animation-fill-mode: forwards;
      }
      .txt {
        animation: ${aniTop} 0.5s linear;
        animation-fill-mode: forwards;
      }
    }
  }
  .right {
    justify-content: right;
    @media screen and (max-width: 880px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    &.active {
      .img {
        animation: ${aniRight} 0.5s linear;
        animation-fill-mode: forwards;
      }
      .txt {
        animation: ${aniTop} 0.5s linear;
        animation-fill-mode: forwards;
      }
    }
    .img {
      width: auto;
      flex-grow: 0;
      text-align: right;
      opacity: 0;
      transform: translateX(180px);
      overflow: hidden;
      max-width: 870px;
      width: 100%;
      display: flex;
      justify-content: end;
      @media screen and (max-width: 880px) {
        display: block;
        overflow: unset;
        max-width: 100%;
        img {
          transform: translateX(-20px);
        }
      }
    }
    .txt {
      width: auto;
      flex-grow: 0;
      max-width: 740px;
      width: 100%;
      padding: 0 0 80px 40px;
      opacity: 0;
      transform: translateY(100px);
      @media screen and (max-width: 1500px) {
        padding: 30px 60px 0px 60px;
      }
      @media screen and (max-width: 685px) {
        padding: 30px 40px 0px 40px;
      }
      @media screen and (max-width: 505px) {
        padding: 30px 30px 0px 30px;
      }
    }
  }
`;
export const Main02Wrap = styled.article`
  opacity: 0;
  background-color: #27344c;
  transform: translateY(100px);
  & > div {
    padding: 130px 40px 120px;
    background-image: url('/images/bg/bg-main02.png');
    background-repeat: no-repeat;
    max-width: 1920px;
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
      background-position: 70% center;
    }
    @media screen and (max-width: 1150px) {
      padding: 100px 40px 90px;
      background-position: 80% top;
    }
    @media screen and (max-width: 575px) {
      padding: 85px 30px 80px;
      background-image: none;
    }
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
    /*    .left {
      h2 {
        animation: ${aniTop} 0.3s 0.5s linear;
        animation-fill-mode: forwards;
      }
      .subTit {
        animation: ${aniTop} 0.5s 0.8s linear;
        animation-fill-mode: forwards;
      }
      .sub {
        animation: ${aniTop} 0.5s 1.3s linear;
        animation-fill-mode: forwards;
      }
    }*/
  }
  .inner {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1150px) {
      display: block;
    }
    .left {
      width: 40%;
      @media screen and (max-width: 1150px) {
        width: 100%;
        text-align: center;
      }
      h2 {
        color: #fff;
        font-size: 72px;
        font-weight: 600;
        font-family: SEBANGGothic !important;
        position: relative;
        display: inline-block;
        @media screen and (max-width: 1500px) {
          font-size: 54px;
        }
        @media screen and (max-width: 1150px) {
          font-size: 46px;
        }
        @media screen and (max-width: 575px) {
          font-size: 36px;
        }
        &:after {
          content: '';
          display: inline-block;
          width: 50px;
          height: 50px;
          background-color: rgba(255, 255, 255, 0.05);
          position: absolute;
          right: -20px;
          top: 10px;
          @media screen and (max-width: 1150px) {
            width: 35px;
            height: 35px;
          }
          @media screen and (max-width: 575px) {
            width: 42px;
            height: 42px;
            top: -7px;
          }
        }
      }
      .subTit {
        font-weight: 500;
        font-size: 18px;
        color: #fff;
        font-family: SEBANGGothic !important;
        margin: 5px 0 60px;
        padding-bottom: 60px;
        position: relative;
        @media screen and (max-width: 1500px) {
          font-size: 16px;
          margin: 0px 0 60px;
        }
        @media screen and (max-width: 1150px) {
          padding-bottom: 25px;
          margin-bottom: 25px;
        }
        @media screen and (max-width: 575px) {
          padding-bottom: 20px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 70px;
          height: 2px;
          background-color: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          @media screen and (max-width: 1150px) {
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
          }
        }
      }
      .sub {
        @media screen and (max-width: 1150px) {
          margin-bottom: 55px;
        }
        span {
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          display: block;
          @media screen and (max-width: 1500px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1150px) {
            font-size: 18px;
            :lang(ja),
            :lang(zh) {
              word-break: break-all;
              display: inline;
            }
          }
          @media screen and (max-width: 575px) {
            font-size: 16px;
          }
          @media screen and (max-width: 340px) {
            display: inline;
          }
          &:first-of-type {
            margin-bottom: 25px;
            @media screen and (max-width: 1150px) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .right {
      width: 60%;
      @media screen and (max-width: 1670px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 1150px) {
        width: 100%;
        margin: 0;
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
    @media screen and (max-width: 1670px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 800px) {
      display: block;
      margin: 0 auto 20px;
      text-align: center;
    }
    button {
      flex-grow: 1;
      color: #8e9fbe;
      font-weight: 600;
      font-size: 22px;
      font-family: Pretendard !important;
      padding-top: 15px;
      border-top: 2px solid transparent;
      @media screen and (max-width: 1670px) {
        font-size: 18px;
      }
      @media screen and (max-width: 800px) {
        width: calc(50% - 20px);
        text-align: left;
        border-top: 2px solid #495771;
        margin-right: 25px;
        padding-bottom: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        &:nth-of-type(2),
        &:nth-of-type(4) {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 800px) {
        width: 100%;
        margin-right: 0;
      }
      @media screen and (max-width: 575px) {
        max-width: 270px;
      }
      &.active {
        color: #a0cdf9;
        border-top: 2px solid #a0cdf9;
        span {
          position: relative;
          z-index: 1;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #e13232;
            left: -5px;
            top: 4px;
            z-index: -1;
          }
        }
      }
    }
  }
  .content {
    border: 2px solid #6f8fb3;
    display: flex;
    align-items: center;
    height: 595px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1670px) {
      min-height: 470px;
    }
    @media screen and (max-width: 1150px) {
      display: block;
      text-align: center;
      padding: 75px 0 65px;
      box-sizing: border-box;
    }
    @media screen and (max-width: 1150px) {
      height: auto;
      padding: 60px 0 80px;
    }
    * {
      opacity: 0;
      animation: ${fade} 0.5s linear;
      animation-fill-mode: forwards;
    }
    img {
      display: inline-block;
      margin-left: 80px;
      @media screen and (max-width: 1670px) {
        margin-left: 40px;
        width: 250px;
      }
      @media screen and (max-width: 1150px) {
        margin-left: 0px;
        width: auto;
        height: 300px;
      }
      @media screen and (max-width: 575px) {
        height: 250px;
      }
    }
    & > div {
      padding-left: 80px;
      box-sizing: border-box;
      width: 470px;
      flex-grow: 1;
      @media screen and (max-width: 1670px) {
        padding-left: 50px;
        padding-right: 20px;
      }
      @media screen and (max-width: 1150px) {
        padding: 0;
        margin: 40px auto 0;
      }
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
    .num {
      color: #a0cdf9;
      font-size: 30px;
      font-weight: 600;
      font-family: Pretendard !important;
      display: block;
      width: 100%;
      @media screen and (max-width: 1670px) {
        font-size: 26px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 30px;
      }
      @media screen and (max-width: 575px) {
        font-size: 24px;
      }
    }
    .tit {
      color: #a0cdf9;
      font-size: 60px;
      font-weight: 600;
      font-family: Pretendard !important;
      line-height: 1.2;
      margin: 10px 0 30px;
      @media screen and (max-width: 1670px) {
        font-size: 48px;
        margin: 10px 0 20px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 50px;
      }
      @media screen and (max-width: 575px) {
        font-size: 40px;
      }
      span {
        display: block;
        color: #a0cdf9;
        font-size: 60px;
        font-weight: 600;
        font-family: Pretendard !important;
        line-height: 1;
        @media screen and (max-width: 1670px) {
          font-size: 48px;
        }
        @media screen and (max-width: 1150px) {
          font-size: 50px;
        }
        @media screen and (max-width: 575px) {
          font-size: 40px;
        }
      }
    }
    .txt {
      margin-bottom: 50px;
      width: 100%;
      padding-right: 20px;
      @media screen and (max-width: 1670px) {
        margin-bottom: 40px;
      }
      @media screen and (max-width: 1150px) {
        margin-bottom: 30px;
        padding: 0 20px;
      }
      &.mb35 {
        margin-bottom: 30px;
      }
      span {
        color: #a0cdf9;
        font-size: 16px;
        font-weight: 400;
        display: block;
        line-height: 2;
        word-break: break-word;
        @media screen and (max-width: 1670px) {
          display: inline;
          font-size: 14px;
        }
        @media screen and (max-width: 1150px) {
          font-size: 18px;
          line-height: 1.8;
        }
        @media screen and (max-width: 575px) {
          font-size: 16px;
        }
      }
    }
    a {
      color: #a0cdf9;
      font-weight: 600;
      font-size: 18px;
      font-family: Pretendard !important;
      padding: 10px 0;
      padding-right: 90px;
      background-image: url('/images/icon/ico-main02-more.png');
      background-position: center right;
      background-repeat: no-repeat;
      transition: 0.3s;
      &:hover {
        background-position: center left;
        padding-left: 90px;
        padding-right: 0px;
      }
      @media screen and (max-width: 1150px) {
        font-size: 16px;
      }
    }
  }
`;
export const Main03Wrap = styled.article`
  position: relative;
  &.active {
    .inner > h2 {
      animation: ${aniTop} 0.5s linear;
      animation-fill-mode: forwards;
    }
    .inner > p {
      animation: ${aniTop} 0.3s 0.5s linear;
      animation-fill-mode: forwards;
    }
    .itemList {
      li {
        &:nth-of-type(1) {
          animation: ${aniTop} 0.3s 0.8s linear;
          animation-fill-mode: forwards;
        }
        &:nth-of-type(2) {
          animation: ${aniTop} 0.3s 1.1s linear;
          animation-fill-mode: forwards;
        }
        &:nth-of-type(3) {
          animation: ${aniTop} 0.3s 1.4s linear;
          animation-fill-mode: forwards;
        }
      }
    }
  }
  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 50%;
    background-color: #f3f7fc;
    position: absolute;
    bottom: 0;
  }
  & > div {
    background-image: url('/images/bg/bg-main03.png');
    max-width: 1920px;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
      background-image: none;
    }
  }
  .inner {
    max-width: 1480px;
    margin: 0 auto;
    padding: 150px 40px 130px;
    @media screen and (max-width: 1000px) {
      padding: 110px 40px 100px;
    }
    @media screen and (max-width: 635px) {
      padding: 70px 30px 40px;
    }
    & > h2 {
      font-family: SEBANGGothic !important;
      font-size: 56px;
      font-weight: 600;
      color: #222;
      line-height: 1;
      text-align: center;
      margin-bottom: 20px;
      opacity: 0;
      transform: translateY(100px);
      @media screen and (max-width: 1000px) {
        font-size: 40px;
      }
      @media screen and (max-width: 635px) {
        font-size: 36px;
      }
    }
    & > p {
      color: #333333;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      opacity: 0;
      transform: translateY(100px);
      :lang(ja) {
        word-break: break-word;
      }
      @media screen and (max-width: 1000px) {
        font-size: 18px;
      }
      @media screen and (max-width: 635px) {
        font-size: 16px;
        :lang(ja),
        :lang(zh),
        :lang(en) {
          word-break: break-word;
        }
      }
    }
  }
  .itemList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    margin-top: 70px;
    @media screen and (max-width: 1000px) {
      display: block;
    }
    @media screen and (max-width: 635px) {
      margin-top: 50px;
    }
    li {
      width: 31%;
      background-color: #fff;
      border-top: 8px solid;
      text-align: center;
      box-shadow: 10px 10px 10px 0 rgba(69, 69, 69, 0.06);
      opacity: 0;
      transform: translateY(100px);
      transition: 0.3s;
      max-height: 550px;
      padding-top: 165px;
      background-repeat: no-repeat;
      background-position: center 90px;
      @media screen and (max-width: 1300px) {
        width: calc(33.3% - 20px);
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        box-shadow: 0px 10px 10px 0 rgba(69, 69, 69, 0.06);
        margin-bottom: 45px;
        text-align: left;
        padding-top: 0;
        padding-left: 290px;
        padding-right: 20px;
        background-position: 50px center;
      }
      @media screen and (max-width: 635px) {
        padding: 130px 20px 0;
        background-position: center 70px;
        text-align: center;
        min-height: 400px;
      }
      a {
        padding: 90px 0 60px;
        display: inline-block;
        width: 100%;
        @media screen and (max-width: 1000px) {
          padding: 80px 0 70px;
        }
      }
      &:hover {
        margin-top: -30px;
        .arrow {
          padding-right: 0;
          padding-left: 100px;
          background-position: left center;
        }
      }
      &:nth-of-type(1) {
        border-color: #345888;
        background-image: url('/images/icon/ico-main03-item01.png');
        @media screen and (max-width: 1000px) {
          background-image: url('/images/icon/ico-main03-item01_m.png');
        }
        @media screen and (max-width: 635px) {
          background-image: url('/images/icon/ico-main03-item01.png');
        }
      }
      &:nth-of-type(2) {
        border-color: #4f79b0;
        background-image: url('/images/icon/ico-main03-item02.png');
        @media screen and (max-width: 1000px) {
          background-image: url('/images/icon/ico-main03-item02_m.png');
        }
        @media screen and (max-width: 635px) {
          background-image: url('/images/icon/ico-main03-item02.png');
        }
      }
      &:nth-of-type(3) {
        border-color: #89a9d3;
        background-image: url('/images/icon/ico-main03-item03.png');
        @media screen and (max-width: 1000px) {
          background-image: url('/images/icon/ico-main03-item03_m.png');
        }
        @media screen and (max-width: 635px) {
          background-image: url('/images/icon/ico-main03-item03.png');
        }
      }
      h3 {
        color: #244672;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 25px;
        @media screen and (max-width: 1000px) {
          font-size: 30px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 635px) {
          font-size: 24px;
        }
      }
      p {
        margin-bottom: 30px;
        padding: 0 20px;
        word-break: keep-all;
        height: 77px;
        @media screen and (max-width: 1000px) {
          height: auto;
          padding: 0;
        }
        span {
          color: #41506b;
          font-weight: 400;
          font-size: 16px;
          display: block;
          word-break: keep-all;
          :lang(en) {
            @media screen and (max-width: 1400px) {
              display: inline;
            }
          }
          :lang(ja) {
            word-break: break-word;
            @media screen and (max-width: 1230px) {
              display: inline;
            }
          }
          @media screen and (max-width: 1100px) {
            display: inline;
          }
          @media screen and (max-width: 1000px) {
            display: block;
          }
          @media screen and (max-width: 635px) {
            display: inline;
          }
        }
      }
      .arrow {
        color: #41506b;
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        padding: 20px 0;
        padding-right: 100px;
        background-image: url(/images/icon/ico-more.png);
        background-repeat: no-repeat;
        background-position: right center;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        @media screen and (max-width: 1000px) {
          font-size: 20px;
        }
        @media screen and (max-width: 635px) {
          font-size: 16px;
        }
      }
    }
  }
`;

export const Main04Wrap = styled.article`
  &.active {
    .inner > h2 {
      animation: ${aniTop} 0.5s linear;
      animation-fill-mode: forwards;
    }
    .inner > div p {
      animation: ${aniTop} 0.5s 0.5s linear;
      animation-fill-mode: forwards;
    }
    .inner > div a {
      animation: ${aniRight} 0.3s 0.8s linear;
      animation-fill-mode: forwards;
    }
    ul {
      li {
        animation: ${aniTop} 0.3s 1s linear;
        animation-fill-mode: forwards;
        /*      &:nth-of-type(1) {
          animation: ${aniTop} 0.3s 0.8s linear;
          animation-fill-mode: forwards;
        }
        &:nth-of-type(2) {
          animation: ${aniTop} 0.3s 1.1s linear;
          animation-fill-mode: forwards;
        }
        &:nth-of-type(3) {
          animation: ${aniTop} 0.3s 1.4s linear;
          animation-fill-mode: forwards;
        }
        &:nth-of-type(4) {
          animation: ${aniTop} 0.3s 1.7s linear;
          animation-fill-mode: forwards;
        }*/
      }
    }
  }
  .noList{
    font-size: 20px;
    font-weight: 500;
    padding: 100px 0 0;
    text-align: center;
  }
}
  & > div {
    background-image: url('/images/bg/bg-main03.png');
    max-width: 1920px;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
      background-image: none;
    }
  }
  .inner {
    max-width: 1590px;
    margin: 0 auto;
    padding: 150px 40px 200px;
    @media screen and (max-width: 800px) {
      padding: 100px 40px;
    }
    @media screen and (max-width: 500px) {
      padding: 80px 30px 100px;
    }
    & > h2 {
      font-family: SEBANGGothic !important;
      font-size: 56px;
      font-weight: 600;
      color: #222;
      line-height: 1;
      text-align: center;
      margin-bottom: 20px;
      opacity: 0;
      transform: translateY(100px);
      @media screen and (max-width: 800px) {
        font-size: 40px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 500px) {
        font-size: 36px;
      }
    }
    & > .top {
      text-align: center;
      position: relative;
      a {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #444444;
        font-weight: 600;
        font-size: 16px;
        font-family: Pretendard !important;
        padding: 10px 0;
        padding-right: 80px;
        background-image: url('/images/icon/ico-arrowMore2.png');
        background-position: center right;
        background-repeat: no-repeat;
        transition: 0.3s;
        opacity: 0;
        transform: translateX(180px);
        &:hover {
          background-position: center left;
          padding-left: 80px;
          padding-right: 0px;
        }
        @media screen and (max-width: 800px) {
          bottom: -50px;
        }
        @media screen and (max-width: 500px) {
          bottom: -60px;
        }
      }
      p {
        color: #333333;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        opacity: 0;
        transform: translateY(100px);
        :lang(ja) {
          @media screen and (max-width: 500px) {
            word-break: break-word;
          }
        @media screen and (max-width: 800px) {
          font-size: 18px;
        }
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
  .scroll {
    @media screen and (max-width: 1500px) {
      overflow-x: auto;
      width: 100%;
      padding-bottom: 40px;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        /* 스크롤바 막대 너비 설정 */
        width: 1px;
        height: 3px;
      }
      /* 스크롤바 막대 설정*/
      &::-webkit-scrollbar-thumb {
        /* 스크롤바 막대 높이 설정 */
        background-color: #90ace2;
        /* 스크롤바 둥글게 설정 */
        border-radius: 0px;
      } /* 스크롤바 뒷 배경 설정*/
      &::-webkit-scrollbar-track {
        background-color: #e7edf4;
      }
    }
  }
  ul {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 70px;
    @media screen and (max-width: 1500px) {
      width: 1500px;
    }
    li {
      width: 350px;
      height: 380px;
      display: flex;
      align-items: start;
      justify-content: start;
      border: 2px solid #e9e9e9;
      padding: 75px 45px 0;
      box-sizing: border-box;
      background-color: #fff;
      opacity: 0;
      transform: translateY(100px);
      transition: 0.2s;
      margin-right: 25px;
      &:last-of-type{
        margin-right: 0;
      }
      @media screen and (max-width: 410px) {
        width: 300px;
        &:hover {
          scale: 1;
        }
      }
      @media screen and (max-width: 360px) {
        width: 270px;
        height: 330px;
        padding: 50px 30px 0;
      }
      &:hover {
        scale: 1.1;
      }
      &.new {
        span {
          position: relative;
          &:after {
            width: 10px;
            height: 10px;
            content: '';
            display: inline-block;
            position: absolute;
            background-color: #e13232;
            top: 3px;
            right: -15px;
          }
        }
      }
      span {
        color: #1d489b;
        font-family: SEBANGGothic !important;
        font-weight: 600;
        display: inline-block;
        margin-bottom: 30px;
      }
      h3 {
        width: 100%;
        color: #333333;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 30px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
      p {
        color: #787878;
        font-weight: 400;
        font-size: 17px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
`;
