import styled, { keyframes } from 'styled-components';
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

export const MainWrap = styled.article`
  padding: 0px 0 100px;
  .loading {
    padding: 150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    padding: 0px 0 70px;
  }
  @media screen and (max-width: 550px) {
    padding: 0px 0 60px;
  }
`;

export const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 25px 15px;
  margin-top: 100px;
  border-bottom: 1px solid #525252;
  @media screen and (max-width: 700px) {
    display: block;
    margin-top: 40px;
    padding: 25px 0 30px 0px;
  }
  h2 {
    color: #222222;
    font-size: 40px;
    font-weight: 600;
    @media screen and (max-width: 700px) {
      margin-bottom: 20px;
      font-size: 36px;
    }
    @media screen and (max-width: 550px) {
      font-size: 30px;
    }
  }
  .subTit {
    color: #222222;
    font-size: 30px;
    font-weight: 600;
  }
  .searchItem {
    display: flex;
    align-items: center;
    justify-content: start;
    & > div {
      position: relative;
      @media screen and (max-width: 450px) {
        width: calc(70% - 10px);
      }
      &.data {
        @media screen and (max-width: 450px) {
          width: 100%;
        }
      }
    }
  }
  select,
  input {
    height: 50px;
    background-color: #f2f6f9;
    border: 0;
    color: #555555;
    font-size: 15px;
    font-weight: 400;
    padding-left: 15px;
    &::placeholder {
      color: #555;
      font-size: 15px;
      font-weight: 400;
    }
    @media screen and (max-width: 550px) {
      height: 40px;
      font-size: 14px;
    }
  }
  select {
    width: 120px;
    margin-right: 10px;
    appearance: none;
    padding-right: 20px;
    background-image: url('/images/icon/ico-select-arrow-g.png');
    background-repeat: no-repeat;
    background-position: 89% 50%;
    @media screen and (max-width: 450px) {
      width: 30%;
    }
  }
  input {
    width: 305px;
    padding-right: 70px;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }
  button {
    width: 60px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    img {
      margin-top: 5px;
    }
  }
`;
export const NewsListWrap = styled.ul`
  display: grid;
  margin-top: 60px;
  gap: 45px;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 910px) {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
  }
  @media screen and (max-width: 550px) {
    gap: 30px;
    grid-template-columns: 1fr;
  }
  li {
    height: 340px;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    transition: 0.3s;
    background-color: #fff;
    max-width: 370px;
    @media screen and (max-width: 910px) {
      height: 315px;
    }
    @media screen and (max-width: 550px) {
      height: 300px;
      width: 100%;
      max-width: 100%;
    }
    &:hover {
      transform: translateY(-20px);
    }
    a {
      width: 100%;
      height: 100%;
      padding: 60px 50px 0;
      box-sizing: border-box;
      display: block;
      @media screen and (max-width: 910px) {
        padding: 60px 45px 0;
      }
      @media screen and (max-width: 550px) {
        padding: 50px 40px 0;
      }
      &:hover {
        .newsNew {
          &:before {
            background-color: #5479c4;
          }
        }
        .noticeNew {
          &:before {
            background-color: #e60012;
          }
        }
      }
    }
    h3 {
      color: #333333;
      font-size: 22px;
      font-weight: 600;
      position: relative;
      padding-top: 25px;
      line-height: 1.4;
      margin-bottom: 30px;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      @media screen and (max-width: 550px) {
        font-size: 20px;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #cccccc;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    p {
      color: #787878;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.6;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      @media screen and (max-width: 550px) {
        font-size: 16px;
      }
    }
  }
`;
export const PagingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px;
  @media screen and (max-width: 700px) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 110px;
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #adadad;
    font-size: 16px;
    font-weight: 600;
    font-family: Pretendard !important;
    &.active {
      color: #333333;
      background-color: #f2f6f9;
    }
    @media screen and (max-width: 500px) {
      width: 30px;
      height: 30px;
    }
  }
`;
export const NewsTit = styled.h1`
  padding: 90px 0 75px;
  text-align: center;
  color: #222;
  font-size: 48px;
  line-height: 1;
  font-weight: 600;
  border-bottom: 2px solid #cecece;
  @media screen and (max-width: 800px) {
    font-size: 44px;
  }
  @media screen and (max-width: 580px) {
    font-size: 38px;
    padding: 75px 0 75px;
  }
  p {
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    @media screen and (max-width: 580px) {
      font-size: 16px;
    }
  }
`;
export const NewsWrap = styled.article`
  padding-bottom: 80px;
  margin-bottom: 70px;
  min-height: 60vh;
  .loading {
    width: 100%;
    height: 50vh;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tit {
    padding: 40px 20px 35px;
    border-bottom: 2px solid #e2e2e2;
    @media screen and (max-width: 580px) {
      padding: 30px 15px;
    }
    h2 {
      color: #222222;
      font-weight: 600;
      font-size: 28px;
      @media screen and (max-width: 800px) {
        font-size: 24px;
      }
      @media screen and (max-width: 580px) {
        font-size: 22px;
      }
    }
    p {
      color: #8c8c8c;
      font-size: 18px;
      font-weight: 400;
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
    }
  }
  .content {
    font-size: 18px;
    font-weight: 400;
    color: #404040;
    border-bottom: 2px solid #cecece;
    padding: 75px 0 80px;
    line-height: 1.8;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      padding: 55px 0 80px;
    }
    @media screen and (max-width: 580px) {
      font-size: 16px;
      padding: 50px 0 60px;
    }
    * {
      word-break: break-all;
      font-size: 18px;
      font-weight: 400;
      color: #404040;
      line-height: 1.8;
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
    }
    img {
      max-width: 100%;
    }
  }
  .link {
    background-color: #f2f6f9;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 25px;
    margin-top: 70px;
    @media screen and (max-width: 800px) {
      margin-top: 55px;
    }
    @media screen and (max-width: 580px) {
      margin-top: 40px;
    }
    a {
      color: #282828;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .listLink {
    margin: 75px 0 90px;
    border-bottom: 1px solid #dddddd;
    @media screen and (max-width: 800px) {
      margin: 50px 0 70px;
    }
    @media screen and (max-width: 580px) {
      margin: 40px 0 60px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      padding: 0 25px;
      border-top: 1px solid #dddddd;
      & > span {
        color: #999999;
        font-size: 15px;
        font-weight: 400;
        @media screen and (max-width: 580px) {
          display: none;
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: start;
        width: calc(100% - 100px);
        @media screen and (max-width: 580px) {
          width: 100%;
        }
        span {
          color: #999999;
          font-size: 16px;
          font-weight: 400;
        }
        h3 {
          margin-left: 40px;
          color: #555555;
          font-size: 16px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          width: 85%;
          @media screen and (max-width: 580px) {
            margin-left: 30px;
            width: 100%;
          }
        }
      }
    }
  }
  .listBtn {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 50px;
    background-color: #1a2a55;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 25px;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
`;
export const DataTable = styled.div`
  border-top: 1px solid #272727;
  table {
    .dis-c_m {
      display: none;
      @media screen and (max-width: 700px) {
        display: table-column;
      }
    }
    .dis-n_m {
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    .item {
      &:nth-of-type(odd) {
        td {
          background-color: #f8fafb;
        }
      }
    }
    th {
      background-color: #f8fafb;
      border-bottom: 1px solid #dbdbdb;
      padding: 13px 5px;
      text-align: center;
      color: #404040;
      font-weight: 400;
      font-size: 18px;
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
    td {
      text-align: center;
      padding: 21px 5px;
      vertical-align: middle;
      &:nth-of-type(2) {
        text-align: left;
        padding-left: 55px;
        @media screen and (max-width: 800px) {
          padding-left: 20px;
        }
        @media screen and (max-width: 600px) {
          padding-left: 10px;
        }
      }
      span {
        display: inline-block;
      }
      .num {
        color: #676767;
        font-size: 17px;
        font-weight: 400;
        width: 100%;
        position: relative;
        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 20px;
          background-color: #c6c6c6;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }
      .nameTit {
        display: inline-block;
        width: 100%;
        font-size: 0;
        text-align: left;
      }
      .nameKo {
        color: #363636;
        font-size: 18px;
        font-weight: 500;
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        font-family: 'NotoSansKr' !important;
        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
        @media screen and (max-width: 600px) {
          max-width: 100%;
        }
      }
      .nameEn {
        color: #959595;
        font-size: 18px;
        font-weight: 500;
        vertical-align: middle;
        max-width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
        @media screen and (max-width: 600px) {
          max-width: 100%;
          display: block;
        }
        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
      .fileName {
        background-image: url('/images/icon/ico-file.png');
        background-repeat: no-repeat;
        background-position: left 13px;
        color: #676767;
        font-size: 17px;
        font-width: 400;
        padding: 5px 0px 5px 20px;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        max-width: 100%;
        text-align: left;
        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background-color: #b4b4b4;
        }
      }
      .downloadBtn {
        height: 35px;
        color: #222222;
        font-size: 16px;
        font-weight: 400;
        padding: 0 40px 0 20px;
        background-image: url('/images/icon/ico-download-g.png');
        background-repeat: no-repeat;
        background-position: 110px center;
        border: 1px solid #b8b8b8;
        border-radius: 25px;
        transition: 0.3s;
        vertical-align: top;
        @media screen and (max-width: 800px) {
          height: 30px;
          font-size: 14px;
          padding: 0 30px 0 15px;
          background-position: 90px center;
        }
        @media screen and (max-width: 370px) {
          background-image: none;
          font-size: 15px;
          padding: 0 15px;
        }
        @media screen and (max-width: 340px) {
          background-image: none;
          font-size: 14px;
          padding: 0 10px;
        }
        &:hover {
          background-image: url('/images/icon/ico-download-b.png');
          color: #3662b9;
          border-color: #3662b9;
        }
      }
    }
  }
`;
export const DownLoadPopUp = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(61, 61, 61, 0.3);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .layer {
    background-color: #fff;
    width: 515px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(25, 25, 25, 0.18);
    .dis-n {
      display: none;
    }
    @media screen and (max-width: 540px) {
      width: 420px;
    }
    @media screen and (max-width: 430px) {
      width: 320px;
      .dis-n {
        display: inherit;
      }
    }
    h2 {
      color: #222222;
      font-size: 28px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 5px;
      @media screen and (max-width: 540px) {
        font-size: 26px;
        margin-bottom: 10px;
      }
    }
    p {
      color: #a2a2a2;
      font-size: 16px;
      font-width: 500;
      margin-bottom: 35px;
      padding: 0 10px;
      @media screen and (max-width: 540px) {
        margin-bottom: 25px;
      }
    }
    .close {
      position: absolute;
      top: 20px;
      right: 25px;
    }
    .downloadBtn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      color: #666666;
      font-size: 18px;
      font-weight: 500;
      padding: 0 55px 0 27px;
      background-image: url('/images/icon/ico-download-b-g.png');
      background-repeat: no-repeat;
      background-position: 135px center;
      border: 1px solid #b8b8b8;
      border-radius: 25px;
      transition: 0.3s;
      @media screen and (max-width: 540px) {
        font-size: 16px;
        padding: 0 40px 0 20px;
        background-position: 115px center;
      }
      &:first-of-type {
        margin-right: 10px;
        @media screen and (max-width: 430px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      &:hover {
        background-image: url('/images/icon/ico-download-b-b.png');
        color: #3662b9;
        border-color: #3662b9;
      }
    }
  }
`;
