import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export const languages = ['KOR', 'ENG', 'CHN', 'JPN'] as const;
export type Languages = typeof languages[number];

import KOR from './translation.ko.json';
import ENG from './translation.en.json';
import CHN from './translation.cn.json';
import JPN from './translation.jp.json';

const resources = {
  KOR: { translation: KOR },
  ENG: { translation: ENG },
  CHN: { translation: CHN },
  JPN: { translation: JPN },
};

const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'ENG',
  fallbackLng: 'ENG',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
