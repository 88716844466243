import React, { useEffect } from 'react';

const Video = () => {
  useEffect(() => {
    document.querySelector<any>('header').style.display = 'none';
    document.querySelector<any>('footer').style.display = 'none';
  }, []);
  return (
    <div>
      <video src="/video/video.mp4" width="100%" controls autoPlay></video>
    </div>
  );
};

export default Video;
