import styled, { keyframes } from 'styled-components';
const progress = keyframes`
  0%{width: 0%;}
  100%{
    width: 100%;}
`;
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

export const Main01 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  .tit {
    display: flex;
    padding: 100px 0;
    @media screen and (max-width: 1300px) {
      padding: 100px 0 130px;
    }
    @media screen and (max-width: 900px) {
      display: block;
      padding: 65px 0 85px;
    }
  }
  h2 {
    width: 32%;
    /*    
    color: #222222;
    font-weight: 600;*/
    line-height: 1;
    position: relative;
    @media screen and (max-width: 1300px) {
      margin-left: 20px;
    }
    :lang(en) {
      &:after {
        @media screen and (max-width: 1030px) {
          width: 100px;
        }
      }
    }
    :lang(ja) {
      &:after {
        @media screen and (max-width: 1110px) {
          width: 100px;
        }
        @media screen and (max-width: 970px) {
          width: 80px;
        }
      }
    }
    &:before {
      top: -5px;
      left: -25px;
      @media screen and (max-width: 560px) {
        left: -13px;
        top: -5px;
      }
    }
    &:after {
      width: 200px;
      height: 1px;
      background-color: #292929;
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: 15px;

      @media screen and (max-width: 1300px) {
        right: -30px;
        width: 170px;
      }
      @media screen and (max-width: 1100px) {
        right: -40px;
        width: 140px;
      }
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }
  p {
    width: 68%;
    margin-left: 7% !important;
    box-sizing: border-box;
    @media screen and (max-width: 900px) {
      margin-left: 0px !important;
      width: 100%;
    }
    span {
      color: #2a2929;
      &:nth-of-type(2) {
        margin-bottom: 25px;
        @media screen and (max-width: 1100px) {
          display: inline-block;
        }
      }
      @media screen and (max-width: 1100px) {
        display: inline;
      }
      @media screen and (max-width: 900px) {
        display: block;
      }
    }
  }
`;

export const Main02 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  background-color: #26334b;
  padding: 140px 0 150px;
  @media screen and (max-width: 880px) {
    padding: 100px 0 130px;
  }
  @media screen and (max-width: 560px) {
    padding: 90px 0 100px;
  }
  .tit {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      color: #ffffff;
      margin-left: 0;
      :lang(en) {
        &:before {
          @media screen and (max-width: 450px) {
            left: 55px;
          }
        }
      }
    }
    p {
      span {
        font-weight: 300;
        color: #fff;
        @media screen and (max-width: 560px) {
          display: inline;
        }
      }
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 35px;
    background-color: #32435d;
    border-radius: 180px;
    position: relative;
    @media screen and (max-width: 1200px) {
      display: block;
      text-align: center;
      width: 440px;
      margin: 0 auto;
      border-radius: 250px;
      padding: 45px;
    }
    @media screen and (max-width: 560px) {
      width: 340px;
      padding: 35px;
    }
    @media screen and (max-width: 400px) {
      width: 310px;
      padding: 30px;
    }
    :lang(en) {
      @media screen and (max-width: 360px) {
        padding: 30px 0;
        background-color: transparent;
      }
    }
    @media screen and (max-width: 360px) {
      width: 290px;
    }
    @media screen and (max-width: 340px) {
      width: 270px;
    }
    :lang(en) {
      &:after {
        @media screen and (max-width: 360px) {
          top: 305px;
        }
      }
    }
    &:after {
      content: '';
      display: inline-block;
      width: calc(50% - 530px);
      height: 1px;
      border-top: 1px dashed #fff;
      position: absolute;
      right: calc(50% + 170px);
      @media screen and (max-width: 1200px) {
        top: 422px;
        left: 50%;
        transform: translateX(50%);
        width: 1px;
        height: 75px;
        border-top: 0;
        border-left: 1px dashed #fff;
      }
      @media screen and (max-width: 560px) {
        top: 324px;
      }
      @media screen and (max-width: 400px) {
        top: 300px;
      }
      @media screen and (max-width: 360px) {
        top: 280px;
      }
      @media screen and (max-width: 340px) {
        top: 260px;
      }
    }
    :lang(en) {
      &:before {
        @media screen and (max-width: 360px) {
          top: 670px;
        }
      }
    }
    &:before {
      content: '';
      display: inline-block;
      width: calc(50% - 530px);
      height: 1px;
      border-top: 1px dashed #fff;
      position: absolute;
      left: calc(50% + 170px);
      @media screen and (max-width: 1200px) {
        top: 902px;
        left: 50%;
        transform: translateX(50%);
        width: 1px;
        height: 75px;
        border-top: 0;
        border-left: 1px dashed #fff;
      }
      @media screen and (max-width: 560px) {
        top: 704px;
      }
      @media screen and (max-width: 400px) {
        top: 661px;
      }
      @media screen and (max-width: 360px) {
        top: 620px;
      }
      @media screen and (max-width: 340px) {
        top: 580px;
      }
    }
    li {
      background-color: #fff;
      width: 310px;
      height: 310px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      @media screen and (max-width: 1200px) {
        margin: 0 auto 130px;
        width: 350px;
        height: 350px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 560px) {
        margin: 0 auto 105px;
        width: 275px;
        height: 275px;
      }
      @media screen and (max-width: 400px) {
        margin: 0 auto 105px;
        width: 255px;
        height: 255px;
      }
      @media screen and (max-width: 360px) {
        margin: 0 auto 105px;
        width: 235px;
        height: 235px;
      }
      :lang(en) {
        @media screen and (max-width: 360px) {
          width: 260px;
          height: 260px;
        }
      }
      @media screen and (max-width: 340px) {
        margin: 0 auto 105px;
        width: 215px;
        height: 215px;
      }
      :lang(en) {
        &:before {
          @media screen and (max-width: 360px) {
            width: 280px;
            height: 280px;
          }
        }
      }
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 340px;
        height: 340px;
        border-radius: 50%;
        border: 1px dashed #fff;
        @media screen and (max-width: 1200px) {
          width: 400px;
          height: 400px;
        }
        @media screen and (max-width: 560px) {
          width: 300px;
          height: 300px;
        }
        @media screen and (max-width: 400px) {
          width: 280px;
          height: 280px;
        }
        @media screen and (max-width: 360px) {
          width: 260px;
          height: 260px;
        }
        @media screen and (max-width: 340px) {
          width: 240px;
          height: 240px;
        }
      }
      .num {
        color: #94c6f8;
        font-family: SEBANGGothic !important;
        font-weight: 600;
        font-size: 20px;
        display: block;
        @media screen and (max-width: 1200px) {
          font-size: 22px;
        }
        @media screen and (max-width: 340px) {
          font-size: 20px;
        }
      }
      h3 {
        color: #26334b;
        font-size: 32px;
        font-family: SEBANGGothic !important;
        font-weight: 600;
        line-height: 1;
        margin: 25px 0;
        z-index: 1;
        position: relative;
        display: inline-block;
        :lang(en) {
          @media screen and (max-width: 560px) {
            margin: 15px 0 20px;
          }
        }
        @media screen and (max-width: 560px) {
          font-size: 26px;
        }
        @media screen and (max-width: 340px) {
          font-size: 24px;
        }
        &:before {
          z-index: -1;
          content: '';
          display: inline-block;
          width: 45px;
          height: 45px;
          background-color: rgba(160, 205, 249, 0.3);
          border-radius: 50%;
          left: -12px;
          top: -8px;
          position: absolute;
        }
      }
      p {
        :lang(en) {
          width: 100%;
          margin: 0 auto;
        }
        span {
          color: #5e6675;
          font-size: 17px;
          font-weight: 400;
          display: block;
          :lang(ja) {
            @media screen and (max-width: 360px) {
              font-size: 14px;
            }
            @media screen and (max-width: 340px) {
              font-size: 13px;
            }
          }
          :lang(zh) {
            @media screen and (max-width: 360px) {
              font-size: 14px;
            }
            @media screen and (max-width: 340px) {
              font-size: 13px;
            }
          }
          :lang(en) {
            @media screen and (max-width: 560px) {
              font-size: 14px;
            }
          }
          @media screen and (max-width: 560px) {
            font-size: 16px;
          }
          @media screen and (max-width: 340px) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const Main03 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  margin: 0 auto;
  text-align: center;
  padding: 150px 0 120px;
  @media screen and (max-width: 1200px) {
    padding: 100px 0 70px;
  }
  @media screen and (max-width: 560px) {
    padding: 80px 0 50px;
  }
  .tit {
    text-align: center;
    margin-bottom: 80px;
    @media screen and (max-width: 560px) {
      margin-bottom: 60px;
    }
    h2,
    p {
      margin-left: 0;
      span {
        @media screen and (max-width: 820px) {
          display: inline;
        }
      }
    }
  }
  ul {
    display: inline-block;
    position: relative;
    transform: translateX(-55px);
    @media screen and (max-width: 1480px) {
      transform: translateX(-35px);
    }
    @media screen and (max-width: 1200px) {
      display: grid;
      text-align: center;
      grid-template-columns: repeat(3, 1fr);
      transform: translateX(0px);
      width: 650px;
      margin: 0 auto;
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
      width: 440px;
    }
    @media screen and (max-width: 560px) {
      width: 320px;
    }
    @media screen and (max-width: 390px) {
      width: 235px;
    }
    li {
      flex-basis: 260px;
      width: 260px;
      height: 260px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      @media screen and (max-width: 1480px) {
        width: 220px;
        height: 220px;
      }
      @media screen and (max-width: 1200px) {
        width: 220px;
        height: 220px;
        transform: translateX(0px) !important;
        margin: 0 auto;
      }
      @media screen and (max-width: 560px) {
        width: 180px;
        height: 180px;
      }
      @media screen and (max-width: 390px) {
        width: 130px;
        height: 130px;
      }
      h3 {
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        padding-top: 80px;
        background-repeat: no-repeat;
        background-position: center top;
        flex-grow: 1;
        width: 100%;
        text-align: center;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        @media screen and (max-width: 1200px) {
          font-size: 20px;
        }
        @media screen and (max-width: 560px) {
          font-size: 18px;
          background-size: 50px;
          padding-top: 60px;
        }
        :lang(en) {
          @media screen and (max-width: 390px) {
            font-size: 14px;
            padding: 50px 20px 0;
          }
        }
        @media screen and (max-width: 390px) {
          font-size: 16px;
          background-size: 40px;
          padding-top: 50px;
        }
      }
      &:nth-of-type(1) {
        transform: translateX(0);
        background-color: rgba(79, 118, 196, 0.6);
        h3 {
          background-image: url('/images/icon/ico-about03-item01.png');
          :lang(en) {
            @media screen and (max-width: 560px) {
              flex: 0;
              width: 100px;
              margin-top: 20px;
            }
            @media screen and (max-width: 390px) {
              padding: 35px 20px 0;
              margin-top: 10px;
            }
          }
        }
        @media screen and (max-width: 1200px) {
          margin-right: -25px;
        }
        @media screen and (max-width: 720px) {
          margin-left: 25px;
        }
        @media screen and (max-width: 560px) {
          margin-left: 0px;
        }
        @media screen and (max-width: 390px) {
          margin-right: 0;
          margin-left: 0px;
        }
      }
      &:nth-of-type(2) {
        transform: translateX(-30px);
        background-color: rgba(79, 118, 196, 0.7);
        h3 {
          background-image: url('/images/icon/ico-about03-item02.png');
        }
        @media screen and (max-width: 720px) {
          margin-left: -25px;
          margin-right: 0;
        }
        @media screen and (max-width: 560px) {
          margin-left: -40px;
          margin-right: 0;
        }
        @media screen and (max-width: 390px) {
          margin-left: -25px;
          margin-right: 0;
        }
      }
      &:nth-of-type(3) {
        transform: translateX(-60px);
        background-color: rgba(79, 118, 196, 0.8);
        h3 {
          background-image: url('/images/icon/ico-about03-item03.png');
        }
        @media screen and (max-width: 1200px) {
          margin-left: -25px;
        }
        @media screen and (max-width: 720px) {
          margin-top: -25px;
          margin-left: 25px;
        }
        @media screen and (max-width: 560px) {
          margin-left: 0px;
          margin-top: -25px;
        }
        @media screen and (max-width: 390px) {
          margin-right: 0px;
          margin-top: -20px;
          margin-left: 0px;
        }
      }
      &:nth-of-type(4) {
        transform: translateX(-90px);
        background-color: rgba(23, 168, 193, 0.6);
        h3 {
          background-image: url('/images/icon/ico-about03-item04.png');
        }
        @media screen and (max-width: 1200px) {
          margin-right: -25px;
          margin-top: -25px;
        }
        @media screen and (max-width: 720px) {
          margin-left: -25px;
          margin-top: -25px;
          margin-right: 0;
        }
        @media screen and (max-width: 560px) {
          margin-left: -40px;
          margin-top: -25px;
          margin-right: 0;
        }
        @media screen and (max-width: 390px) {
          margin-left: -25px;
          margin-top: -20px;
          margin-right: 0;
        }
      }
      &:nth-of-type(5) {
        transform: translateX(-120px);
        background-color: rgba(75, 196, 181, 0.7);
        h3 {
          background-image: url('/images/icon/ico-about03-item05.png');
        }
        @media screen and (max-width: 1200px) {
          margin-top: -25px;
        }
        @media screen and (max-width: 720px) {
          margin-top: -25px;
          margin-left: 25px;
        }
        @media screen and (max-width: 560px) {
          margin-top: -25px;
          margin-left: 0px;
        }
        @media screen and (max-width: 390px) {
          margin-right: 0;
          margin-top: -20px;
          margin-left: 0px;
        }
      }
      &:nth-of-type(6) {
        background-color: rgba(67, 209, 192, 0.7);
        h3 {
          background-image: url('/images/icon/ico-about03-item06.png');
        }
        position: absolute;
        transform: translateX(-150px);
        @media screen and (max-width: 1200px) {
          position: unset !important;
          margin-left: -25px;
          margin-top: -25px;
        }
        @media screen and (max-width: 720px) {
          margin-left: -25px;
          margin-top: -25px;
          margin-right: 0;
        }
        @media screen and (max-width: 560px) {
          margin-left: -40px;
          margin-top: -25px;
          margin-right: 0;
        }
        @media screen and (max-width: 390px) {
          margin-left: -25px;
          margin-top: -20px;
          margin-right: 0;
        }
      }
    }
  }
`;

export const Main04 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  margin-bottom: 100px;
  padding-top: 120px;
  @media screen and (max-width: 1200px) {
    padding: 70px 0 50px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 560px) {
    padding: 50px 0;
    margin-bottom: 0;
  }
  .tit {
    text-align: left;
    margin-bottom: 100px;
    @media screen and (max-width: 1000px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 560px) {
      margin-bottom: 55px;
    }
    & > div {
      display: flex;
      align-items: start;
      justify-content: space-between;
      div {
        width: 170px;
      }
      p {
        :lang(en) {
          width: calc(100% - 220px);
          @media screen and (max-width: 1000px) {
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        display: block;
      }
      span {
        :lang(en) {
          @media screen and (max-width: 760px) {
            display: inline;
          }
        }
        :lang(ja),
        :lang(zh) {
          @media screen and (max-width: 730px) {
            display: inline;
          }
        }
        @media screen and (max-width: 600px) {
          display: inline;
        }
      }
      div {
        @media screen and (max-width: 1000px) {
          margin-top: 40px;
        }
        @media screen and (max-width: 560px) {
          margin-top: 30px;
        }
      }
      button {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #f7f7f7;
        @media screen and (max-width: 1000px) {
          width: 50px;
          height: 50px;
          img {
            width: 17px;
          }
        }
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
`;
export const SliderWrap = styled.div`
  position: relative;
  .fakeBox {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2;
    width: calc(1400px / 4);
  }
  .hide {
    opacity: 0;
  }
  .slick-list {
    overflow: unset;
  }
  .slick-active {
    .slideItem {
      h3,
      li {
        color: #444444;
        transition: 0.3s;
      }
      h3:before {
        background-color: #444;
        transition: 0.3s;
      }
    }
  }
  .slick-current {
    .slideItem {
      h3,
      li {
        word-break: break-word;
        color: #406bc1;
        :lang(en),
        :lang(ko) {
          @media screen and (max-width: 390px) {
            word-break: keep-all;
          }
        }
      }
      h3:before {
        background-color: #406bc1;
      }
      h3:after {
        animation: ${progress} 6s linear;
        animation-fill-mode: forwards;
      }
    }
  }
  .slideItem {
    h3 {
      color: #e4e4e4;
      font-size: 30px;
      font-weight: 600;
      font-family: SEBANGGothic !important;
      padding-bottom: 40px;
      margin-bottom: 50px;
      border-bottom: 2px solid #e4e4e4;
      position: relative;
      @media screen and (max-width: 1000px) {
        font-size: 26px;
        padding-bottom: 35px;
        margin-bottom: 35px;
      }
      @media screen and (max-width: 560px) {
        font-size: 24px;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
      &:after {
        content: '';
        display: inline-block;
        height: 2px;
        background-color: #406bc1;
        width: 0%;
        position: absolute;
        left: 0;
        bottom: -1.5px;
      }
      &:before {
        content: '';
        display: inline-block;
        background-color: #e4e4e4;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        bottom: -4px;
        left: 0;
        position: absolute;
        z-index: 1;
      }
    }
    li {
      color: #e4e4e4;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 15px;
      word-break: keep-all;
      padding-right: 20px;
      min-width: 350px;
      @media screen and (max-width: 1450px) {
        min-width: 320px;
      }
      @media screen and (max-width: 700px) {
        min-width: 250px;
      }
      :lang(ja),
      :lang(zh) {
        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
`;
export const Main05 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  padding: 140px 0 200px;
  background-color: #26334b;
  @media screen and (max-width: 1450px) {
    padding: 140px 0 100px;
    min-height: 845px;
  }
  @media screen and (max-width: 1060px) {
    padding: 110px 0;
  }
  @media screen and (max-width: 700px) {
    text-align: center;
    min-height: 1325px;
  }
  @media screen and (max-width: 560px) {
    padding: 80px 0;
  }
  h2 {
    color: #ffffff;
    margin-bottom: 50px;
    @media screen and (max-width: 1060px) {
      margin-bottom: 70px;
    }
    @media screen and (max-width: 560px) {
      margin-bottom: 40px;
    }
  }
  ul {
    display: flex;
    align-items: start;
    justify-content: space-between;
    height: 490px;
    @media screen and (max-width: 1060px) {
      display: block;
      height: auto;
    }
    li {
      text-align: center;
      flex-grow: 1;
      width: 25%;
      padding: 0 15px;
      @media screen and (max-width: 1060px) {
        width: 33.3%;
        display: inline-block;
        margin-bottom: 50px;
        height: auto;
        vertical-align: top;
        min-height: 330px;
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 700px) {
        width: 50%;
        &:nth-of-type(4) {
          margin-bottom: 50px;
        }
        &:nth-of-type(5) {
          margin-right: 50%;
        }
      }
      @media screen and (max-width: 350px) {
        width: 100%;
        &:nth-of-type(4) {
          margin-bottom: 50px;
        }
        &:nth-of-type(5) {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 420px) {
        &:nth-of-type(2) {
          span {
            display: inline;
          }
        }
      }
      img {
        max-width: 100%;
      }
    }
    h3 {
      margin-top: 20px;
      span {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        display: block;
        :lang(zh), :lang(ja) {
          word-break: break-word;
        }
        @media screen and (max-width: 1450px) {
          font-size: 18px;
        }
        @media screen and (max-width: 560px) {
          font-size: 16px;
        }
        @media screen and (max-width: 400px) {
          display: inline;
          }
        @media screen and (max-width: 350px) {
          display: block;
        }
        }
      }
    }
  }
`;
export const Main06 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  padding: 120px 0 250px;
  text-align: center;
  background-color: #f5f8fb;
  height: 1070px;
  @media screen and (max-width: 1450px) {
    padding: 120px 0 130px;
    height: 915px;
  }
  @media screen and (max-width: 900px) {
    padding: 105px 0;
    height: 700px;
  }
  @media screen and (max-width: 700px) {
    height: 600px;
  }
  @media screen and (max-width: 560px) {
    padding: 100px 0 90px;
    height: 600px;
  }
  @media screen and (max-width: 450px) {
    padding: 50px 0 50px;
    height: 450px;
  }
  img {
    max-width: 100%;
    @media screen and (max-width: 750px) {
      margin-top: 90px;
    }
  }
  div {
    position: relative;
  }
  h2 {
    color: #26334b;
    vertical-align: top;
    position: absolute;
    left: 0;
    top: 10px;
    @media screen and (max-width: 750px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Main07 = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  margin: 0 auto;
  padding: 120px 40px 200px;
  max-width: 1520px;
  @media screen and (max-width: 1000px) {
    padding: 100px 40px 120px;
  }
  @media screen and (max-width: 560px) {
    padding: 90px 30px;
  }
  .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    span {
      display: inline-block;
      font-family: SEBANGGothic !important;
      color: #f4f4f4;
      font-size: 100px;
      font-weight: 600;
      line-height: 1;
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    @media screen and (max-width: 560px) {
      margin-bottom: 30px;
    }
  }
  h2 {
    margin-bottom: 0;
  }
  .map > div {
    width: 100%;
    height: 570px;
    @media screen and (max-width: 800px) {
      height: 330px;
    }
    @media screen and (max-width: 560px) {
      height: 280px;
    }
    @media screen and (max-width: 400px) {
      height: 210px;
    }
  }
  .txt {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-top: 40px;
    }
    .right {
      width: 44%;
      max-width: 620px;
      overflow: hidden;
      text-align: center;
      img {
        height: 100%;
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 50px;
        img {
          width: 100%;
        }
      }
      @media screen and (max-width: 560px) {
        margin-bottom: 30px;
      }
    }
    .left {
      width: 54%;
      margin-right: 2%;
      @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
      h3 {
        color: #222222;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 30px;
        @media screen and (max-width: 560px) {
          font-size: 28px;
          margin-bottom: 25px;
        }
      }
      ul {
        li {
          display: flex;
          margin-bottom: 15px;
          &:nth-of-type(1) {
            @media screen and (max-width: 560px) {
              display: block;
            }
            h4 {
              @media screen and (max-width: 560px) {
                width: 100%;
                margin-bottom: 10px;
              }
            }
            p {
              @media screen and (max-width: 560px) {
                width: 100%;
              }
            }
          }
          &:nth-of-type(4) {
            @media screen and (max-width: 560px) {
              display: block;
            }
            h4 {
              @media screen and (max-width: 560px) {
                width: 100%;
                margin-bottom: 10px;
              }
            }
          }
          h4 {
            width: 20%;
            flex-basis: 20%;
            color: #222222;
            font-weight: 500;
            font-size: 18px;
            box-sizing: border-box;
            padding-right: 15px;
            @media screen and (max-width: 1450px) {
              font-size: 17px;
            }
            @media screen and (max-width: 560px) {
              font-size: 16px;
            }
          }
          p {
            color: #555555;
            font-size: 18px;
            font-weight: 500;
            width: 80%;
            flex-basis: 80%;
            @media screen and (max-width: 1450px) {
              font-size: 17px;
            }
            @media screen and (max-width: 560px) {
              font-size: 17px;
            }
            span {
              color: #555555;
              font-size: 18px;
              font-weight: 500;
              display: block;
              :lang(zh),
              :lang(ja) {
                word-break: break-word;
              }
              @media screen and (max-width: 1450px) {
                font-size: 17px;
              }
              @media screen and (max-width: 1000px) {
                font-size: 16px;
              }
              &.mb15 {
                margin-bottom: 15px;
              }
            }
          }
          .txtBox {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-grow: 1;
            @media screen and (max-width: 1450px) {
              display: block;
            }
            @media screen and (max-width: 1000px) {
              display: flex;
            }
            @media screen and (max-width: 780px) {
              display: block;
            }
            div {
              height: 90px;
              width: 47%;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: start;
              flex-wrap: wrap;
              padding-left: 20px;
              @media screen and (max-width: 1450px) {
                width: 395px;
                padding-left: 25px;
                height: 55px;
                padding-right: 25px;
                :lang(ja),
                :lang(en) {
                  height: 90px;
                }
              }
              @media screen and (max-width: 1000px) {
                height: 90px;
                width: 47%;
                padding-right: 10px;
              }
              @media screen and (max-width: 780px) {
                width: 100%;
              }
              p {
                flex-grow: 1;
              }
              span {
                color: #fff;
                display: block;
                @media screen and (max-width: 1450px) {
                  display: inline-block;
                  &:first-of-type {
                    margin-right: 10px;
                    padding-right: 10px;
                    position: relative;
                    &:after {
                      content: '';
                      display: inline-block;
                      background-color: #fff;
                      width: 1px;
                      height: 10px;
                      position: absolute;
                      right: 0;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }
                @media screen and (max-width: 1000px) {
                  display: block;
                  &:first-of-type {
                    margin-right: 0px;
                    padding-right: 0px;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
              &:first-of-type {
                background-color: #597ec8;
                margin-right: 15px;
                @media screen and (max-width: 1450px) {
                  margin-right: 0;
                  margin-bottom: 20px;
                }
                @media screen and (max-width: 1000px) {
                  margin-right: 15px;
                  margin-bottom: 0px;
                }
                @media screen and (max-width: 780px) {
                  margin-bottom: 20px;
                }
              }
              &:last-of-type {
                background-color: #78cddc;
              }
            }
          }
        }
      }
    }
  }
`;
