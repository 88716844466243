export const sliderData = [
  {
    id: 1,
    title: 'TR_SLIDER_TIT_01',
    sub: 'TR_SLIDER_SUB_01',
  },
  {
    id: 2,
    title: 'TR_SLIDER_TIT_02',
    sub: 'TR_SLIDER_SUB_02',
  },
  {
    id: 3,
    title: 'TR_SLIDER_TIT_03',
    sub: 'TR_SLIDER_SUB_03',
  },
];
