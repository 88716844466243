import useLocalStorage from 'hooks/useLocalStorage';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PagingWrap } from '../style';

const paging = ({ list }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageResult, setPageResult] = useState<any>();
  const [pageArr, setPageArr] = useLocalStorage('pageArr', 0);
  const pageVal = Number(searchParams.get('pageNo'));

  useEffect(() => {
    setPageResult(null);
    const result = [];
    const sliceResult = [];

    for (let i = 0; i < list?.obj?.endPageNo; i++) {
      result.push(i + 1);
    }
    for (let i = 0; i < result.length; i += 5) {
      sliceResult.push(result.slice(i, i + 5));
    }
    setPageResult(sliceResult[pageArr]);

    setPageArr(Math.floor((pageVal - 1) / 5));
  }, [list, pageArr, pageVal, searchParams]);

  const onClickPage = useCallback(
    (id: any) => {
      if (pageVal) {
        searchParams.set('pageNo', id);
      } else {
        searchParams.set('pageNo', id);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <PagingWrap>
      <button type="button" onClick={() => onClickPage(list?.obj?.firstPageNo)}>
        <img src="/images/icon/ico-first.png" alt="첫페이지 이동" />
      </button>
      <button type="button" onClick={() => onClickPage(list?.obj?.prevPageNo)}>
        <img src="/images/icon/ico-prev.png" alt="이전 페이지 이동" />
      </button>
      {pageResult?.map((item: any) => (
        <button key={item} type="button" onClick={() => onClickPage(item)} className={pageVal === item ? 'active' : ''}>
          {item}
        </button>
      ))}
      <button type="button" onClick={() => onClickPage(list?.obj?.nextPageNo)}>
        <img src="/images/icon/ico-next.png" alt="다음 페이지 이동" />
      </button>
      <button type="button" onClick={() => onClickPage(list?.obj?.finalPageNo)}>
        <img src="/images/icon/ico-last.png" alt="마지막 페이지 이동" />
      </button>
    </PagingWrap>
  );
};

export default paging;
