import { useRef } from 'react';

//hook
function useMoveScrool() {
  const element = useRef<HTMLDivElement | null>(null);

  const onMoveToElement = () => {
    const posY: any = element?.current?.offsetTop;

    window.scrollTo({
      top: posY,
      behavior: 'smooth',
    });
  };
  return { element, onMoveToElement };
}

export default useMoveScrool;
