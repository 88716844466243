import styled, { keyframes } from 'styled-components';
const menuBar01 = keyframes`
  0% {
    transform : translateY(10px) rotate(45deg);
  }
  50% {
    transform : translateY(10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
`;
const menuBar02 = keyframes`
  0% {
    transform : translateY(-10px) rotate(-45deg);
  }
  50% {
    transform : translateY(-10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
`;
const activeMenuBar01 = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(10px) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
`;
const activeMenuBar02 = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-13px) rotate(0);
  }
  100% {
    transform: translateY(-13px) rotate(-45deg);
  }
`;
export const HeaderWrap = styled.header`
  width: 100%;
  height: 90px;
  padding: 0 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  transition: 0.3s;
  box-sizing: border-box;
  transition-delay: 0.3s;
  z-index: 999;
  @media screen and (max-width: 1300px) {
    height: 80px;
    padding: 0 40px;
  }
  @media screen and (max-width: 500px) {
    height: 70px;
    padding: 0 30px;
  }
  & > a {
    position: relative;
    width: 130px;
    height: 46px;
    @media screen and (max-width: 1300px) {
      width: 95px;
      height: 33px;
    }
    img {
      max-width: 100%;
    }
  }
  &.white {
    background-color: #fff;
    border-color: #eee;
    .mbBtn {
      span {
        background-color: #424242;
      }
    }
    .pc {
      .mainTit {
        color: #3b3b3b;
        transition-delay: 0s;

        &:hover {
          color: #072762;
        }
      }
    }

    .titBtn {
      color: #26334b;
      transition-delay: 0s;
      background-image: url('/images/icon/ico-lang-b.png');
    }
  }
  &.active {
    background-color: #fff;
    border-color: #eee;
    transition-delay: 0s;
    .hide {
      height: 370px;
    }

    .pc {
      &:after {
        height: 370px;
        box-shadow: 0px 5px 10px rgba(180, 180, 180, 0.1);
      }

      .mainTit {
        color: #3b3b3b;
        transition-delay: 0s;

        &:hover {
          color: #072762;
        }
      }
    }

    .titBtn {
      color: #26334b;
      transition-delay: 0s;
      background-image: url('/images/icon/ico-lang-b.png');
    }
  }

  .pc {
    height: calc(100% + 1px);
    @media screen and (max-width: 1300px) {
      display: none;
    }
    &:after {
      content: '';
      display: inline-block;
      background-color: #fff;
      position: absolute;
      height: 0px;
      width: 100%;
      left: 0;
      right: 0;
      top: 90px;
      transition: all 0.3s ease 0s;
    }
  }
  .bg-dark {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
export const MenuTitleWrap = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
`;
export const MenuTitle = styled.li`
  width: 165px;
  position: relative;
  height: 100%;
  border-bottom: 2px solid transparent;
  &:nth-of-type(4) {
    span {
      max-width: 105px;
    }
  }
  &:last-of-type {
    span {
      max-width: 100%;
    }
  }
  &:hover {
    border-color: #072762;

    ul {
      background-color: #fafafa;
    }

    & > .mainTit {
      color: #072762 !important;
      transition-delay: 0s;

      span {
        &:after {
          display: inline-block;
        }
      }
    }
  }

  & > a {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: 0.3s;
    position: relative;
    transition-delay: 0.3s;
    span {
      display: inline-block;
      position: relative;
      text-align: center;
      max-width: 115px;
      line-height: 1.3;
      &:after {
        content: '';
        display: none;
        width: 8px;
        height: 8px;
        background-color: #e13232;
        top: 0px;
        right: -10px;
        position: absolute;
      }
    }
  }

  ul {
    position: absolute;
    top: 90px;
    transition: 0s;
    text-align: center;
    z-index: 2;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease 0s;

    li {
      width: 100%;

      &:first-of-type {
        padding-top: 15px;
      }

      a {
        padding: 10px 0px;
        width: 100%;
        display: inline-block;
        color: #414141;
        font-size: 16px;
        font-weight: 400;
        transition: 0.3s;
        line-height: 1.3;
        word-break: break-word;
        &:hover {
          font-weight: 500;
          color: #072762;
        }
      }
    }
  }
`;
export const LangBtnWrap = styled.div`
  position: relative;
  width: 80px;
  @media screen and (max-width: 1300px) {
    display: none;
  }
  &:hover {
    ul {
      height: 177px;
      border-color: #26334b;

      li:first-of-type:before {
        display: block;
      }
    }
  }

  & > button {
    background-image: url('/images/icon/ico-lang-w.png');
    background-repeat: no-repeat;
    background-position: left 25px;
    padding: 20px 15px 20px 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    transition-delay: 0.3s;
  }

  ul {
    position: absolute;
    width: 80px;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
    top: 60px;
    height: 0;
    overflow: hidden;
    transition: 0.3s;

    li {
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;

      button {
        padding: 10px 0;
        color: #909090;

        &:hover {
          color: #26334b;
        }
      }

      &:first-of-type {
        position: relative;

        &:before {
          content: '';
          display: none;
          top: 0;
          left: -1px;
          right: -1px;
          width: calc(100% + 2px);
          height: 4px;
          background-color: #26334b;
          position: absolute;
        }

        button {
          padding: 20px 0 10px;
          width: 100%;
          height: 100%;
        }
      }

      &:last-of-type {
        button {
          padding: 10px 0 20px;
        }

        border-bottom: 1px solid #ccc;
      }
    }
  }
`;
export const MenuTrigger = styled.button`
  @media screen and (max-width: 1300px) {
    display: inline-block;
  }
  position: absolute;
  width: 35px;
  height: 25px;
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  border: 0;
  background-color: transparent;
  z-index: 9999;
  display: none;
  right: 60px;
  @media screen and (max-width: 500px) {
    right: 30px;
  }
  &.active {
    span {
      background-color: #424242;
      &:nth-of-type(1) {
        animation: ${activeMenuBar01} 0.75s forwards;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        animation: ${activeMenuBar02} 0.75s forwards;
      }
    }
  }
  span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 9991;
    background-color: #fff;
    &:nth-of-type(1) {
      top: 0;
      animation: ${menuBar01} 0.75s forwards;
    }
    &:nth-of-type(2) {
      top: 12px;
      opacity: 1;
      width: 25px;
    }
    &:nth-of-type(3) {
      bottom: 0;
      animation: ${menuBar02} 0.75s forwards;
    }
  }
`;
export const MobileNav = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 85%;
  max-width: 510px;
  height: 100%;
  background-color: #ffffff;
  z-index: 9990;
  transform: translateX(100%);
  transition: 0.5s;
  nav {
    height: calc(100% - 170px);
    overflow-y: auto;
    margin-top: 90px;
    &::-webkit-scrollbar {
      /* 스크롤바 막대 너비 설정 */
      width: 6px;
    }
    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb {
      /* 스크롤바 막대 높이 설정 */
      height: 17%;
      background-color: #888;
      /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
    } /* 스크롤바 뒷 배경 설정*/
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  &.active {
    transform: translateX(0%);
  }
  .top {
    height: 80px;
    border-bottom: 1px solid #e2e2e2;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    img {
      width: 100px;
    }
  }
`;
export const MenuList_M = styled.ul`
  padding: 10px 65px 10px 40px;

  @media screen and (max-width: 500px) {
    padding: 10px 30px 10px 30px;
  }
  & > li {
    width: 100%;
    overflow: hidden;
    &.active {
      button {
        background-image: url('/images/icon/ico-menu_m-minus.png');
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: #e13232;
          position: absolute;
          left: 0px;
          top: 20px;
        }
      }
      &:nth-of-type(1) {
        .hide {
          height: 350px;
        }
      }
      &:nth-of-type(2) {
        .hide {
          height: 250px;
        }
      }
      &:nth-of-type(3) {
        .hide {
          height: 290px;
        }
      }
      &:nth-of-type(4) {
        .hide {
          height: 60px;
        }
      }
      &:nth-of-type(5) {
        .hide {
          height: 180px;
        }
      }
    }
  }
  button {
    padding: 20px 0 20px 10px;
    background-image: url('/images/icon/ico-menu_m-plus.png');
    background-repeat: no-repeat;
    background-position: right center;
    width: 100%;
    text-align: left;

    span {
      color: #000000;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .hide {
    transition: 0.3s;
    height: 0;
    padding-left: 30px;
    li {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        color: #5d5d5d;
        font-size: 18px;
        font-weight: 400;
        &:hover,
        &:active {
          color: #072762;
          font-weight: 500;
          border-bottom: 1px solid #072762;
        }
      }
    }
  }
`;

export const LangBtn_M = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 35px;
  position: absolute;
  top: 80px;
  border-bottom: 1px solid #e2e2e2;
  @media screen and (max-width: 500px) {
    padding: 0 30px 0 30px;
  }
  @media screen and (max-width: 340px) {
    padding: 0 0px 0 20px;
  }
  button {
    color: #b5b5b5;
    font-size: 16px;
    font-weight: 500;
    padding: 0 15px;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      background-color: #cecece;
      width: 1px;
      height: 14px;
      position: absolute;
      right: 0;
      bottom: 4px;
    }
    &:last-of-type {
      &:before {
        display: none;
      }
    }
    &.active {
      color: #2d2d2d;
    }
  }
`;
