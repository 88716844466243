import { BannerImg, BannerTop, MainWrap, MaxWidth, SubTit, TitTab, TopBtn } from 'Global';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMoveScrool from '../../hooks/useMoveScroll';
import { ImgWrap, Main01, Main02 } from './style';
import { throttle } from 'lodash';

const Management = () => {
  const { t } = useTranslation();
  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const [tab, setTab] = useState<number>(1);
  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    length: 2,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;
        const mainTop02 = tabRef[1].element?.current?.getBoundingClientRect().top;
        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }
        if (mainTop02 && mainTop02 <= 900) {
          setScrollEvent(2);
        }
        if (mainTop02 && mainTop02 <= -650) {
          setScrollEvent(3);
        }
        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [tabRef, scrollEvent],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop>
          <h1>
            <span>{t('TR_MANAGEMENT_TIT_01')}</span>
            <span>{t('TR_MANAGEMENT_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="management">
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_MANAGEMENT_TAB_01')}
          </button>
          <button type="button" className={scrollEvent === 2 ? ' active' : ''} onClick={tabRef[1].onMoveToElement}>
            {t('TR_MANAGEMENT_TAB_02')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg className="management"></BannerImg>
      <MaxWidth>
        <Main01 ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
          <h2>{t('TR_MANAGEMENT_MAIN_01_TIT')}</h2>
          <p>{t('TR_MANAGEMENT_MAIN_01_TXT')}</p>
          <ImgWrap>
            <img src="/images/img-Quality.png" alt="Quality" />
            <img src="/images/img-Quality_t.png" alt="Quality" />
            <img src="/images/img-Quality_m.png" alt="Quality" />
          </ImgWrap>
        </Main01>
      </MaxWidth>
      <Main02 ref={tabRef[1].element} className={scrollEvent >= 2 ? ' active' : ''}>
        <MaxWidth>
          {/*<div className="inner">
            <div>
              <h2>환경경영</h2>
              <p>ENVIRONMENT</p>
            </div>
          </div>*/}
          <ImgWrap>
            <img src="/images/img-Environment.png" alt="Environment" />
            <img src="/images/img-Environment_t.png" alt="Environment" />
            <img src="/images/img-Environment_m.png" alt="Environment" />
          </ImgWrap>
        </MaxWidth>
      </Main02>
      {scrollEvent !== 0 && (
        <TopBtn className={scrollEvent === 2 ? 'active' : ''} onClick={onTop}>
          TOP
        </TopBtn>
      )}
    </MainWrap>
  );
};

export default Management;
