import styled, { keyframes } from 'styled-components';

const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

export const MainWrap = styled.section`
  margin-top: 100px;
`;
export const Main01 = styled.article`
  padding: 150px 40px;
  @media screen and (max-width: 1050px) {
    padding: 80px 60px;
  }
  @media screen and (max-width: 650px) {
    padding: 50px 0px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 48%;
      padding: 65px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-bottom: 1px solid #d9d9d9;
      :lang(en) {
        width: 100%;
      }
      @media screen and (max-width: 1050px) {
        width: 100%;
      }
      @media screen and (max-width: 580px) {
        padding: 55px 0;
      }
      &:nth-of-type(1) {
        border-top: 2px solid #000000;
        div {
          background-image: url('/images/icon/ico-welfare-item01.png');
        }
      }
      &:nth-of-type(2) {
        border-top: 2px solid #000000;
        :lang(en) {
          border-top: 0;
        }
        @media screen and (max-width: 1050px) {
          border-top: 0;
        }
        div {
          background-image: url('/images/icon/ico-welfare-item02.png');
        }
      }
      &:nth-of-type(3) {
        div {
          background-image: url('/images/icon/ico-welfare-item03.png');
        }
      }
      &:nth-of-type(4) {
        div {
          background-image: url('/images/icon/ico-welfare-item04.png');
        }
      }
      &:nth-of-type(5) {
        div {
          background-image: url('/images/icon/ico-welfare-item10.png');
        }
      }
      &:nth-of-type(6) {
        div {
          background-image: url('/images/icon/ico-welfare-item06.png');
        }
      }
      &:nth-of-type(7) {
        div {
          background-image: url('/images/icon/ico-welfare-item07.png');
        }
      }
      &:nth-of-type(8) {
        div {
          background-image: url('/images/icon/ico-welfare-item08.png');
        }
      }
      &:nth-of-type(9) {
        div {
          background-image: url('/images/icon/ico-welfare-item09.png');
        }
      }
      &:nth-of-type(10) {
        div {
          background-image: url('/images/icon/ico-welfare-item05.png');
        }
      }
      &:nth-of-type(11) {
        border-bottom: 0;
        :lang(en) {
          border-bottom: 1px solid #d9d9d9;
        }
        @media screen and (max-width: 1050px) {
          border-bottom: 1px solid #d9d9d9;
        }
        div {
          background-image: url('/images/icon/ico-welfare-item11.png');
        }
      }
      &:nth-of-type(12) {
        border-bottom: 0;
        div {
          background-image: url('/images/icon/ico-welfare-item12.png');
        }
      }
      div {
        background-image: url('/images/icon/ico-welfare-item01.png');
        background-repeat: no-repeat;
        background-position: left center;
        padding: 15px 150px;
        padding-right: 0;
        width: 450px;
        box-sizing: border-box;
        :lang(en) {
          width: 100%;
          margin-left: 80px;
          @media screen and (max-width: 1200px) {
            padding: 15px 150px;
            padding-right: 0;
          }
          @media screen and (max-width: 1100px) {
            margin-left: 60px;
          }
          @media screen and (max-width: 950px) {
            margin-left: 0px;
            width: 500px;
          }
          @media screen and (max-width: 580px) {
            width: 80%;
            padding: 15px 100px;
            padding-right: 0;
          }
          @media screen and (max-width: 480px) {
            width: 80%;
            padding: 100px 0px 0;
            padding-right: 0;
            background-position: center top;
          }
        }
        @media screen and (max-width: 1200px) {
          padding: 15px 100px;
          width: 400px;
        }
        @media screen and (max-width: 1050px) {
          width: 450px;
          padding: 15px 150px;
          padding-right: 0;
        }
        @media screen and (max-width: 580px) {
          width: 270px;
          padding: 15px 100px;
          padding-right: 0;
        }
        @media screen and (max-width: 430px) {
          width: 300px;
          padding: 15px 100px;
          padding-right: 0;
        }
        @media screen and (max-width: 380px) {
          width: 250px;
          padding: 15px 100px;
          padding-right: 0;
        }
        h2 {
          color: #222222;
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 20px;
          line-height: 1;
          white-space: nowrap;
          :lang(en) {
            white-space: pre-wrap;
            line-height: 1.4;
            @media screen and (max-width: 480px) {
              text-align: center;
            }
          }
          @media screen and (max-width: 580px) {
            font-size: 22px;
          }
          @media screen and (max-width: 360px) {
            font-size: 18px;
            white-space: pre-wrap;
            line-height: 1.4;
          }
        }
        p {
          color: #525252;
          font-size: 18px;
          font-weight: 400;
          background-image: url('/images/icon/ico-welfare-check.png');
          background-repeat: no-repeat;
          background-position: left 12px;
          white-space: nowrap;
          padding: 2px 0 2px 25px;
          :lang(en) {
            white-space: pre-wrap;
          }
          :lang(ja),
          :lang(zh) {
            @media screen and (max-width: 1050px) {
              white-space: pre-wrap;
              word-break: break-word;
            }
          }
          @media screen and (max-width: 580px) {
            font-size: 16px;
            white-space: pre-wrap;
          }
          @media screen and (max-width: 360px) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
