import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HeaderWrap, LangBtn_M, LangBtnWrap, MenuList_M, MenuTitle, MenuTitleWrap, MenuTrigger, MobileNav } from './style';
import { menuData } from './menuData';
import { useTranslation } from 'react-i18next';
import { languages, Languages } from 'lang/i18n';
import { random, throttle } from 'lodash';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Header = () => {
  const [menuList] = useState(menuData);
  const [active, setActive] = useState(false);
  const [lang, setLang] = useState<string | null>('ENG');
  const [htmlLang, setHtmlLang] = useState<string>('en');
  const [params, setParams] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);
  const [activeMenu, setActiveMenu] = useState(false);
  const [toggleNo, setToggleNo] = useState<number>(0);
  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    setLang(lang);
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        setScrollY(window.scrollY);
      }, 100),
    [],
  );
  useEffect(() => {
    setLang(localStorage.getItem('language'));
    if (!localStorage.getItem('language')) {
      setLang('ENG');
    }
    if (localStorage.getItem('language') === 'KOR') {
      setHtmlLang('ko');
    }
    if (localStorage.getItem('language') === 'ENG') {
      setHtmlLang('en');
    }
    if (localStorage.getItem('language') === 'CHN') {
      setHtmlLang('zh');
    }
    if (localStorage.getItem('language') === 'JPN') {
      setHtmlLang('ja');
    }
    setParams(window.location.pathname !== '/');
  }, [params, lang, useLocation()]);

  useEffect(() => {
    if (pathname === '/') {
      window.addEventListener('scroll', throttledScroll);
    }
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll, pathname]);

  useEffect(() => {
    setActiveMenu(false);
    setToggleNo(0);
  }, [pathname]);

  const onClickMenu = useCallback(() => {
    setActiveMenu((prev) => !prev);
  }, [activeMenu]);

  const toggleMenu = useCallback<any>(
    (id: number) => {
      if (toggleNo === id) {
        setToggleNo(0);
      } else {
        setToggleNo(id);
      }
    },
    [toggleNo],
  );
  return (
    <HeaderWrap className={active ? 'active' : '' || params || scrollY !== 0 ? 'white' : ''}>
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: htmlLang }}></Helmet>
      </HelmetProvider>
      <Link to="/" title="메인페이지 이동" onClick={() => window.scrollTo(0, 0)}>
        <img src="/images/logo-b.png" alt="UNIC" />
      </Link>
      <nav className="pc" onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)}>
        <MenuTitleWrap>
          {menuList.map((item) => (
            <MenuTitle key={item.id}>
              <Link to={item.link} className="mainTit">
                <span>{t(item.title)}</span>
              </Link>
              <ul className="hide">
                {item.arr.map((list) => (
                  <li key={list.id}>
                    <Link to={list.link}>{t(list.title)}</Link>
                  </li>
                ))}
              </ul>
            </MenuTitle>
          ))}
        </MenuTitleWrap>
      </nav>
      <LangBtnWrap>
        <button type="button" className="titBtn">
          {lang}
        </button>
        <ul>
          {languages.map((lang) => (
            <li key={lang}>
              <button onClick={() => handleChangeLanguage(lang)}>{lang}</button>
            </li>
          ))}
        </ul>
      </LangBtnWrap>
      <MenuTrigger onClick={onClickMenu} className={'mbBtn ' + (activeMenu ? 'active' : '')}>
        <span></span>
        <span></span>
        <span></span>
      </MenuTrigger>
      {activeMenu && <div className="bg-dark"></div>}
      <MobileNav className={activeMenu ? 'active' : ''}>
        <div className="top">
          <img src="/images/logo-b.png" alt="UNIC" />
        </div>
        <LangBtn_M>
          {languages.map((lang) => (
            <button
              key={lang}
              className={localStorage.getItem('language') === lang ? 'active' : '' || (!localStorage.getItem('language') && lang === 'ENG') ? 'active' : ''}
              onClick={() => handleChangeLanguage(lang)}
            >
              {lang}
            </button>
          ))}
        </LangBtn_M>
        <nav>
          <MenuList_M>
            {menuList.map((item) => (
              <li key={item.id} className={toggleNo === item.id ? 'active' : ''}>
                <button className="mainTit" onClick={() => toggleMenu(item.id)}>
                  <span>{t(item.title)}</span>
                </button>
                <ul className="hide">
                  {item.arr.map((list) => (
                    <li key={list.id}>
                      <Link to={list.link}>{t(list.title)}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </MenuList_M>
        </nav>
      </MobileNav>
    </HeaderWrap>
  );
};

export default Header;
