export const menuData = [
  {
    id: 1,
    title: 'TR_NAV_TIT_01',
    link: '/about',
    arr: [
      { id: 1, title: 'TR_NAV_TIT_01_ARR_01', link: '/about/overview' },
      { id: 2, title: 'TR_NAV_TIT_01_ARR_02', link: '/about/philosophy' },
      { id: 3, title: 'TR_NAV_TIT_01_ARR_03', link: '/about/coreValue' },
      { id: 4, title: 'TR_NAV_TIT_01_ARR_04', link: '/about/history' },
      { id: 5, title: 'TR_NAV_TIT_01_ARR_05', link: '/about/certification' },
      { id: 6, title: 'TR_NAV_TIT_01_ARR_06', link: '/about/organizationChart' },
      { id: 7, title: 'TR_NAV_TIT_01_ARR_07', link: '/about/map' },
    ],
  },
  {
    id: 2,
    title: 'TR_NAV_TIT_02',
    link: '/product',
    arr: [
      { id: 1, title: 'TR_NAV_TIT_02_ARR_01', link: '/product/dryer' },
      { id: 2, title: 'TR_NAV_TIT_02_ARR_02', link: '/product/podCleaner' },
      { id: 3, title: 'TR_NAV_TIT_02_ARR_03', link: '/product/maskCleaner' },
      { id: 4, title: 'TR_NAV_TIT_02_ARR_04', link: '/product/singleProcessor' },
      { id: 5, title: 'TR_NAV_TIT_02_ARR_05', link: '/product/management' },
    ],
  },
  {
    id: 3,
    title: 'TR_NAV_TIT_03',
    link: '/support',
    arr: [
      { id: 1, title: 'TR_NAV_TIT_03_ARR_01', link: '/support/technical' },
      { id: 2, title: 'TR_NAV_TIT_03_ARR_02', link: '/support/network' },
      { id: 3, title: 'TR_NAV_TIT_03_ARR_03', link: '/support/contact' },
      { id: 4, title: 'TR_NAV_TIT_03_ARR_05', link: '/news/notice?pageNo=1' },
      { id: 5, title: 'TR_NAV_TIT_03_ARR_04', link: '/news/news?pageNo=1' },
      { id: 6, title: 'TR_NAV_TIT_03_ARR_06', link: '/news/reference?pageNo=1' },
    ],
  },
  {
    id: 4,
    title: 'TR_NAV_TIT_04',
    link: '/welfare',
    arr: [{ id: 1, title: 'TR_NAV_TIT_04_ARR_01', link: '/welfare/welfare' }],
  },
  {
    id: 5,
    title: 'TR_NAV_TIT_05',
    link: '/employment',
    arr: [
      { id: 1, title: 'TR_NAV_TIT_05_ARR_01', link: '/employment/information' },
      { id: 2, title: 'TR_NAV_TIT_05_ARR_02', link: '/employment/talent' },
      { id: 3, title: 'TR_NAV_TIT_05_ARR_03', link: '/employment/recruitment' },
      { id: 4, title: 'TR_NAV_TIT_05_ARR_04', link: '/employment/inquiry' },
    ],
  },
];
