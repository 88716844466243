import styled from 'styled-components';

export const PopupWrap = styled.section`
  padding: 0 20px;
  &.formWrap {
    padding: 0;
    h1 {
      padding: 10px 0;
    }
  }
  h1 {
    padding: 20px 0px;
    border-bottom: 1px solid rgb(221, 221, 221);
    line-height: 25px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  span,
  p {
    word-break: keep-all;
    line-height: 1.8;
  }
  & > p {
    margin-bottom: 20px;
  }
  h2 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  /*  .light {
    background-color: yellow;
    display: inline-block;
    margin-bottom: 5px;
  }*/
  table {
    th,
    td {
      border: 1px solid #555;
      padding: 5px;
      text-align: left;
    }
    th {
      background-color: #ddd;
    }
  }
  .list-var2 {
    li {
      margin-bottom: 10px;
    }
  }
`;
