import React from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from 'google-maps-react';

const center = {
  lat: 37.1795,
  lng: 127.0937,
};
const markerCoors = {
  lat: 37.1795,
  lng: 127.0937,
};
const OPTIONS = {
  minZoom: 4,
  maxZoom: 18,
};

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCD76nONGSmOMS59VKHaF8YesiT2BJL5g8',
  });

  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap center={center} options={OPTIONS} zoom={18} onLoad={onLoad} onUnmount={onUnmount}>
      <MarkerF position={markerCoors}></MarkerF>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
