import React, { useCallback, useState } from 'react';
import { SearchWrap } from '../style';
import { SubTit } from 'Global';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useInput from 'hooks/useInput';

const Search = ({ tab, selected, setSelected, text, onChangeText }: any) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const txtVal = searchParams.get('searchText');
  const typeVal = searchParams.get('searchType');

  const selectList = [
    { id: 1, value: 'title', name: t('TR_NEWS_SEARCH_SELECT_TXT_01') },
    { id: 2, value: 'content', name: t('TR_NEWS_SEARCH_SELECT_TXT_02') },
  ];

  const handleSelect = useCallback(
    (e: any) => {
      setSelected(e.target.value);
    },
    [selected],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (text) {
        if (txtVal) {
          searchParams.set('searchText', text);
        } else {
          searchParams.append('searchText', text);
        }
      } else {
        searchParams.delete('searchText');
      }

      if (typeVal) {
        searchParams.set('searchType', selected);
      } else {
        searchParams.append('searchType', selected);
      }
      searchParams.set('pageNo', '1');
      setSearchParams(searchParams);
    },
    [selected, text, txtVal],
  );

  return (
    <SearchWrap className={tab == 3 ? 'data' : ''}>
      <h2>
        {(tab === 1 && t('TR_NEWS_SEARCH_TIT_01')) || (tab === 2 && t('TR_NEWS_SEARCH_TIT_02')) || (tab === 3 && t('TR_NEWS_SEARCH_TIT_03'))}
        {tab === 3 && <span className="subTit">(Data)</span>}
      </h2>
      <form onSubmit={onSubmit}>
        <div className="searchItem">
          {tab !== 3 ? (
            <select onChange={handleSelect} value={selected}>
              {selectList.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          ) : null}

          <div className={tab == 3 ? 'data' : ''}>
            <input type="text" placeholder={tab !== 3 ? t('TR_NEWS_SEARCH_INPUT_TXT') : 'Please enter a search term'} value={text} onChange={onChangeText} />
            <button type="submit">
              <img src="/images/icon/ico-search.png" alt="검색버튼" />
            </button>
          </div>
        </div>
      </form>
    </SearchWrap>
  );
};

export default Search;
