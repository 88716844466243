import React, { useEffect, useState } from 'react';
import { FooterWrap } from './style';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const [white, setWhite] = useState(false);

  useEffect(() => {
    if (pathname.split('/')[1] === 'product' && pathname.split('/')[2] !== 'management') {
      setWhite(true);
    } else {
      setWhite(false);
    }
  }, [white, pathname]);
  return (
    <FooterWrap className={white ? 'white' : ''}>
      <div className="inner">
        <div className="top">
          <img src="/images/logo-b.png" alt="UNIC" />
        </div>
        <div className="mid">
          <div>
            <span>Head Quarter : 46 Dongtansandan 10-gil, Hwaseong-si, Gyeonggi-do, Republic of Korea</span>
            <span>Factory : 46 Dongtansandan 10-gil, Hwaseong-si, Gyeonggi-do</span>
          </div>

          {localStorage.getItem('language') === 'KOR' && (
            <div>
              <button
                type="button"
                onClick={() => {
                  window.open('/popup/termPersonal', 'window', 'width=600, height=800, location=no, status=no, scrollbars=yes');
                }}
              >
                {t('TR_FOOTER_INFO')}
              </button>
              <button
                type="button"
                onClick={() => {
                  window.open('/popup/termEmail', 'window', 'width=600, height=800, location=no, status=no, scrollbars=yes');
                }}
              >
                {t('TR_FOOTER_EMAIL')}
              </button>
            </div>
          )}
        </div>
        <div className="bot">
          <span>
            <span className="number">Business Registration Number : 135-86-47339</span> <span className="m15">TEL : 031-366-4678</span>
            <span className="fax">FAX : 031-366-2379</span>
          </span>
          <span>
            Email : support@unic-global.com <span className="email">/ askterry12@unic-global.com</span>
          </span>
        </div>
      </div>
    </FooterWrap>
  );
};

export default Footer;
