import React, { useEffect, useMemo, useState } from 'react';
import useMoveScrool from 'hooks/useMoveScroll';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import { MaxWidth, BannerImg, BannerTop, TitTab, MainWrap, TopBtn } from 'Global';
import { Main01 } from './style';
import { useLocation } from 'react-router-dom';

const Welfare = () => {
  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const { pathname } = useLocation();
  const { element, onMoveToElement } = useMoveScrool();
  const { t, i18n } = useTranslation();
  const interval: any = undefined;

  const tabRef: any = {
    0: useMoveScrool(),
    length: 1,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;

        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }

        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [],
  );

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  useEffect(() => {
    if (pathname === '/welfare') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/welfare/welfare') {
      tabRef[0].onMoveToElement();
      setScrollEvent(1);
    }
  }, [window.location.pathname]);
  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop>
          <h1>
            <span>{t('TR_WELFARE_TIT_01')}</span>
            <span>{t('TR_WELFARE_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab>
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_WELFARE_TAB')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg className="welfare"></BannerImg>
      <MaxWidth>
        <Main01 ref={tabRef[0].element} className={scrollEvent === 1 ? ' active' : ''}>
          <ul>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM01_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM01_TXT_01')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM02_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM02_TXT_01')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM03_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM03_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM03_TXT_02')}</p>
                <p>{t('TR_WELFARE_ITEM03_TXT_03')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM04_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM04_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM04_TXT_02')}</p>
                <p>{t('TR_WELFARE_ITEM04_TXT_03')}</p>
              </div>
            </li>

            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM05_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM05_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM05_TXT_02')}</p>
                <p>{t('TR_WELFARE_ITEM05_TXT_03')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM06_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM06_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM06_TXT_02')}</p>
                <p>{t('TR_WELFARE_ITEM06_TXT_03')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM07_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM07_TXT_01')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM08_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM08_TXT_01')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM09_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM09_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM09_TXT_02')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM10_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM10_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM10_TXT_02')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM11_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM11_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM11_TXT_02')}</p>
              </div>
            </li>
            <li>
              <div>
                <h2>{t('TR_WELFARE_ITEM12_TIT')}</h2>
                <p>{t('TR_WELFARE_ITEM12_TXT_01')}</p>
                <p>{t('TR_WELFARE_ITEM12_TXT_02')}</p>
              </div>
            </li>
          </ul>
        </Main01>
      </MaxWidth>
    </MainWrap>
  );
};

export default Welfare;
