import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from 'components/header';
import Footer from 'components/footer';
import Main from 'pages/main';
import About from 'pages/about';
import Welfare from 'pages/welfare';
import GlobalStyle from 'Global';
import './font.css';
import Support from 'pages/support';
import News from 'pages/news';
import View from 'pages/news/View';
import TermPersonal from 'pages/popup/TermPersonal';
import TermEmail from 'pages/popup/TermEmail';
import Management from 'pages/management';
import Product from 'pages/product';
import Video from 'pages/popup/Video';
import Employment from 'pages/employment';
function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/') {
      window.scrollTo({
        top: 0,
      });
    }
  }, [pathname]);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/*" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/:params" element={<About />} />
        <Route path="/welfare" element={<Welfare />} />
        <Route path="/welfare/:params" element={<Welfare />} />
        <Route path="/support" element={<Support />} />
        <Route path="/support/:params" element={<Support />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/view/:id" element={<View />} />
        <Route path="/notice/view/:id" element={<View />} />
        <Route path="/news/:params" element={<News />} />
        <Route path="/product/management" element={<Management />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/employment" element={<Employment />} />
        <Route path="/employment/:params" element={<Employment />} />
        <Route path="/popup/termPersonal" element={<TermPersonal />} />
        <Route path="/popup/termEmail" element={<TermEmail />} />
        <Route path="/popup/video" element={<Video />} />
      </Routes>
      <Footer />
      <GlobalStyle />
    </>
  );
}

export default App;
