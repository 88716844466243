import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Main02Wrap } from './style';
import { tabData } from './tabData';
import { useTranslation } from 'react-i18next';

type TabData = {
  id: number;
  title: string;
  img: string;
  link: string;
  num: string;
  tit: string;
  tit02: string;
  txt01: string;
  txt02: string;
  txt03: string;
  txt04: string;
};

type Main02 = {
  titRef02: React.ForwardedRef<HTMLDivElement | null>;
  scrollEvent: number;
};

const Main02 = ({ titRef02, scrollEvent }: Main02) => {
  const { t, i18n } = useTranslation();
  const [tab, setTab] = useState<number>(1);

  const onClickTab = useCallback(
    (id: number) => {
      setTab(id);
    },
    [tab],
  );

  return (
    <Main02Wrap ref={titRef02} className={scrollEvent >= 2 ? 'active' : ''}>
      <div>
        <div className="inner">
          <div className="left">
            <h2>{t('TR_MAIN_02_TAB_TIT')}</h2>
            <p className="subTit">{t('TR_MAIN_02_TAB_SUB_TIT')}</p>
            <p className="sub">
              <span>{t('TR_MAIN_02_TAB_SUB_01')}</span>
              <span>{t('TR_MAIN_02_TAB_SUB_02')}</span>
              <span>{t('TR_MAIN_02_TAB_SUB_03')}</span>
            </p>
          </div>
          <div className="right">
            <div className="btnGroup">
              {tabData.map((item: TabData) => (
                <button key={item.id} type="button" onClick={() => onClickTab(item.id)} className={tab === item.id ? 'active' : ''}>
                  <span>{t(item.title)}</span>
                </button>
              ))}
            </div>
            {tabData.map((item: TabData) =>
              tab == item.id ? (
                <div key={item.id} className="content">
                  <img src={item.img} alt={t(item.tit)} />
                  <div>
                    <span className="num">{item.num}</span>
                    <h3 className="tit">
                      <span>{t(item.tit)}</span>
                      {t(item.tit02)}
                    </h3>
                    <p className={'txt' + (item.id === 3 ? ' mb30' : '')}>
                      <span>{t(item.txt01)}</span>
                      <span>{t(item.txt02)}</span>
                      <span>{t(item.txt03)}</span>
                    </p>
                    <Link to={item.link}>MORE</Link>
                  </div>
                </div>
              ) : null,
            )}
          </div>
        </div>
      </div>
    </Main02Wrap>
  );
};

export default Main02;
