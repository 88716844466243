import React, { useEffect, useMemo, useState } from 'react';
import { MaxWidth, BannerImg, BannerTop, TitTab, MainWrap, TopBtn, SubTit } from 'Global';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useMoveScrool from 'hooks/useMoveScroll';
import { throttle } from 'lodash';
import { Main01, Main02, Main03, Main04 } from './style';

const Employment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const [tab, setTab] = useState<number>(1);

  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    2: useMoveScrool(),
    3: useMoveScrool(),
    4: useMoveScrool(),
    5: useMoveScrool(),
    length: 6,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;
        const mainTop02 = tabRef[1].element?.current?.getBoundingClientRect().top;
        const mainTop03 = tabRef[2].element?.current?.getBoundingClientRect().top;
        const mainTop04 = tabRef[3].element?.current?.getBoundingClientRect().top;

        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }
        if (mainTop02 && mainTop02 <= 900) {
          setScrollEvent(2);
        }
        if (mainTop03 && mainTop03 <= 900) {
          setScrollEvent(3);
        }
        if (mainTop04 && mainTop04 <= 900) {
          setScrollEvent(4);
        }
        if (mainTop04 && mainTop04 <= 500) {
          setScrollEvent(5);
        }
        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [tabRef, scrollEvent],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  useEffect(() => {
    if (pathname === '/employment') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/employment/information') {
      tabRef[0].onMoveToElement();
    }
    if (pathname === '/employment/talent') {
      tabRef[1].onMoveToElement();
    }
    if (pathname === '/employment/recruitment') {
      tabRef[2].onMoveToElement();
    }
    if (pathname === '/employment/inquiry') {
      tabRef[3].onMoveToElement();
    }
  }, [pathname]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop>
          <h1>
            <span>{t('TR_EMPLOYMENT_TIT_01')}</span>
            <span>{t('TR_EMPLOYMENT_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="employment">
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_EMPLOYMENT_TAB_01')}
          </button>
          <button type="button" className={scrollEvent === 2 ? ' active' : ''} onClick={tabRef[1].onMoveToElement}>
            {t('TR_EMPLOYMENT_TAB_02')}
          </button>
          <button type="button" className={scrollEvent === 3 ? ' active' : ''} onClick={tabRef[2].onMoveToElement}>
            {t('TR_EMPLOYMENT_TAB_03')}
          </button>
          <button type="button" className={scrollEvent === 4 ? ' active' : ''} onClick={tabRef[3].onMoveToElement}>
            {t('TR_EMPLOYMENT_TAB_04')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg className="employment"></BannerImg>
      <Main01 ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
        <h2>
          <span>{t('TR_EMPLOYMENT_MAIN_01_TIT_01')}</span>
          <span>{t('TR_EMPLOYMENT_MAIN_01_TIT_02')}</span>
        </h2>
        <div>
          <p>
            <span>{t('TR_EMPLOYMENT_MAIN_01_TXT_01')}</span>
            <span>{t('TR_EMPLOYMENT_MAIN_01_TXT_02')}</span>
          </p>
          <p>
            <span>{t('TR_EMPLOYMENT_MAIN_01_TXT_03')}</span>
            <span>{t('TR_EMPLOYMENT_MAIN_01_TXT_04')}</span>
          </p>
          <span>{t('TR_EMPLOYMENT_MAIN_01_TXT_05')}</span>
        </div>
      </Main01>
      <Main02 ref={tabRef[1].element} className={scrollEvent >= 2 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_EMPLOYMENT_MAIN_02_TIT')}</h2>
            <p>
              <span>{t('TR_EMPLOYMENT_MAIN_02_TXT_01')}</span>
              <span>{t('TR_EMPLOYMENT_MAIN_02_TXT_02')}</span>
            </p>
          </SubTit>
        </MaxWidth>
        <div className="content">
          <MaxWidth className="inner">
            <div className="item">
              <h3>
                <span>C</span>onsiderate
              </h3>
              <p>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_01_TXT_01')}</span>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_01_TXT_02')}</span>
              </p>
            </div>
            <div className="item">
              <h3>
                <span>C</span>onfident
              </h3>
              <p>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_02_TXT_01')}</span>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_02_TXT_02')}</span>
              </p>
            </div>
            <div className="item">
              <h3>
                <span>C</span>reator
              </h3>
              <p>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_03_TXT_01')}</span>
                <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_03_TXT_02')}</span>
              </p>
            </div>
            <div className="item">
              <div>
                <h3>
                  UNI<span className="normal">+</span>
                  <span className="bold">C</span>
                </h3>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_04_TXT_01')}</span>
                  <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_04_TXT_02')}</span>
                  <span>{t('TR_EMPLOYMENT_MAIN_02_ITEM_04_TXT_03')}</span>
                </p>
              </div>
            </div>
          </MaxWidth>
        </div>
      </Main02>
      <Main03 ref={tabRef[2].element} className={scrollEvent >= 3 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2>{t('TR_EMPLOYMENT_MAIN_03_TIT')}</h2>
            <div>
              <a href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/unickorea?ChkDispType=1" target="_blank">
                {t('TR_EMPLOYMENT_MAIN_03_LINK_01_TXT')}
              </a>
              <a href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=Rnp6eVFCbzdGSHhDQTZhUy8rR3FDQT09" target="_blank">
                {t('TR_EMPLOYMENT_MAIN_03_LINK_02_TXT')}
              </a>
            </div>
          </SubTit>
          <ul>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_01_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_01_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_01_TXT_02')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_02_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_02_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_02_TXT_02')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_03_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_03_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_03_TXT_02')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_04_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_04_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_04_TXT_02')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_05_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_05_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_05_TXT_02')}</span>
                </p>
              </div>
            </li>
            <li>
              <div>
                <h4>{t('TR_EMPLOYMENT_MAIN_03_ITEM_06_TIT')}</h4>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_06_TXT_01')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_06_TXT_02')}</span>
                </p>
                <p>
                  <span>{t('TR_EMPLOYMENT_MAIN_03_ITEM_06_TXT_03')}</span>
                  <span className="fc-b">{t('TR_EMPLOYMENT_MAIN_03_ITEM_06_TXT_04')}</span>
                </p>
              </div>
            </li>
          </ul>
        </MaxWidth>
      </Main03>
      <Main04 ref={tabRef[3].element}>
        <div>
          <SubTit className="tit">
            <h2>{t('TR_EMPLOYMENT_MAIN_04_TIT')}</h2>
            <p>
              <span>{t('TR_EMPLOYMENT_MAIN_04_TXT_01')}</span>
              <span>{t('TR_EMPLOYMENT_MAIN_04_TXT_02')}</span>
            </p>
          </SubTit>
          <ul>
            <li>
              <p>{t('TR_EMPLOYMENT_MAIN_04_LIST_01_TXT_01')}</p>
              <span>{t('TR_EMPLOYMENT_MAIN_04_LIST_01_TXT_02')}</span>
            </li>
            <li>
              <p>{t('TR_EMPLOYMENT_MAIN_04_LIST_02_TXT')}</p>
              <span>031-366-4678</span>
            </li>
            <li>
              <p>{t('TR_EMPLOYMENT_MAIN_04_LIST_03_TXT')}</p>
              <span>support@unic-global.com</span>
            </li>
          </ul>
        </div>
      </Main04>
      {scrollEvent !== 0 && (
        <TopBtn className={scrollEvent === 2 || scrollEvent === 4 ? 'active' : ''} onClick={onTop}>
          TOP
        </TopBtn>
      )}
    </MainWrap>
  );
};

export default Employment;
