export const tabData = [
  {
    id: 1,
    title: '01 DRYER',
    img: '/images/img-main02-item01.png',
    link: '/product/dryer',
    num: '01.',
    tit: 'TR_MAIN_02_TAB_ITEM_01_TIT',
    tit02: 'TR_MAIN_02_TAB_ITEM_01_TIT_02',
    txt01: 'TR_MAIN_02_TAB_ITEM_01_TXT_01',
    txt02: 'TR_MAIN_02_TAB_ITEM_01_TXT_02',
    txt03: 'TR_MAIN_02_TAB_ITEM_01_TXT_03',
    txt04: 'TR_MAIN_02_TAB_ITEM_01_TXT_04',
  },
  {
    id: 2,
    title: '02 POD CLEANER',
    img: '/images/img-main02-item02.png',
    link: '/product/podCleaner',
    num: '02.',
    tit: 'TR_MAIN_02_TAB_ITEM_02_TIT',
    tit02: 'TR_MAIN_02_TAB_ITEM_02_TIT_02',
    txt01: 'TR_MAIN_02_TAB_ITEM_02_TXT_01',
    txt02: 'TR_MAIN_02_TAB_ITEM_02_TXT_02',
    txt03: 'TR_MAIN_02_TAB_ITEM_02_TXT_03',
    txt04: 'TR_MAIN_02_TAB_ITEM_02_TXT_04',
  },
  {
    id: 3,
    title: '03 MASK CLEANER',
    img: '/images/img-main02-item03.png',
    link: '/product/maskCleaner',
    num: '03.',
    tit: 'TR_MAIN_02_TAB_ITEM_03_TIT',
    tit02: 'TR_MAIN_02_TAB_ITEM_03_TIT_02',
    txt01: 'TR_MAIN_02_TAB_ITEM_03_TXT_01',
    txt02: 'TR_MAIN_02_TAB_ITEM_03_TXT_02',
    txt03: 'TR_MAIN_02_TAB_ITEM_03_TXT_03',
    txt04: 'TR_MAIN_02_TAB_ITEM_03_TXT_04',
  },
  {
    id: 4,
    title: '04 SINGLE PROCESSOR',
    img: '/images/img-main02-item04.png',
    link: '/product/singleProcessor',
    num: '04.',
    tit: 'TR_MAIN_02_TAB_ITEM_04_TIT',
    tit02: 'TR_MAIN_02_TAB_ITEM_04_TIT_02',
    txt01: 'TR_MAIN_02_TAB_ITEM_04_TXT_01',
    txt02: 'TR_MAIN_02_TAB_ITEM_04_TXT_02',
    txt03: 'TR_MAIN_02_TAB_ITEM_04_TXT_03',
    txt04: 'TR_MAIN_02_TAB_ITEM_04_TXT_04',
  },
];
