import styled, { keyframes } from 'styled-components';
const scroll = keyframes`
  0%{width: 0%}
  100%{
    width: 100%}
`;
export const SliderWrap = styled.div`
  height: 940px;
  position: relative;
  @media screen and (max-width: 1300px) {
    height: 100vh;
  }
  div {
    height: 100%;
  }
  .item {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    display: inline-flex !important;
    align-items: center;
    background-size: 120%;
    transition: all 4s;
    overflow: hidden;
    min-height: 940px;
    min-width: 1920px;
    &.active {
      background-size: 100%;
      p,
      span {
        transform: translateY(0);
        opacity: 1;
        transition: all 1.5s;
      }
      span {
        transition-delay: 0.8s;
      }
    }
  }
  .item1 {
    background-image: url('/images/img-slide01.png');
  }
  .item2 {
    background-image: url('/images/img-slide02.png');
  }
  .item3 {
    background-image: url('/images/img-slide03.png');
  }
  .txt {
    color: #fff;
    height: auto;
    max-width: 1440px;
    padding: 0 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    @media screen and (max-width: 1300px) {
      top: 33%;
    }
    @media screen and (max-width: 470px) {
      padding: 0 30px;
    }
    p {
      font-family: SEBANGGothic !important;
      font-weight: 600;
      font-size: 86px;
      transition: all 0s;
      transform: translateY(100%);
      transition-delay: 0.2s;
      opacity: 0;
      margin-bottom: 15px;
      line-height: 1.2;
      @media screen and (max-width: 870px) {
        font-size: 68px;
      }
      @media screen and (max-width: 470px) {
        font-size: 54px;
      }
      @media screen and (max-width: 350px) {
        font-size: 48px;
      }
    }
    span {
      font-size: 24px;
      font-weight: 500;
      transition: all 0s;
      transform: translateY(100%);
      display: inline-block;
      opacity: 0;
      display: inline-block;
      padding-bottom: 20px;
      @media screen and (max-width: 870px) {
        font-size: 20px;
        :lang(ja),
        :lang(zh),
        :lang(en) {
          word-break: break-word;
        }
      }
      @media screen and (max-width: 350px) {
        font-size: 16px;
      }
    }
  }
`;
export const ControlWrap = styled.div`
  position: absolute;
  height: auto !important;
  left: 50%;
  transform: translateX(-50%);
  top: 650px;
  max-width: 1440px;
  padding-left: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1300px) {
    top: 64%;
  }
  @media screen and (max-width: 380px) {
    top: 72%;
  }
  @media screen and (max-width: 470px) {
    max-width: 210px;
    padding-left: 0px;
    left: 30px;
    flex-wrap: wrap;
    transform: translateX(0);
  }
  .btn {
    @media screen and (max-width: 470px) {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  button {
    height: 23px;
    img {
      vertical-align: middle;
    }
    &.toggleBtn {
      width: 25px;
      box-sizing: border-box;
      margin: 0 5px;
      padding: 0;
    }
  }
  .slideIndex {
    font-size: 14px;
    color: #fff;
    font-family: SEBANGGothic !important;
    font-weight: 500;
  }
  .progress {
    height: 2px;
    width: 150px;
    margin: 0 30px;
    background-color: rgba(255, 255, 255, 0.2);
    @media screen and (max-width: 470px) {
      width: 150px;
      margin: 0px;
      margin-right: 15px;
    }
    div {
      background-color: #fff;
      height: 100%;
      transition: 50ms;
    }
  }
`;
export const ScrollWrap = styled.div`
  font-weight: 500;
  font-size: 12px;
  font-family: SEBANGGothic !important;
  position: absolute;
  right: 100px;
  bottom: 115px;
  color: #fff;
  height: auto !important;
  width: 140px;
  padding-top: 15px;
  transform: rotate(90deg);
  letter-spacing: 4px;
  @media screen and (max-width: 1300px) {
    right: 0px;
  }
  @media screen and (max-width: 470px) {
    right: -30px;
  }
  &:before {
    content: '';
    display: inline-block;
    height: 2px;
    max-width: 120px;
    animation: ${scroll} 1.5s infinite linear;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 0;
  }
  &:after {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
