import styled, { keyframes } from 'styled-components';
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

export const Main01 = styled.article`
  padding-top: 130px;
  text-align: center;
  margin-bottom: 200px;
  @media screen and (max-width: 900px) {
    padding-top: 100px;
    margin-bottom: 135px;
  }
  @media screen and (max-width: 560px) {
    padding-top: 80px;
    margin-bottom: 100px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  h2 {
    color: #222222;
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 15px;
    @media screen and (max-width: 560px) {
      font-size: 30px;
    }
  }
  p {
    color: #b0b0b0;
    font-size: 34px;
    font-weight: 500;
    font-family: 'Pretendard';
    line-height: 1;
    margin-bottom: 75px;
    @media screen and (max-width: 560px) {
      font-size: 26px;
      margin-bottom: 50px;
    }
  }
`;
export const Main02 = styled.article`
  background-color: #26334b;
  padding: 160px 0 180px;
  @media screen and (max-width: 900px) {
    padding: 100px 0 120px;
  }
  @media screen and (max-width: 560px) {
    padding: 85px 0 100px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  /*  .inner {
    position: relative;
    div {
      position: absolute;
      top: 49%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-right: 30px;
      text-align: center;
      h2 {
        color: #222222;
        font-size: 34px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 10px;
      }
      p {
        color: #b0b0b0;
        font-size: 30px;
        font-family: 'Pretendard';
        font-weight: 500;
        line-height: 1;
      }
    }
  }*/
`;
export const ImgWrap = styled.div`
  text-align: center;
  img {
    max-width: 100%;
    &:nth-of-type(1) {
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    &:nth-of-type(2) {
      display: none;
      @media screen and (max-width: 900px) {
        display: inline;
      }
      @media screen and (max-width: 560px) {
        display: none;
      }
    }
    &:nth-of-type(3) {
      display: none;
      @media screen and (max-width: 560px) {
        display: inline;
      }
    }
  }
`;
