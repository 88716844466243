import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { ControlWrap, ScrollWrap, SliderWrap } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sliderData } from './sliderData';
import { useTranslation } from 'react-i18next';

const Slick = () => {
  const [slidePlay, setSlidePlay] = useState<boolean>(true);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [progressWidth, setProgressWidth] = useState<number>(0);

  const progressRef = useRef<any>(null);
  const sliderRef = useRef<any>(null);

  const { t, i18n } = useTranslation();
  let interval: any = undefined;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    fade: true,
    swipe: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    draggable: false,
    touchMove: false,
    beforeChange(currentSlide: number, nextSlide: number) {
      setCurrentSlide(nextSlide + 1);
    },
  };

  const onNext = useCallback(() => {
    sliderRef.current.slickNext();
    setProgressWidth(0);
  }, []);

  const onPrev = useCallback(() => {
    sliderRef.current.slickPrev();
    setProgressWidth(0);
  }, []);

  const onToggle = useCallback(() => {
    if (slidePlay) {
      sliderRef.current.slickPause();
      setSlidePlay(false);
    } else {
      sliderRef.current.slickPlay();
      setSlidePlay(true);
    }
  }, [slidePlay]);

  useEffect(() => {
    if (currentSlide === 0) {
      setCurrentSlide(1);
    }
    sliderRef.current.slickPause();
    if (slidePlay) {
      interval = setInterval(() => {
        setProgressWidth((prev) => prev + 1);
        if (progressWidth === 100) {
          setProgressWidth(0);
          sliderRef.current.slickNext();
        }
      }, 50);
    } else {
      clearInterval(interval);
    }
    progressRef.current.style.width = progressWidth + '%';
    return () => clearInterval(interval);
  }, [slidePlay, progressWidth]);

  return (
    <SliderWrap>
      <Slider {...settings} ref={sliderRef}>
        {sliderData.map((item) => (
          <div key={item.id} className={`item item${item.id} ` + (item.id === currentSlide ? 'active' : '')}>
            <div className="txt">
              <p>{t(item.title)}</p>
              <span>{t(item.sub)}</span>
            </div>
          </div>
        ))}
      </Slider>
      <ControlWrap>
        <div className="btn">
          <button type="button" onClick={onPrev}>
            <img src="/images/icon/ico-prevArrow.png" alt="이전슬라이드 이동" />
          </button>
          <button type="button" onClick={onToggle} className="toggleBtn">
            {slidePlay ? (
              <img src="/images/icon/ico-stop.png" alt="슬라이드 자동재생 중지" />
            ) : (
              <img src="/images/icon/ico-play.png" alt="슬라이드 자동재생 시작" />
            )}
          </button>
          <button type="button" onClick={onNext}>
            <img src="/images/icon/ico-nextArrow.png" alt="다음슬라이드 이동" />
          </button>
        </div>
        <div className="progress">
          <div ref={progressRef}></div>
        </div>
        <span className="slideIndex">
          {currentSlide} / {sliderData.length}
        </span>
      </ControlWrap>
      <ScrollWrap>SCROLL</ScrollWrap>
    </SliderWrap>
  );
};

export default Slick;
