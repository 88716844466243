import styled, { keyframes } from 'styled-components';
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;
const lineAni = keyframes`
  0%{width: 0%}
  100%{
    width: 100%}
`;
const rotateAni = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;
export const Main01 = styled.article`
  text-align: center;
  padding: 140px 0 150px;
  @media screen and (max-width: 900px) {
    padding: 75px 0 100px;
  }
  @media screen and (max-width: 620px) {
    padding: 63px 0 80px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  h2 {
    margin-bottom: 45px;
    letter-spacing: -1.5px;
    padding: 0 30px;
    @media screen and (max-width: 900px) {
      margin-bottom: 35px;
    }
    span {
      color: #2a2929;
      font-size: 32px;
      font-weight: 600;
      display: block;
      :lang(ja),
      :lang(zh) {
        word-break: break-word;
        @media screen and (max-width: 780px) {
          display: inline;
        }
      }
      @media screen and (max-width: 900px) {
        font-size: 28px;
      }
      @media screen and (max-width: 620px) {
        display: inline;
        font-size: 24px;
      }
    }
  }
  div {
    padding: 0 30px;
    p {
      margin-bottom: 20px;
    }
    span {
      display: block;
      color: #2a2929;
      font-weight: 400;
      font-size: 20px;
      :lang(ja),
      :lang(zh) {
        word-break: break-word;
        @media screen and (max-width: 780px) {
          display: inline;
        }
      }
      @media screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media screen and (max-width: 620px) {
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        display: inline;
      }
    }
  }
`;
export const Main02 = styled.article`
  background-color: #26334b;
  padding: 195px 0 150px;
  @media screen and (max-width: 900px) {
    padding: 90px 0 180px;
  }
  @media screen and (max-width: 600px) {
    padding: 75px 0 130px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
    .content {
      &:before {
        animation: ${lineAni} 1.5s linear;
        animation-fill-mode: forwards;
        transition-delay: 0.5s;
      }
    }
    .item {
      &:nth-of-type(1) {
        opacity: 1;
        transition-delay: 0.5s;
      }
      &:nth-of-type(2) {
        opacity: 1;
        transition-delay: 0.9s;
      }
      &:nth-of-type(3) {
        opacity: 1;
        transition-delay: 1.3s;
      }
      &:nth-of-type(4) {
        opacity: 1;
        transition-delay: 1.6s;
      }
    }
  }
  opacity: 0;
  transform: translateY(100px);
  .tit {
    margin-bottom: 125px;
    @media screen and (max-width: 900px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 60px;
    }
    h2 {
      color: #fff;
    }
    p {
      :lang(en) {
        width: 50%;
        span {
          display: inline;
        }
        @media screen and (max-width: 1420px) {
          width: 55%;
        }
        @media screen and (max-width: 1180px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 1480px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 560px) {
        margin-left: 10px;
      }
      span {
        color: #dbe2ef;
        @media screen and (max-width: 600px) {
          display: inline;
        }
      }
    }
  }
  .content {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 2px;
      background-color: #a0cdf9;
      position: absolute;
      transition: 1.2s;
      transition-delay: 0.2s;
      @media screen and (max-width: 1180px) {
        display: none;
      }
    }
  }
  .inner {
    display: flex;
    align-items: start;
    justify-content: start;
    @media screen and (max-width: 1180px) {
      display: block;
    }
    .item {
      width: 22%;
      opacity: 0;
      transition: 0.5s;
      :lang(en) {
        padding-right: 20px;
        @media screen and (max-width: 1480px) {
          width: calc(45% - 325px);
          margin-right: 1%;
        }
        @media screen and (max-width: 1300px) {
          width: calc(49% - 325px);
          margin-right: 1%;
        }
        @media screen and (max-width: 1180px) {
          width: 500px;
          margin: 0 auto;
          border-top: 2px solid #a0cdf9;
          position: relative;
          margin-bottom: 90px;
          padding-right: 0;
          &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: #a0cdf9;
            position: absolute;
            border-radius: 50%;
            top: -2px;
            right: 0;
            transform: translateY(-50%);
          }
        }
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        &:nth-of-type(3) {
          @media screen and (max-width: 1480px) {
            margin-right: 0px;
          }
          @media screen and (max-width: 1180px) {
            margin: 0 auto;
          }
        }
      }
      @media screen and (max-width: 1480px) {
        width: auto;
        margin-right: 40px;
      }
      @media screen and (max-width: 1180px) {
        width: 500px;
        margin: 0 auto;
        border-top: 2px solid #a0cdf9;
        position: relative;
        margin-bottom: 90px;
        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          background-color: #a0cdf9;
          position: absolute;
          border-radius: 50%;
          top: -2px;
          right: 0;
          transform: translateY(-50%);
        }
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      &:nth-of-type(3) {
        @media screen and (max-width: 1480px) {
          margin-right: 0px;
        }
        @media screen and (max-width: 1180px) {
          margin: 0 auto;
        }
      }

      &:last-of-type {
        width: 370px;
        height: 370px;
        border: 1px solid #97c1eb;
        border-radius: 50%;
        background-color: #26334b;
        transform: translateY(-50%);
        margin-left: 100px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        :lang(en) {
          padding-right: 0;
        }
        &:before {
          display: none;
        }
        @media screen and (max-width: 1480px) {
          margin-right: 0px;
          width: 325px;
          height: 325px;
          margin-left: 70px;
        }
        @media screen and (max-width: 1230px) {
          margin-left: 50px;
        }
        @media screen and (max-width: 1180px) {
          margin: 0 auto;
          transform: translateY(0);
          margin-top: 160px;
          p span {
            display: block;
          }
        }
        @media screen and (max-width: 600px) {
          width: 250px;
          height: 250px;
          margin-top: 115px;
        }
        h3 {
          font-size: 40px;
          margin-bottom: 10px;
          padding-top: 0;
          @media screen and (max-width: 600px) {
            font-size: 30px;
          }
          span {
            font-size: 40px;
            @media screen and (max-width: 600px) {
              font-size: 30px;
            }
          }
          .normal {
            color: #fff;
            font-weight: 500;
          }
          &:before,
          &:after {
            display: none;
          }
        }
        p {
          span {
            color: #ffffff;
            font-size: 20px;
            font-weight: 400;
            @media screen and (max-width: 600px) {
              font-size: 16px;
            }
          }
        }
        &:after {
          display: inline-block;
          content: '';
          width: 540px;
          height: 540px;
          background-color: transparent;
          background-image: url('/images/img-circle.png');
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: -85px;
          left: -85px;
          animation: ${rotateAni} 25s linear infinite;
          transform-origin: 50% 50%;
          @media screen and (max-width: 1480px) {
            width: 480px;
            height: 480px;
            background-size: 480px;
            top: -75px;
            left: -75px;
          }
          @media screen and (max-width: 600px) {
            width: 340px;
            height: 340px;
            background-size: 340px;
            top: -45px;
            left: -45px;
          }
        }
      }
      h3 {
        font-size: 28px;
        font-family: SEBANGGothic !important;
        font-weight: 600;
        color: #fff;
        padding-top: 40px;
        position: relative;
        @media screen and (max-width: 600px) {
          font-size: 24px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid #a0cdf9;
          background-color: #26334b;
          top: 0;
          left: 0;
          position: absolute;
          transform: translateY(-50%);
        }
        &:after {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          background-color: #a0cdf9;
          position: absolute;
          border-radius: 50%;
          top: 0;
          left: 6px;
          transform: translateY(-50%);
        }
        span {
          color: #9fccf7;
          font-size: 34px;
          font-family: SEBANGGothic !important;
          font-weight: 600;
          @media screen and (max-width: 600px) {
            font-size: 30px;
          }
        }
      }
      p {
        & > span {
          color: #dbe2ef;
          font-weight: 400;
          font-size: 18px;
          display: block;
          :lang(ja),
          :lang(zh) {
            word-break: break-word;
          }
          @media screen and (max-width: 1180px) {
            display: inline;
          }
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
          span {
            color: #ffffff;
            font-width: 600;
          }
        }
      }
    }
  }
`;
export const Main03 = styled.article`
  background-color: #f5f6f8;
  padding: 140px 0 150px;
  @media screen and (max-width: 800px) {
    padding: 90px 0 100px;
  }
  @media screen and (max-width: 600px) {
    padding: 90px 0 75px;
  }
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  .tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :lang(en) {
      @media screen and (max-width: 850px) {
        display: block;
      }
    }
    @media screen and (max-width: 770px) {
      display: block;
    }
    h2 {
      margin-bottom: 0;
      :lang(en) {
        @media screen and (max-width: 850px) {
          margin-bottom: 20px;
        }
      }
      @media screen and (max-width: 770px) {
        margin-bottom: 20px;
      }
    }
    a {
      width: 250px;
      height: 60px;
      color: #2d343d;
      font-weight: 500;
      font-size: 20px;
      color: #2d343d;
      display: inline-flex;
      align-items: center;
      justify-content: start;
      padding-left: 40px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 5px 10px rgba(153, 153, 153, 0.05);
      background-image: url('/images/icon/ico-employment-more-g.png');
      background-repeat: no-repeat;
      background-position: 200px center;
      transition: 0.3s;
      @media screen and (max-width: 800px) {
        font-size: 18px;
      }
      &:first-of-type {
        margin-right: 10px;
        padding-left: 30px;
        @media screen and (max-width: 580px) {
          margin-bottom: 10px;
        }
      }
      &:hover {
        background-color: #26334b;
        color: #fff;
        background-image: url('/images/icon/ico-employment-more-w.png');
      }
    }
  }
  ul {
    display: grid;
    margin-top: 40px;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 1100px) {
      display: block;
    }
    :lang(en) {
      @media screen and (max-width: 1270px) {
        display: block;
      }
    }
    li {
      background-color: #fff;
      height: 240px;
      box-shadow: 0 0 15px 12px rgba(153, 153, 153, 0.05);
      display: flex;
      align-items: start;
      justify-content: start;
      padding-top: 30px;
      padding-left: 50px;
      padding-right: 20px;
      @media screen and (max-width: 1100px) {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 800px) {
        height: 220px;
      }
      :lang(en) {
        @media screen and (max-width: 1270px) {
          height: auto;
          margin-bottom: 25px;
          padding-bottom: 50px;
        }
      }
      @media screen and (max-width: 600px) {
        height: auto;
        padding-bottom: 50px;
        padding-left: 20px;
      }
      div {
        flex-grow: 1;
      }

      &:nth-of-type(1) {
        h4 {
          background-image: url('/images/icon/ico-employment-item01.png');
        }
      }
      &:nth-of-type(2) {
        h4 {
          background-image: url('/images/icon/ico-employment-item02.png');
        }
      }
      &:nth-of-type(3) {
        h4 {
          background-image: url('/images/icon/ico-employment-item03.png');
        }
      }
      &:nth-of-type(4) {
        h4 {
          background-image: url('/images/icon/ico-employment-item04.png');
        }
      }
      &:nth-of-type(5) {
        height: 320px;
        :lang(ja) {
          @media screen and (max-width: 1360px) {
            height: 400px;
          }
          @media screen and (max-width: 1100px) {
            height: auto;
            padding-bottom: 40px;
          }
        }
        :lang(zh) {
          @media screen and (max-width: 1150px) {
            height: 380px;
          }
          @media screen and (max-width: 1100px) {
            height: 240px;
          }
        }
        :lang(en) {
          height: 440px;
          @media screen and (max-width: 1330px) {
            height: 500px;
          }
          @media screen and (max-width: 1270px) {
            height: auto;
            padding-bottom: 50px;
          }
        }
        @media screen and (max-width: 1100px) {
          height: 240px;
        }
        @media screen and (max-width: 800px) {
          height: 220px;
        }
        @media screen and (max-width: 600px) {
          height: auto;
          padding-bottom: 50px;
        }
        h4 {
          background-image: url('/images/icon/ico-employment-item05.png');
        }
      }
      &:nth-of-type(6) {
        height: 320px;
        :lang(ja) {
          @media screen and (max-width: 1360px) {
            height: 400px;
          }
          @media screen and (max-width: 1100px) {
            height: auto;
            padding-bottom: 40px;
          }
        }
        :lang(zh) {
          @media screen and (max-width: 1150px) {
            height: 380px;
          }
          @media screen and (max-width: 1100px) {
            height: auto;
            padding-bottom: 40px;
          }
        }
        :lang(en) {
          height: 440px;
          @media screen and (max-width: 1330px) {
            height: 500px;
          }
          @media screen and (max-width: 1270px) {
            height: auto;
            padding-bottom: 50px;
          }
        }
        @media screen and (max-width: 800px) {
          height: 290px;
        }
        @media screen and (max-width: 600px) {
          height: auto;
          padding-bottom: 50px;
        }
        h4 {
          background-image: url('/images/icon/ico-employment-item06.png');
          :lang(en) {
            @media screen and (max-width: 440px) {
              padding-right: 100px;
            }
          }
        }
        p:first-of-type {
          margin-bottom: 15px;
        }
      }
      h4 {
        color: #222222;
        font-size: 28px;
        font-weight: 600;
        padding: 25px 0 43px;
        background-repeat: no-repeat;
        background-position: right center;
        margin-right: 10px;
        line-height: 1;
        :lang(en) {
          @media screen and (max-width: 1100px) {
            word-break: break-word;
            line-height: 1.4;
            padding-right: 70px;
          }
          @media screen and (max-width: 440px) {
            font-size: 20px;
          }
        }
        @media screen and (max-width: 800px) {
          font-size: 26px;
        }
        @media screen and (max-width: 600px) {
          font-size: 24px;
          padding: 25px 0 30px;
          background-size: 75px;
          margin-right: 0;
        }
      }
      p {
        transform: translateY(-8px);
        padding-right: 10px;
        :lang(en) {
          width: 87%;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
        span {
          display: block;
          color: #444444;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: -0.6px;
          :lang(ja),
          :lang(zh),
          :lang(en) {
            font-weight: 400;
          }
          :lang(en) {
            font-size: 19px;
            @media screen and (max-width: 800px) {
              font-size: 18px;
            }
            @media screen and (max-width: 600px) {
              font-size: 16px;
            }
          }
          :lang(ja),
          :lang(zh) {
            word-break: break-word;
          }
          @media screen and (max-width: 800px) {
            font-size: 18px;
          }
          @media screen and (max-width: 600px) {
            font-size: 16px;
            margin-top: 10px;
          }
          &.fc-b {
            color: #506fa7;
          }
        }
      }
    }
  }
`;
export const Main04 = styled.article`
  background-color: #26334b;
  background-image: url('/images/bg/bg-Main04employment.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 130px 0;
  @media screen and (max-width: 800px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 800px) {
    padding: 90px 0 80px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    @media screen and (max-width: 1180px) {
      display: block;
    }
    @media screen and (max-width: 500px) {
      padding: 0 30px;
    }
  }
  h2 {
    color: #ffffff;
    @media screen and (max-width: 1180px) {
      margin-bottom: 15px;
    }
  }
  .tit p {
    margin-left: 0px;
    :lang(en) {
      max-width: 100%;
    }
    @media screen and (max-width: 1180px) {
      margin-bottom: 50px;
      margin-left: 20px;
    }
    @media screen and (max-width: 630px) {
      margin-bottom: 40px;
    }
    @media screen and (max-width: 560px) {
      margin-left: 10px;
    }
    span {
      color: #fff;
      @media screen and (max-width: 1480px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 1180px) {
        display: inline;
        margin-left: 0;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    :lang(en),
    :lang(ja),
    :lang(zh) {
      flex-wrap: nowrap;
      margin-left: 20px;
      @media screen and (max-width: 1180px) {
        flex-wrap: wrap;
      }
    }
    li {
      min-width: 300px;
      padding: 90px 0 10px 35px;
      background-repeat: no-repeat;
      background-position: 35px top;
      border-left: 1px solid #959ba8;
      :lang(en),
      :lang(ja),
      :lang(zh) {
        @media screen and (max-width: 1430px) {
          min-width: 240px;
        }
        @media screen and (max-width: 1180px) {
          width: 50%;
          background-position: 30px center;
          padding: 15px 0 15px 140px;
        }
        @media screen and (max-width: 780px) {
          min-width: 100%;
        }
        &:nth-of-type(2) {
          @media screen and (max-width: 780px) {
            margin-top: 60px;
          }
        }
        @media screen and (max-width: 380px) {
          padding: 15px 0 15px 100px;
          background-position: 20px center;
          background-size: 60px;
        }
      }
      @media screen and (max-width: 1330px) {
        min-width: 240px;
      }
      @media screen and (max-width: 1180px) {
        min-width: 50%;
        background-position: 30px center;
        padding: 15px 0 15px 140px;
      }
      @media screen and (max-width: 630px) {
        min-width: 100%;
      }
      @media screen and (max-width: 380px) {
        padding: 15px 0 15px 100px;
        background-position: 20px center;
        background-size: 60px;
      }
      &:nth-of-type(1) {
        background-image: url('/images/icon/ico-employment-main04-01.png');
      }
      &:nth-of-type(2) {
        background-image: url('/images/icon/ico-employment-main04-02.png');
        @media screen and (max-width: 630px) {
          margin-top: 60px;
        }
      }
      &:nth-of-type(3) {
        background-image: url('/images/icon/ico-employment-main04-03.png');
        @media screen and (max-width: 1180px) {
          margin-top: 60px;
        }
        span {
          @media screen and (max-width: 500px) {
            width: 100%;
            word-break: break-word;
          }
          @media screen and (max-width: 630px) {
            font-size: 18px;
          }
        }
      }
      p {
        color: #8798b7;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 10px;
        @media screen and (max-width: 630px) {
          font-size: 18px;
        }
      }
      span {
        color: #ffffff;
        font-weight: 400;
        font-size: 22px;
        :lang(en),
        :lang(ja),
        :lang(zh) {
          font-size: 20px;
          display: inline-block;
          width: 80%;
          @media screen and (max-width: 630px) {
            font-size: 18px;
          }
        }
        @media screen and (max-width: 630px) {
          font-size: 18px;
        }
      }
    }
  }
`;
