import styled, { createGlobalStyle, keyframes } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  :lang(ko) {
    font-family: "NotoSansKr";
  }
  :lang(en) {
    font-family: "NotoSansKr";
  }
  :lang(ja) {
    font-family: "NotoSansJP";
  }
  :lang(zh) {
    font-family: "NotoSansCn";
  }
  * {
    box-sizing: border-box;
    word-break: keep-all;
  }

  html, body {
    font-size: 14px;
    overflow-x: hidden;
  }
  body {
    font-size: 15px;
    color: #333;
    line-height: 1.6;

    background-color: #fff;
    &::-webkit-scrollbar{
      /* 스크롤바 막대 너비 설정 */ width: 6px;
    }
    /* 스크롤바 막대 설정*/
    &::-webkit-scrollbar-thumb{
      /* 스크롤바 막대 높이 설정 */
      height: 17%;
      background-color: #888;
      /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
    } /* 스크롤바 뒷 배경 설정*/
    &::-webkit-scrollbar-track{
      background-color: rgba(0,0,0,0);
    }
  }

  a {
    color: #333;
    text-decoration: none;
  }

  input, select {
    border: 1px solid #d9d9d9;
    padding: 0 5px;
  }

  table {
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    word-break: keep-all;
  }

  input[type="file"] {
    padding: 2px 0;
  }

  button {
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }
  #root:before{
    display: none;
  }
`;

export default GlobalStyle;

const scroll = keyframes`
 0%{
   transform: translateY(-20px)
 }
  50%{
    transform: translateY(0px)
  }
  100%{
    transform: translateY(-20px)
  }
`;

export const MaxWidth = styled.div`
  max-width: 1480px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 560px) {
    padding: 0 30px;
  }
`;
export const MaxWidth2 = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  margin: 0 auto;
  @media screen and (max-width: 560px) {
    padding: 0 30px;
  }
`;
export const MainWrap = styled.section`
  margin-top: 90px;
  position: relative;
  @media screen and (max-width: 1300px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 70px;
  }
`;

export const BannerTop = styled.div`
  padding: 110px 0 90px;
  @media screen and (max-width: 870px) {
    padding: 100px 0 60px;
  }
  @media screen and (max-width: 560px) {
    padding: 70px 0px 60px;
  }
  &.about {
    span {
      @media screen and (max-width: 340px) {
        display: inline;
      }
    }
  }
  h1 {
    line-height: 1.5;
    span {
      color: #222;
      font-size: 36px;
      font-weight: 600;
      display: block;
      :lang(ja) {
        word-break: break-word;
      }
      :lang(zh) {
        word-break: break-word;
      }
      @media screen and (max-width: 870px) {
        font-size: 30px;
      }
      @media screen and (max-width: 560px) {
        font-size: 26px;
      }
    }
  }
`;
export const TitTab = styled.div`
  margin-bottom: 30px;
  &.management {
    :lang(en) {
      display: flex;
      button {
        word-break: keep-all;
      }
    }
  }
  button {
    color: #989898;
    font-size: 18px;
    padding: 7px 10px;
    margin: 0 20px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    transition: 0.2s;
    @media screen and (max-width: 870px) {
      font-size: 16px;
      margin: 0 15px;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &.active,
    &:hover {
      color: #020202;
      border-color: #020202;
    }
  }
  &.cnt7 button {
    :lang(en) {
      @media screen and (max-width: 1230px) {
        margin: 0px;
        width: 25%;
        margin-top: 10px;
      }
      @media screen and (max-width: 490px) {
        width: 33.3%;
        vertical-align: bottom;
      }
      @media screen and (max-width: 380px) {
        width: 50%;
      }
    }
    @media screen and (max-width: 760px) {
      margin: 0px;
      width: 25%;
      margin-top: 10px;
    }
    @media screen and (max-width: 390px) {
      width: 33.3%;
    }
  }
  &.employment {
    :lang(en) {
      width: 100%;
      button {
        vertical-align: bottom;
      }
      @media screen and (max-width: 730px) {
        button {
          width: 50%;
          margin: 0;
          margin-top: 10px;
        }
      }
    }
    @media screen and (max-width: 500px) {
      button {
        width: 50%;
        margin: 0;
        margin-top: 10px;
      }
    }
  }

  &.cnt4 {
    .dis-b-m {
      display: none;
    }
    @media screen and (max-width: 760px) {
      .dis-b-m {
        display: initial;
      }
      button {
        margin-top: 10px;
        &:nth-of-type(3) {
          margin-left: 0;
        }
      }
    }
    @media screen and (max-width: 760px) {
      .dis-b-m {
        display: none;
      }
      button {
        width: 50%;
        margin: 0;
        vertical-align: bottom;
        &:nth-of-type(3),
        &:nth-of-type(4) {
          margin-top: 10px;
        }
      }
    }
  }
  &.cnt3 {
    :lang(en) {
      @media screen and (max-width: 380px) {
        flex-wrap: wrap;
        button {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 50%;
        }
      }
    }
    :lang(zh),
    :lang(ja) {
      button {
        word-break: break-word;
      }
      @media screen and (max-width: 410px) {
        flex-wrap: wrap;
        button {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 50%;
        }
      }
    }
    @media screen and (max-width: 600px) {
      display: flex;
      justify-content: space-between;
      button:last-of-type {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 340px) {
      button {
        &:first-of-type {
          margin-right: 10px;
        }
        &:nth-of-type(2) {
          margin: 0 10px;
        }
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
  &.news {
    :lang(en) {
      @media screen and (max-width: 420px) {
        button {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 50%;
        }
      }
    }
    :lang(ja) {
      @media screen and (max-width: 390px) {
        button {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 50%;
        }
      }
    }
    :lang(zh) {
      @media screen and (max-width: 340px) {
        button {
          margin: 0 !important;
          margin-top: 10px !important;
          width: 50%;
        }
      }
    }
    @media screen and (max-width: 330px) {
      button {
        &:first-of-type {
          margin-right: 10px;
        }
        &:nth-of-type(2) {
          margin: 0 10px;
        }
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
`;

export const BannerImg = styled.div`
  height: 480px;
  width: 100%;
  background-image: url('/images/bg/bg-about.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media screen and (max-width: 900px) {
    height: 320px;
  }
  @media screen and (max-width: 560px) {
    height: 240px;
  }
  &:before {
    content: '';
    display: inline-block;
    background-image: url('/images/icon/ico-scroll.png');
    background-repeat: no-repeat;
    width: 31px;
    height: 40px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transition: 0.3s;
    transform: translateX(-50%);
    animation: ${scroll} 1.5s infinite linear;
    @media screen and (max-width: 560px) {
      bottom: 30px;
    }
  }
  &.welfare {
    background-image: url('/images/bg/bg-welfare.png');
  }
  &.support {
    background-image: url('/images/bg/bg-support.png');
  }
  &.news {
    background-image: url('/images/bg/bg-news.png');
    @media screen and (max-width: 1300px) {
      background-position: -290px;
    }
    @media screen and (max-width: 990px) {
      background-position: -390px;
    }
    @media screen and (max-width: 560px) {
      background-position: -320px;
    }
  }
  &.management {
    background-image: url('/images/bg/bg-management.png');
    @media screen and (max-width: 900px) {
      background-position: -290px;
    }
    @media screen and (max-width: 470px) {
      background-position: -350px;
    }
    @media screen and (max-width: 400px) {
      background-position: -400px;
    }
    @media screen and (max-width: 350px) {
      background-position: -450px;
    }
  }
  &.product {
    background-image: url('/images/bg/bg-product.png');
  }
  &.employment {
    background-image: url('/images/bg/bg-employment.png');
    background-position: center;
  }
`;
export const TopBtn = styled.button`
  position: fixed;
  bottom: 60px;
  right: 100px;
  text-align: center;
  color: #26334b;
  font-weight: 500;
  font-size: 14px;
  background-image: url('/images/icon/ico-arrowTop-b.png');
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 30px;
  transition: 0.2s;
  z-index: 9998;
  @media screen and (max-width: 1500px) {
    right: 40px;
  }
  @media screen and (max-width: 500px) {
    right: 15px;
  }
  &.active {
    color: #fff;
    background-image: url('/images/icon/ico-arrowTop-w.png');
  }
`;
export const SubTit = styled.div`
  h2 {
    color: #222;
    font-size: 40px;
    font-weight: 600;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    @media screen and (max-width: 1480px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 880px) {
      font-size: 36px;
    }
    @media screen and (max-width: 560px) {
      font-size: 32px;
      margin-left: 10px;
      margin-bottom: 25px;
    }
    &.fs36 {
      font-size: 36px;
      @media screen and (max-width: 880px) {
        font-size: 34px;
      }
      @media screen and (max-width: 560px) {
        font-size: 30px;
        margin-left: 10px;
        margin-bottom: 25px;
      }
    }
    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: #f81325;
      position: absolute;
      top: 5px;
      left: -20px;
      @media screen and (max-width: 880px) {
        top: 0;
      }
      @media screen and (max-width: 560px) {
        width: 11px;
        height: 11px;
        left: -13px;
        top: 3px;
      }
    }
  }
  p {
    &.fc-b {
      span {
        color: #2a2929;
      }
    }
    span {
      font-size: 20px;
      font-weight: 400;
      color: #787878;
      display: block;
      :lang(ja),
      :lang(zh) {
        word-break: break-word;
      }
      @media screen and (max-width: 1300px) {
        font-size: 18px;
      }
      @media screen and (max-width: 560px) {
        font-size: 16px;
      }
    }
  }
`;
