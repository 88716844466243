import React, { useEffect } from 'react';
import { PopupWrap } from './style';

const TermPersonal = () => {
  useEffect(() => {
    document.querySelector<any>('header').style.display = 'none';
    document.querySelector<any>('footer').style.display = 'none';
  }, []);
  return (
    <PopupWrap>
      <h1>개인정보취급방침</h1>
      <p>
        (주)유닉(이하”회사”라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), 개인정보보호법 및 통신비밀보호법 등 관련 법령상의
        개인정보보호 규정을 준수합니다. 회사는 이를 위하여 개인정보보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
        처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침(이하 “본 처리방침”이라 함)을 수립•공개합니다.
      </p>
      <p>개인정보취급방침 시행일자 : 2022년 11월 01일</p>
      <p>
        <h2>제1조 (개인정보의 처리목적)</h2>
        <ul>
          <li>
            1. 재화 또는 서비스의 제공 <p>• 물품배송, 서비스의 제공, 계약서 또는 청구서의 발송, 채권 추심 등의 목적으로 처리합니다.</p>
          </li>
          <li>
            2. 마케팅 및 판촉 활동 <p>• 이메일 등의 발송을 통해 회사 제품에 대한 정보 제공 등 기타 판촉 행사 등의 목적으로 개인정보를 처리합니다.</p>
          </li>
          <li>
            3. 직원의 채용 <p>• 회사의 정기 또는 수시 임직원의 채용 시 지원자에 대한 신원 확인, 연락•통지, 결과의 통보를 목적으로 개인정보를 처리합니다.</p>
          </li>
          <li>
            4. 임직원의 관리
            <p>
              • 회사의 대•내외적 업무상의 필요에 의하여 사용되는 경우로서 그 사용이 공서양속 내지 관련 법률 등에 위반되지 아니한 경우에 한하여 개인정보를
              처리합니다.
            </p>
          </li>
          <li>
            5. 회사의 원활한 업무 수행
            <p>
              • 회사와의 업무 협의를 위한 내방객, 방문자 및 업무와 관련한 거래 당사자의 담당자 정보, 계약의 체결, 협의, 수행 담당자 등 회사의 업무 수행과
              관련하여 원활한 업무 수행을 목적으로 거래 상대방(개인, 개인사업자, 법인사업자 포함) 임직원의 개인정보를 처리합니다.
            </p>
          </li>
        </ul>
      </p>

      <p>
        <h2>제2조 (개인정보의 처리 및 보유 기간)</h2>
        <ul>
          <li>
            • 회사는 법령에 따른 개인정보 보유, 이용 기간 또는 정보주체로부터 개인정보를 수집 시에는 동의 받은 개인정보 보유, 이용 기간 또는 수집, 이용에 대한
            동의일로부터 개인정보의 처리 목적을 달성하는 시점까지 최소 3년간 보유, 이용합니다. 다만, 보유 기간이 경과한 이후에는 분쟁 해결, 민원 처리 및 법령상
            의무 이행의 목적을 위하여만 보유 및 이용하며 이용목적을 달성한 후에는 지체없이 파기합니다.
          </li>
          <li>
            • 각각의 개인정보는 원칙적으로 제품 및 서비스의 공급 완료 및 그에 따른 채권•채무의 정산 완료 시점까지 처리, 보유하게 됩니다. 다만, 관계 법령 위반에
            따른 수사, 조사 등이 진행 중인 경우에는 해당 수사, 조사가 종료되는 시점까지 처리, 보유하며, 그 구체적인 기간은 회사의 개인정보보호 규정에서 정한
            바에 따릅니다.
          </li>
        </ul>
      </p>

      <p>
        <h2>제3조 (개인정보의 제3자 제공에 관한 사항)</h2>
        <ul>
          <li>
            • 회사는 원칙적으로 정보주체의 개인정보를 본 처리방침 제1조에서 명시한 목적범위 내에서만 처리하며, 정보주체로부터 사전 동의를 받거나 법률의 특별한
            규정에 의한 경우를 제외하고는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
          </li>
          <li>• 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</li>
          <li>1. 해당 정보주체의 동의가 있는 경우</li>
          <li>2. 제1조 소정의 목적을 실현하기 위하여 회사가 제3자에게 그 필요한 범위 내에서 제공하는 경우</li>
          <li>3. 정보주체 개인을 특정하지 아니한 상태에서 가공한 통계적인 데이터로 이용하는 경우</li>
          <li>4. 법령 또는 법원의 유효한 명령 등에 의해 공개를 요청받은 경우</li>
          <li>5. 사람의 생명, 신체 또는 재산 등의 중대한 이익을 보호하기 위하여 긴급히 필요한 경우</li>
        </ul>
      </p>

      <p>
        <h2>제4조 (처리하는 개인정보의 항목)</h2>
        <span>회사는 다음의 개인정보 항목을 처리하고 있습니다.</span>
        <ul>
          <li>
            1. 재화 또는 서비스의 제공
            <p>• 성명, 회사명, 아이디, 비밀번호, 주소, 전화번호, 핸드폰번호, 이메일 주소, 은행계좌정보 등 결제정보, 사업자등록번호</p>
          </li>
          <li>
            2. 마케팅 및 판촉 활동 <p>• 대상자의 성명, 회사명, 직위, 이메일 주소, 회사 전화번호, 휴대폰 번호</p>
          </li>
          <li>
            3. 직원의 채용
            <p>
              • 이름, 주민등록번호, 주소, 전화번호, 휴대전화번호, 이메일, 학력, 병역정보, 보훈정보, 장애여부, 건강상태, 외국어 능력, 기타 자기소개서 등 기재
              사항(가족관계, 자격증, 경력사항, 컴퓨터능력 등)
            </p>
          </li>
          <li>
            4. 기타생성정보
            <p>• 접속 IP, 서비스 이용 기록, 접속 로그, 불량 이용 기록 등</p>
          </li>
          <li>
            5. 임직원의 관리
            <p>• 채용 시 제공된 개인정보 및 그에 대한 변경 사항, 부서, 직위, 인사 고과 등</p>
          </li>
          <li>
            6. 회사의 원활한 업무 수행
            <p>• 회사명, 소속, 직위, 이름, 연락처, 주소, 핸드폰번호, 이메일 주소, 생년월일 등</p>
          </li>
        </ul>
      </p>

      <p>
        <h2>제5조 (개인정보 처리의 위탁에 관한 사항)</h2>
        <ul>
          <li>
            • 회사는 정보주체의 사전 동의 없이 정보주체의 개인정보에 관한 취급을 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우에는 위탁 대상자와
            위탁 업무 내용에 대해서 해당 정보주체에게 통지하고 필요한 경우 사전 동의를 받을 것입니다.
          </li>
          <li>
            • 회사가 개인정보의 처리에 관하여 제3자와 위탁계약을 체결하는 경우에는 개인정보보호법 제25조에 따라 위탁업무 수행 목적 이외 개인정보 처리금지,
            기술적•관리적 보호조치, 재위탁의 제한, 수탁자에 대한 관리•감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 안전하게
            처리하는지를 감독하겠습니다.
          </li>
          <li>
            • 개인정보에 대한 처리 위탁을 하게 되거나 위탁 업무의 내용, 수탁자가 변경될 경우에는 지체 없이 본 처리방침을 통하여 공개하고, 필요한 동의를
            획득하겠습니다.
          </li>
        </ul>
      </p>

      <p>
        <h2>제6조 (개인정보의 안전성 확보조치에 관한 사항)</h2>
        <ul>
          <li>
            • 회사는 정보주체의 개인정보를 적절하고 신중하게 관리하겠습니다. 그에 따라 회사는 다음과 같이 안정성 확보에 필요한 기술적•관리적•물리적 보호조치를
            취하고 있습니다.
          </li>
          <li>1. 관리적 조치: 개인정보보호규정의 수립 및 시행, 개인정보취급의 최소화 및 교육</li>
          <li>
            2. 기술적 조치: 개인정보처리시스템 등에 대한 접근권한 관리, 접근통제시스템 설치, 고유식별정보의 암호화 등 암호화 기술을 이용한 보안조치,
            보안프로그램의 설치, 접속기록의 위•변조 방지를 위한 조치, 백신 소프트웨어의 설치•운영 등 컴퓨터 바이러스에 의한 침해 방지 조치
          </li>
          <li>3. 물리적 조치: 전산실, 자료보관실 등에 대한 접근 통제</li>
          <li>
            • 회사는 정보주체의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 아니하도록 안전성 확보를 위하여 암호화 기능 등을
            활용하여 보안유지를 위한 대책을 수립하고, 그 이행을 위하여 최선의 노력을 다하겠습니다.
          </li>
          <li>
            • 회사는 개인정보 취급 직원을 최소한으로 제한하고 담당직원에 대한 수시교육을 통해 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률
            그리고 본 처리지침을 준수하도록 하겠습니다.
          </li>
        </ul>
      </p>

      <p>
        <h2>제7조 (정보주체의 권리•의무 및 그 행사방법에 관한 사항)</h2>
        <ul>
          <li>1. 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호와 관련한 권리를 행사할 수 있습니다.</li>
          <li>• 개인정보의 열람 요구</li>
          <li>• 오류 등이 있을 경우 정정 요구</li>
          <li>• 삭제 요구</li>
          <li>• 처리정지 요구</li>
          <li>• 삭제 요구</li>
          <li>• 처리정지 요구</li>
          <li>• 회사의 개인정보 처리에 동의하지 않는 경우에 동의 거부 (단, 이 경우 회사가 제공하는 서비스의 일부 또는 전부의 이용이 제한될 수 있습니다.)</li>
          <li>
            2. 본 조 제1항에서 규정한 정보주체의 권리는 서면, 전화, 이메일, FAX 등의 방법으로 행사할 수 있으며, 회사는 본인 확인 절차를 거친 이후에 이에 대하여
            지체 없이 필요한 조치를 취하겠습니다.
          </li>
          <li>
            3. 회사는 정보주체가 개인정보의 오류에 대한 정정 또는 삭제를 요청한 경우에는 그 정정 및 삭제가 완료될 때까지 당해 개인정보를 이용 또는 제공하지
            않습니다. 회사는 잘못된 개인정보의 이용 또는 제공 사실을 알게 된 경우에는 지체 없이 잘못된 이용 및 제공을 시정하겠습니다. 또한 잘못된 개인정보를
            제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
          </li>
          <li>
            4. 정보주체는 제1항의 권리행사를 그의 법정대리인 또는 위임을 받은 자 등 대리인을 통하여도 행사할 수 있습니다. 이 경우 정보주체는 개인정보보호법
            시행규칙 별지 제11호 서식에 따른 위임장을 회사에 제출하셔야 합니다.
          </li>
          <li>5. 정보주체는 개인정보보호법 및 관계 법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해해서는 안됩니다.</li>
        </ul>
      </p>

      <p>
        <h2>제8조 (개인정보의 파기)</h2>
        <ul>
          <li>
            • 회사는 관계법령에 따라 보존하여야 하는 등 정당한 사유가 없는 한 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 지체 없이 해당
            개인정보를 파기합니다.
          </li>
          <li>
            • 정보주체가 동의한 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 관계법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당
            개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          </li>
          <li>• 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
          <li>
            • 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기하고, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하는 방법으로
            파기합니다.
          </li>
        </ul>
      </p>
      <p>
        <h2>제9조 (개인정보보호 담당 업무부서)</h2>
        <ul>
          <li>
            회사는 개인정보보호법 제30조 제1항 제6호의 규정에 따라 개인정보를 보호하고 개인정보에 관련된 불만을 처리하는 등 개인정보의 처리에 관한 업무를
            담당하는 부서를 다음과 같이 지정하고 있습니다.
          </li>
          <br />
          <li className="light mb10">1. 부서: 경영지원부</li>
          <li className="light mb10">2. 전화: 031-336-4678</li>
          <li className="light mb10">3. 팩스: 031-336-2379</li>
          <li className="light mb10">4. 이메일: support@unic-global.com</li>
        </ul>
      </p>
      <p>
        <h2>제10조 (영상정보처리기기의 설치 및 운영)</h2>
        <span>회사는 아래와 같이 영상정보처리기기를 설치•운영하고 있습니다.</span>
        <table>
          <colgroup>
            <col width="30%" />
            <col width="*" />
          </colgroup>
          <tr>
            <th>1. 설치 목적</th>
            <td>회사의 시설 안전 및 보안, 화재 예방</td>
          </tr>
          <tr>
            <th>2. 설치 위치</th>
            <td>사옥 로비, 출입문, 비상구 등 주요시설물 내•외부</td>
          </tr>
          <tr>
            <th>3. 촬영 범위</th>
            <td>주요시설물의 전 공간을 촬영</td>
          </tr>
          <tr>
            <th>4. 담당 관리부서</th>
            <td>시설/정보관리: 경영지원부</td>
          </tr>
          <tr>
            <th>5. 촬영 기간</th>
            <td>24시간, 365일</td>
          </tr>
          <tr>
            <th>6. 보관 기간</th>
            <td>촬영일로부터 30일 이내</td>
          </tr>
          <tr>
            <th>7. 처리 방법</th>
            <td>관리책임자 또는 수탁자 별도 처리</td>
          </tr>
          <tr>
            <th>8. 열람 방법/장소</th>
            <td>
              정보주체는 개인영상정보의 열람•존재확인 청구서를 작성하여 회사에 제출함으로써 영상정보의 확인 등 열람을 요구할 수 있습니다. 이 때, 임직원이 아닌
              제3자가 자신의 개인영상정보를 요청하는 경우에는 개인정보보호책임자의 최종승인을 득한 후 제공 및 확인이 가능합니다. 영상정보는 회사 내
              정보보호책임자가 지정하는 장소에서 열람할 수 있습니다.
            </td>
          </tr>
          <tr>
            <th>9. 정보주체 열람</th>
            <td>
              정보주체의 열람 등 요구가 있는 경우 회사는 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명, 신체, 재산상의 이익을 위해 필요한 경우에 한해
              열람을 허용합니다.
            </td>
          </tr>
          <tr>
            <th>10. 안전 조치</th>
            <td>
              회사는 영상정보 보호를 위한 기술적•관리적•물리적 조치로써 개인정보보호규정의 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장•전송기술
              적용, 처리기록 보관 및 위•변조 방지조치, 보관시설 마련 및 잠금장치 설치 등의 조치를 취합니다.
            </td>
          </tr>
        </table>
      </p>
      <p>
        <h2>제11조 (권익침해 구제방법)</h2>
        <ul>
          <li>
            정보주체는 개인정보가 침해되는 경우 필요한 구제조치를 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담
            등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해에 대한 신고 및 상담에 관하여는 아래의 기관에 문의하시기를 바랍니다.
          </li>
          <li>1. 개인분쟁조정위원회: 1833-6972 (www.kopico.go.kr)</li>
          <li>2. 개인정보침해 신고센터: (국번없이) 118 (www.privacy.kisa.or.kr)</li>
          <li>3. 대검찰청 사이버범죄수사단: (국번없이) 1301 (www.spo.go.kr)</li>
          <li>4. 경찰청 사이버안전국: (국번없이) 182 (http://cyberbureau.police.go.kr)</li>
        </ul>
      </p>
      <p>
        <h2>제12조 (개인정보 처리방침의 변경)</h2>
        <ul>
          <li>
            본 처리방침은 시행일로부터 적용됩니다. 관련 법령 및 방침이 변경됨에 따라 본 처리방침의 내용이 추가, 삭제 또는 정정되는 경우, 회사는 변경사항이
            시행되기 7일 전부터 회사 홈페이지를 통하여 공지할 것입니다.
          </li>
        </ul>
      </p>
    </PopupWrap>
  );
};

export default TermPersonal;
