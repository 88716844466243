import styled, { keyframes } from 'styled-components';
const aniTop = keyframes`
  0%{opacity: 0;  transform: translateY(100px);}
  100%{
    opacity: 1;  transform: translateY(0px);}
`;

export const Main = styled.article`
  &.active {
    animation: ${aniTop} 0.5s linear;
    animation-fill-mode: forwards;
  }
  opacity: 0;
  transform: translateY(100px);
  padding-top: 135px;
  padding-bottom: 150px;
  .main {
    & > img {
      max-width: 80%;
      max-height: 100%;
      @media screen and (max-width: 420px) {
        max-width: 75%;
      }
    }
  }
  @media screen and (max-width: 880px) {
    padding-top: 80px;
    padding-bottom: 90px;
  }
  @media screen and (max-width: 560px) {
    padding-top: 50px;
  }
  &:first-of-type {
    padding-top: 95px;
    @media screen and (max-width: 560px) {
      padding-top: 65px;
    }
    .main {
      height: 440px;
      @media screen and (max-width: 700px) {
        height: 390px;
      }
      @media screen and (max-width: 560px) {
        height: 340px;
      }
      @media screen and (max-width: 420px) {
        height: 320px;
        & > img {
          max-width: 55%;
        }
      }
      @media screen and (max-width: 360px) {
        height: 290px;
      }
    }
  }
  &:nth-of-type(2) {
    .main {
      height: 390px;
      @media screen and (max-width: 700px) {
        height: 340px;
      }
      @media screen and (max-width: 560px) {
        height: 300px;
      }
      @media screen and (max-width: 420px) {
        height: 280px;
      }
      @media screen and (max-width: 360px) {
        height: 260px;
      }
    }
  }
  &:nth-of-type(3) {
    .main {
      height: 350px;
      @media screen and (max-width: 700px) {
        height: 300px;
        & > img {
          max-width: 65%;
        }
      }
      @media screen and (max-width: 560px) {
        height: 250px;
      }
      @media screen and (max-width: 420px) {
        height: 230px;
      }
      @media screen and (max-width: 360px) {
        height: 200px;
      }
    }
  }
  &:nth-of-type(4) {
    .main {
      height: 380px;
      @media screen and (max-width: 700px) {
        height: 340px;
      }
      @media screen and (max-width: 560px) {
        height: 300px;
      }
      @media screen and (max-width: 420px) {
        height: 260px;
      }
      @media screen and (max-width: 360px) {
        height: 250px;
      }
    }
  }

  &.bg {
    background-color: #f5f8fb;
  }
  h2 {
    margin-bottom: 0 !important;
  }
  .tit {
    margin-bottom: 20px;
  }
  .subTit {
    color: #222222;
    font-size: 20px;
    font-weight: 500;
    margin-left: 15px;
    @media screen and (max-width: 720px) {
      display: block;
    }
  }
  .subTxt {
    .mb30 {
      margin-bottom: 30px;
      @media screen and (max-width: 880px) {
        margin-bottom: 20px;
      }
    }
    span {
      color: #565656;
      font-weight: 400;
      font-size: 20px;
      display: block;
      line-height: 1.8;
      :lang(ja),
      :lang(zh) {
        word-break: break-word;
      }
      @media screen and (max-width: 1400px) {
        display: inline;
      }
      @media screen and (max-width: 880px) {
        font-size: 18px;
      }
      @media screen and (max-width: 560px) {
        font-size: 16px;
      }
    }
  }
`;
export const SlideWrap = styled.div`
  border: 1px solid #b7b7b7;
  position: relative;
  background-color: #fff;
  background-image: url('/images/bg/bg-productSlide.png');
  background-repeat: no-repeat;
  background-position: left center;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 100px 125px 100px 70px;
  margin-top: 60px;
  @media screen and (max-width: 1450px) {
    padding: 85px 55px 100px 50px;
    background-position: left bottom;
  }
  @media screen and (max-width: 1200px) {
    display: block;
    background-position: -85px -72px;
    padding: 70px 60px 50px 60px;
  }
  @media screen and (max-width: 800px) {
    padding: 50px 40px 50px 40px;
  }
  @media screen and (max-width: 620px) {
    background-size: 70%;
  }

  @media screen and (max-width: 560px) {
    padding: 50px 30px 50px 30px;
  }
  @media screen and (max-width: 420px) {
    padding: 40px 20px 40px 20px;
  }
  @media screen and (max-width: 350px) {
    padding: 30px 20px 30px 20px;
  }
  @media screen and (max-width: 560px) {
    margin-top: 30px;
  }
  /*&:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #304c70, #6289b4, #8fbbf6);
  }
  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 17px;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #304c70, #6289b4, #8fbbf6);
  }*/

  .left {
    width: 490px;
    margin-right: 60px;
    flex-grow: 1;
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 140px;
    }
    @media screen and (max-width: 800px) {
      margin-bottom: 80px;
    }
    @media screen and (max-width: 620px) {
      margin-bottom: 50px;
    }
    .main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 480px;
      @media screen and (max-width: 1200px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 700px) {
        width: 350px;
      }
      @media screen and (max-width: 560px) {
        width: 310px;
      }
      @media screen and (max-width: 420px) {
        width: 280px;
      }
      @media screen and (max-width: 380px) {
        width: 260px;
      }
      @media screen and (max-width: 360px) {
        width: 220px;
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      @media screen and (max-width: 620px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 420px) {
        margin-top: 0px;
      }
      button {
        width: 120px;
        height: 120px;
        border: 1px solid #b7b7b7;
        background-color: #fff;
        margin-right: 7px;
        box-sizing: border-box;
        overflow: hidden;
        text-align: center;
        font-size: 0;
        padding: 0;
        &:last-of-type {
          margin-right: 0;
        }
        img {
          max-width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
        @media screen and (max-width: 620px) {
          width: 90px;
          height: 90px;
        }
        @media screen and (max-width: 420px) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
  .right {
    flex-grow: 1;
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #b7b7b7;
      margin-bottom: 40px;
      padding-bottom: 30px;
      flex-wrap: wrap;
      @media screen and (max-width: 770px) {
        margin-bottom: 30px;
      }
      @media screen and (max-width: 620px) {
        margin-bottom: 25px;
        padding-bottom: 25px;
      }
      @media screen and (max-width: 470px) {
        padding-bottom: 20px;
      }
      .videoBtn {
        width: 320px;
        height: 50px;
        background-color: #314c70;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        font-family: Pretendard !important;
        padding-left: 25px;
        border-radius: 5px;
        text-align: left;
        background-image: url('/images/icon/ico-nextArrow.png');
        background-repeat: no-repeat;
        background-position: 285px center;
        @media screen and (max-width: 1286px) {
          margin-top: 20px;
        }
        @media screen and (max-width: 770px) {
          margin-top: 7px;
        }
        @media screen and (max-width: 480px) {
          width: 100%;
          background-position: 92% center;
          font-size: 16px;
          height: 40px;
        }
        @media screen and (max-width: 350px) {
          background-position: 95% center;
          padding-left: 15px;
        }
        @media screen and (max-width: 330px) {
          font-size: 15px;
        }
      }
    }
    h3 {
      color: #2a2929;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      position: relative;
      padding-left: 30px;
      @media screen and (max-width: 470px) {
        line-height: 1.4;
      }
      @media screen and (max-width: 340px) {
        font-size: 28px;
        padding-left: 20px;
      }
      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #b7b7b7;
        left: 0;
        top: 10px;
      }
      &.videoTit {
        @media screen and (max-width: 770px) {
          display: block;
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
    ul {
      li {
        color: #2a2929;
        font-size: 20px;
        font-weight: 400;
        position: relative;
        margin-bottom: 15px;
        padding-left: 20px;
        line-height: 1.6;
        word-break: break-word;
        @media screen and (max-width: 620px) {
          font-size: 16px;
        }
        @media screen and (max-width: 340px) {
          font-size: 14px;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: #b7b7b7;
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }
  }
`;
