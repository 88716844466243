import React, { useEffect, useMemo, useState } from 'react';
import { BannerImg, BannerTop, MainWrap, MaxWidth, SubTit, TitTab, TopBtn } from 'Global';
import { useTranslation } from 'react-i18next';
import useMoveScrool from '../../hooks/useMoveScroll';
import { throttle } from 'lodash';
import { Main } from './style';
import ProductSlide from './component/ProductSlide';
import { useLocation } from 'react-router-dom';

const Product = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [scrollEvent, setScrollEvent] = useState<number>(0);
  const [tab, setTab] = useState<number>(1);
  const tabRef: any = {
    0: useMoveScrool(),
    1: useMoveScrool(),
    2: useMoveScrool(),
    3: useMoveScrool(),
    length: 4,
  };

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        const mainTop01 = tabRef[0].element?.current?.getBoundingClientRect().top;
        const mainTop02 = tabRef[1].element?.current?.getBoundingClientRect().top;
        const mainTop03 = tabRef[2].element?.current?.getBoundingClientRect().top;
        const mainTop04 = tabRef[3].element?.current?.getBoundingClientRect().top;
        if (mainTop01 && mainTop01 <= 900) {
          setScrollEvent(1);
        }
        if (mainTop02 && mainTop02 <= 900) {
          setScrollEvent(2);
        }
        if (mainTop03 && mainTop03 <= 900) {
          setScrollEvent(3);
        }
        if (mainTop04 && mainTop04 <= 900) {
          setScrollEvent(4);
        }
        if (mainTop01 && mainTop01 >= 900) {
          setScrollEvent(0);
        }
      }, 100),
    [tabRef, scrollEvent],
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  useEffect(() => {
    if (pathname === '/product') {
      window.scrollTo(0, 0);
    }
    if (pathname === '/product/dryer') {
      setScrollEvent(2);
      tabRef[0].onMoveToElement();
    }
    if (pathname === '/product/podCleaner') {
      tabRef[1].onMoveToElement();
    }
    if (pathname === '/product/maskCleaner') {
      tabRef[2].onMoveToElement();
    }
    if (pathname === '/product/singleProcessor') {
      tabRef[3].onMoveToElement();
    }
  }, [pathname]);

  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <MainWrap>
      <MaxWidth>
        <BannerTop>
          <h1>
            <span>{t('TR_PRODUCT_TIT_01')}</span>
            <span>{t('TR_PRODUCT_TIT_02')}</span>
          </h1>
        </BannerTop>
        <TitTab className="cnt4">
          <button type="button" className={scrollEvent === 1 ? ' active' : ''} onClick={tabRef[0].onMoveToElement}>
            {t('TR_PRODUCT_TAB_01')}
          </button>
          <button type="button" className={scrollEvent === 2 ? ' active' : ''} onClick={tabRef[1].onMoveToElement}>
            {t('TR_PRODUCT_TAB_02')}
          </button>
          <br className="dis-b-m" />
          <button type="button" className={scrollEvent === 3 ? ' active' : ''} onClick={tabRef[2].onMoveToElement}>
            {t('TR_PRODUCT_TAB_03')}
          </button>
          <button type="button" className={scrollEvent === 4 ? ' active' : ''} onClick={tabRef[3].onMoveToElement}>
            {t('TR_PRODUCT_TAB_04')}
          </button>
        </TitTab>
      </MaxWidth>
      <BannerImg className="product"></BannerImg>
      <Main ref={tabRef[0].element} className={scrollEvent >= 1 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_PRODUCT_MAIN_01_SUB_TIT')}</h2>
          </SubTit>
          <p className="subTxt">
            <p className="mb30">
              <span>{t('TR_PRODUCT_MAIN_01_TXT_01')}</span>
              <span>{t('TR_PRODUCT_MAIN_01_TXT_02')}</span>
            </p>
            <p className="mb30">
              <span>{t('TR_PRODUCT_MAIN_01_TXT_03')}</span>
              <span>{t('TR_PRODUCT_MAIN_01_TXT_04')}</span>
            </p>
            <span>{t('TR_PRODUCT_MAIN_01_TXT_05')}</span>
            <span>{t('TR_PRODUCT_MAIN_01_TXT_06')}</span>
            <span>{t('TR_PRODUCT_MAIN_01_TXT_07')}</span>
          </p>
          <ProductSlide slideNo={0} />
        </MaxWidth>
      </Main>
      <Main ref={tabRef[1].element} className={'bg ' + (scrollEvent >= 2 ? ' active' : '')}>
        <MaxWidth>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_PRODUCT_MAIN_02_SUB_TIT_01')}</h2>
            <span className="subTit">{t('TR_PRODUCT_MAIN_02_SUB_TIT_02')}</span>
          </SubTit>
          <p className="subTxt">
            <span>{t('TR_PRODUCT_MAIN_02_TXT_01')}</span>
            <span>{t('TR_PRODUCT_MAIN_02_TXT_02')}</span>
            <span>{t('TR_PRODUCT_MAIN_02_TXT_03')}</span>
          </p>
          <ProductSlide slideNo={1} />
        </MaxWidth>
      </Main>
      <Main ref={tabRef[2].element} className={scrollEvent >= 3 ? ' active' : ''}>
        <MaxWidth>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_PRODUCT_MAIN_03_SUB_TIT_01')}</h2>
            <span className="subTit">{t('TR_PRODUCT_MAIN_03_SUB_TIT_02')}</span>
          </SubTit>
          <p className="subTxt">
            <span>{t('TR_PRODUCT_MAIN_03_TXT_01')}</span>
            <span>{t('TR_PRODUCT_MAIN_03_TXT_02')}</span>
          </p>
          <ProductSlide slideNo={2} />
        </MaxWidth>
      </Main>
      <Main ref={tabRef[3].element} className={'bg ' + (scrollEvent >= 4 ? ' active' : '')}>
        <MaxWidth>
          <SubTit className="tit">
            <h2 className="fs36">{t('TR_PRODUCT_MAIN_04_SUB_TIT_01')}</h2>
          </SubTit>
          <p className="subTxt">
            <span>{t('TR_PRODUCT_MAIN_04_TXT_01')}</span>
            <span>{t('TR_PRODUCT_MAIN_04_TXT_02')}</span>
            <span>{t('TR_PRODUCT_MAIN_04_TXT_03')}</span>
          </p>
          <ProductSlide slideNo={3} />
        </MaxWidth>
      </Main>
      {scrollEvent !== 0 && <TopBtn onClick={onTop}>TOP</TopBtn>}
    </MainWrap>
  );
};

export default Product;
